import {
  isDataNode,
  meta,
  type Culture,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { DatabaseRouter } from '../createDatabaseRouter';
import type { RouteDefinition } from '../createRouter';
import type {
  RouteLink,
  RouterPlugin,
  RoutingStatus,
  UrlQuery,
} from '../types';
import { encodeQuery } from './encodeAndDecodeQuery';

export interface GetPathOptions {
  culture?: Culture;
  query?: UrlQuery;
  updateQuery?: UrlQuery;
}

export function getPath<
  Typename extends NodeTypename<DataSchema> = NodeTypename<DataSchema>,
>(
  router: DatabaseRouter,
  plugins: RouterPlugin<DatabaseRouter>[] | undefined,
  to: RouteLink<Typename>,
  options?: GetPathOptions,
) {
  const { query, updateQuery } = options || {};
  let culture = options?.culture || router.culture;
  let path: string;
  let status: Omit<RoutingStatus, 'route'> & { route?: RouteDefinition } = {
    query: query || ({} as UrlQuery),
    segments: [],
  };

  if (typeof to === 'string') {
    path = to;
  } else {
    let [node, route] =
      'node' in to
        ? [to.node, to.onRoute === 'current' ? router.route : to.onRoute]
        : 'route' in to
          ? [null, to.route === 'current' ? router.route : to.route]
          : 'routeKey' in to
            ? [null, router.map.routeByKey(culture, to.routeKey)?.node]
            : [null, null];

    if (node && !meta(node).isLoading && !route) {
      const routing = router.getNodeRouting(node);
      route = (routing?.route || router.route).node;
      if (routing?.query) status.query = { ...query, ...routing.query };
    }

    if (node && !meta(node).isLoading) {
      status.query = {
        ...status.query,
        'node-typename': [node.__typename],
        'node-id': [node.id],
      };
    }

    if (updateQuery) {
      status.query = {
        ...router.query,
        ...status.query,
        ...updateQuery,
      };
    }

    culture = isDataNode(route)
      ? meta(route).culture
      : meta((route as RouteDefinition)?.node)?.culture || router.culture;
    status.route = isDataNode<DataSchema, 'Route'>(route)
      ? router.map.routeById(culture, route.id)
      : (route as RouteDefinition) || undefined;

    if (!status.route) path = '/404';
    else {
      if (plugins) {
        for (const plugin of plugins) {
          if (plugin.encode) {
            status = plugin.encode(status as RoutingStatus, router) || status;
          }
        }
      }

      const pathFromMap = status.segments.length
        ? status.route!.fullPathname || status.route!.pathname
        : status.route!.pathname;
      path = pathFromMap
        ? `${
            culture === router.schema.defaultCulture
              ? ''
              : `/${culture.toLowerCase()}`
          }${pathFromMap}`
        : '/404';
    }
  }

  return [
    [
      status.segments.length
        ? [path.replace(/\/$/, ''), ...status.segments].join('/')
        : path,
      status.query && encodeQuery(status.query),
    ]
      .filter(Boolean)
      .join('?'),
  ]
    .filter(Boolean)
    .join('');
}
