import {
  batch,
  store,
  type NodeFromSchema,
  type NodeTypename,
  type Schema,
  type Simplified,
  type SupportedCulture,
} from '@donkeyjs/proxy';

export * from './locale';
export * from './schemaI18n';

export interface I18nSchema<S extends Schema> {
  nodes?: {
    [Type in NodeTypename<S>]?: {
      [Field in keyof NodeFromSchema<S, Type>]?: string | (() => string);
    };
  };
  enums?: {
    [Enum in keyof S['enums']]?: {
      [Value in Extract<
        S['enums'][Enum]['values'] extends readonly (infer U)[] ? U : never,
        string
      >]?: string;
    };
  };
}

interface I18nLibrarySet {
  [key: string]: I18nLibraryEntry;
}

type I18nLibraryEntry = I18nLibrarySet | string | ((...args: any[]) => string);

export interface I18nLibraryCulture<
  Set extends I18nLibrarySet,
  Errors extends Record<string, string>,
> {
  values?: Set;
  errors?: Errors;
}

export type I18nLibraryInput<Library extends I18nLibraryCulture<any, any>> = {
  [culture in SupportedCulture]?: Library | (() => Promise<Library>);
};

export type I18nLibrary<Library extends I18nLibraryCulture<any, any> = object> =
  {
    [culture in SupportedCulture]?: I18n<Library>;
  };

export const createI18nCulture = <
  Set extends I18nLibrarySet = Record<string, never>,
  Errors extends Record<string, string> = Record<string, never>,
>(
  input: I18nLibraryCulture<Set, Errors>,
): I18nLibraryCulture<Set, Errors> => input;

export const createI18nLibrary = <Library extends I18nLibraryCulture<any, any>>(
  input: I18nLibraryInput<Library>,
): I18nLibrary<Library> => {
  const cache: { [key: string]: I18n<Library> } = {};
  return new Proxy({} as I18nLibrary<Library>, {
    get: (_, key: SupportedCulture) => {
      if (!input[key])
        throw new Error(`No translation set available for culture ${key}.`);
      return (cache[key] ??= new I18n(
        key,
        input[key] as Library | (() => Promise<Library>),
      ));
    },
  });
};

type NextLevel = {
  9: 8;
  8: 7;
  7: 6;
  6: 5;
  5: 4;
  4: 3;
  3: 2;
  2: 1;
  1: 0;
  0: never;
};

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

type OwnKeys<N extends I18nLibrarySet> = {
  [K in keyof N]: N[K] extends I18nLibrarySet ? never : K;
}[keyof N];

type OtherKeys<N extends I18nLibrarySet> = {
  [K in keyof N]: N[K] extends I18nLibrarySet ? K : never;
}[keyof N];

export type NestedTranslations<
  N extends I18nLibrarySet,
  Levels extends keyof NextLevel = 9,
  Prefix extends string = '',
> = Simplified<
  0 extends Levels
    ? Record<string, never>
    : {
        [K in OwnKeys<N> as `${Prefix}${Extract<K, string>}`]: N[K];
      } & UnionToIntersection<
        {
          [K in OtherKeys<N>]: N[K] extends I18nLibrarySet
            ? NestedTranslations<
                N[K],
                Levels,
                `${Prefix}${Extract<K, string>}.`
              >
            : Record<string, never>;
        }[OtherKeys<N>]
      >
>;

export class I18n<
  Library extends I18nLibraryCulture<any, Record<string, string>>,
> {
  private state: { loading: boolean; value?: Library };
  private readonly promise: Promise<void> | undefined;

  public readonly culture: SupportedCulture;

  constructor(
    culture: SupportedCulture,
    value: Library | (() => Promise<Library>),
  ) {
    this.culture = culture;
    this.state = store({
      loading: typeof value === 'function',
      value: typeof value === 'function' ? undefined : value,
    });
    this.promise =
      typeof value !== 'function'
        ? undefined
        : value().then((value) => {
            batch(() => {
              this.state.loading = false;
              this.state.value = value;
            });
          });
  }

  public get loading() {
    return this.state.loading;
  }

  public async ready() {
    await this.promise;
  }

  public get<
    K extends Extract<keyof Nodes, string>,
    Nodes extends NestedTranslations<
      Library extends I18nLibraryCulture<infer Nodes, any> ? Nodes : never
    >,
  >(key: K): Nodes[K] | undefined {
    return (
      this.state.value?.values &&
      key
        .split('.')
        .reduce<I18nLibraryEntry>(
          (previous, current) =>
            previous && ((previous as any)[current] as I18nLibraryEntry),
          this.state.value.values,
        )
    );
  }

  public getError(key: string) {
    return this.state.value?.errors?.[key];
  }
}
