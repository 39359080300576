import { bind, preventDefault } from '@donkeyjs/jsx-runtime';
import { PhTrash } from '@donkeyjs/phosphor-icons';
import styles from './LayoutListItem.module.css';

interface LayoutListItemProps {
  title: JSX.Children;
  icon?: JSX.Children;
  children?: JSX.Children;
  class?: JSX.ClassNames;
  active?: boolean;
  onmount?: JSX.OnMount<HTMLDivElement>;
  onFocus?: (ev: FocusEvent) => void;
  withDragHandle?: boolean;
  onDelete?: () => void;
}

export function LayoutListItem(props: LayoutListItemProps) {
  return (
    <div
      class={bind(() => [
        styles.item,
        { [styles.withHandle]: props.withDragHandle, active: props.active },
        props.class,
      ])}
      tabindex={bind(() => (props.onFocus ? 0 : undefined))}
      onfocus={props.onFocus}
      onmount={props.onmount}
    >
      <div class={styles.name}>
        {props.icon}
        <span>{() => props.title}</span>
        {() =>
          !!props.onDelete && (
            <button type="button" onclick={preventDefault(props.onDelete)}>
              <PhTrash weight="fill" />
            </button>
          )
        }
      </div>
      {props.children}
      {() => props.withDragHandle && <div class={['handle', styles.handle]} />}
    </div>
  );
}
