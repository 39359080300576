import { createSchema, type SchemaMeta } from '@donkeyjs/proxy';
import { baseSchema } from '../data/baseSchema';
import { baseSchemaMeta } from '../data/baseSchemaMeta';
import type { LocaleSet } from '../i18n';
import {
  defaultPermissions,
  type Permissions,
  type PermissionsInput,
} from '../permissions';

export interface CreateAppInput {
  key: string;
  hostname: string;
  schema: DataSchema;
  schemaMeta?: SchemaMeta<DataSchema>;
  locales: LocaleSet;
  permissions?: Permissions<DataSchema>;
  routerFollowsCultures?: boolean;
}

export interface AppModule {
  schemaMeta?: SchemaMeta<DataSchema>;
  permissions?: PermissionsInput<DataSchema>;
}

export type AppBase = {
  key: string;
  hostname: string;
  schema: ApplicationSchema;
  schemaMeta: SchemaMeta<DataSchema>;
  locales: LocaleSet;
  permissions: Permissions<DataSchema>;
  routerFollowsCultures: boolean;
};

export const createApp = (
  input: CreateAppInput,
  modules?: AppModule[],
): AppBase => {
  const permissions = (modules || []).reduce(
    (acc, module) => (module?.permissions ? acc.with(module.permissions) : acc),
    input.permissions || defaultPermissions(),
  );

  return {
    key: input.key,
    hostname: input.hostname,
    locales: input.locales || {},
    permissions,
    routerFollowsCultures: input.routerFollowsCultures || false,
    schema:
      (input.schema as ApplicationSchema | undefined) ||
      (createSchema(baseSchema) as unknown as ApplicationSchema),
    schemaMeta: {
      ...(baseSchemaMeta as any),
      ...modules?.reduce(
        (acc, module) => {
          const result: any = { ...acc };
          for (const k in module.schemaMeta) {
            const key = k as keyof SchemaMeta<DataSchema>;
            if (result[key]) {
              result[key] = {
                ...result[key],
                ...module.schemaMeta[key],
              };
            } else {
              result[key] = module.schemaMeta[key];
            }
          }
          return result;
        },
        {} as SchemaMeta<DataSchema>,
      ),
      ...(input.schemaMeta as any),
    },
  };
};
