import {
  documentSelection,
  getGlobal,
  type ScopedDocumentSelection,
} from '@donkeyjs/jsx-runtime';
import { batch, store, watch } from '@donkeyjs/proxy';

export interface ContentEditableOptions {
  onkeydown?: (event: KeyboardEvent) => void;
  oncopy?: (event: ClipboardEvent) => void;
  oncut?: (event: ClipboardEvent) => void;
  onpaste?: (event: ClipboardEvent) => void;
  ondrag?: (event: DragEvent) => void;
  ondragover?: (event: DragEvent) => void;
  ondrop?: (event: DragEvent) => void;
  onfocus?: (element: HTMLElement) => void;
  onblur?: (element: HTMLElement) => void;
}

export interface ContentEditable {
  element: HTMLElement;
  options: ContentEditableOptions;
}

interface ContentEditables {
  editables: Map<HTMLElement, ContentEditable>;
  focussed: ContentEditable | undefined;
  selection: ScopedDocumentSelection | null | undefined;
}

const editablesKey = Symbol('editables');

export const contentEditable =
  (options: ContentEditableOptions) => (element: HTMLElement) => {
    const destroy: (() => void)[] = [];

    const editables = getContentEditables();

    const self = { element, options };
    editables.editables.set(element, self);

    let hasFocus = false;
    destroy.push(
      watch(() => {
        const newHasFocus = editables.focussed === self;
        if (newHasFocus !== hasFocus) {
          hasFocus = newHasFocus;
          if (hasFocus) options.onfocus?.(element);
          else options.onblur?.(element);
        }
      }).dispose,
    );

    element.contentEditable = 'true';
    element.spellcheck = false;
    for (const eventName of [
      'keydown',
      'copy',
      'cut',
      'paste',
      'drag',
    ] as const) {
      const listener = (event: any) => {
        const editable = editables.focussed;

        if (editable) {
          const handle = editable.options[`on${eventName}`];

          handle?.(event);

          if (event.type !== 'keydown' || !handle) {
            event.preventDefault();
            event.stopPropagation();
          }
        }
      };

      element.addEventListener(eventName, listener);
      destroy.push(() => element.removeEventListener(eventName, listener));
    }

    return () => {
      for (const destroyFn of destroy) destroyFn();
      editables.editables.delete(element);
    };
  };

export const getContentEditables = () =>
  getGlobal<ContentEditables>(editablesKey, createEditables);

const createEditables = () => {
  const result = store<ContentEditables>({
    editables: new Map<HTMLElement, ContentEditable>(),
    focussed: undefined,
    selection: undefined,
  });

  watch(() => {
    const focussed = getEditableFromSelection(result.editables);
    if (focussed !== result.focussed) {
      batch(() => {
        result.focussed = focussed;
        result.selection =
          focussed && documentSelection.relateTo(focussed.element);
      });
    }
  });

  return result;
};

const getEditableFromSelection = (
  editables: Map<HTMLElement, ContentEditable>,
) => {
  let node = documentSelection.commonAncestor as HTMLElement | null;
  while (node) {
    if (editables.has(node)) break;
    node = node.parentElement;
  }

  return node ? editables.get(node) : undefined;
};
