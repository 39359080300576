import { jsxx } from '@donkeyjs/jsx-runtime';
import { meta, store, type DataNode } from '@donkeyjs/proxy';
import { getUserContext } from '../../../authentication';
import { session } from '../../../session';
import { File } from '../files/File';

interface FileRefProps {
  value: DataNode<DataSchema, 'FileRef'> | null | undefined;
  default?: DataNode<DataSchema, 'File'> | null | undefined;
  ratio?: number | 'adaptive';
  readonly?: boolean;
  changeOnClick?: boolean | 'button';
  class?: JSX.ClassNames;
  style?: Record<string, any>;
  onmount?: JSX.OnMount<HTMLSpanElement>;
}

export function FileRef(props: FileRefProps) {
  const user = getUserContext();

  if (props.value || !props.readonly)
    return jsxx(
      File,
      store.clone(props, {
        get value() {
          const def = props.default;
          return meta(props.value?.file)?.isLoading &&
            def &&
            !meta(def)?.isLoading
            ? def
            : props.value?.file || def;
        },
        set value(value) {
          if (props.value) {
            props.value.file = value as DataNode<DataSchema, 'File'>;
          } else {
            props.value = session.data.createNode<'FileRef'>({
              __typename: 'FileRef',
              file: value as DataNode<DataSchema, 'File'>,
            });
          }
        },
        get testing() {
          return meta(props.value)?.getField('file').isTest;
        },
        get readonly() {
          return user.isAdmin ? props.readonly : true;
        },
        get changeOnClick() {
          return props.changeOnClick;
        },
        get class() {
          return props.class;
        },
        get style() {
          return props.style;
        },
        onTest(value: DataNode<DataSchema, 'File'> | null | undefined) {
          if (props.value) {
            return meta(props.value).testValues({ file: value || undefined });
          }
          if (value) {
            props.value = session.data.createLocalNode<'FileRef'>({
              __typename: 'FileRef',
              file: value,
            });
            return () => {
              meta(props.value)?.delete();
              props.value = null;
            };
          }
          const prev = props.value;
          props.value = null;
          return () => {
            props.value = prev;
          };
        },
      }),
    );
}
