import { bind } from '@donkeyjs/jsx-runtime';
import { PhCaretRight } from '@donkeyjs/phosphor-icons';
import type { NodeTypename } from '@donkeyjs/proxy';
import styles from './SlideShow.module.css';
import { useSlideShow, type UseSlideShowProps } from './useSlideShow';

export interface SlideShowProps<T extends NodeTypename<DataSchema>>
  extends UseSlideShowProps<T> {
  showControls?: boolean;
}

export function SlideShow<T extends NodeTypename<DataSchema>>(
  props: SlideShowProps<T>,
) {
  const state = useSlideShow(props);

  return (
    <>
      <div
        class={styles.slides}
        style={bind(() => state.style)}
        onmount={state.onmount}
      >
        {() => state.slideCount > 0 && props.data.$(state.$item)}
      </div>
      {() =>
        !!props.showControls && (
          <div class={styles.controls}>
            <button type="button" class={styles.prev} onclick={state.previous}>
              <PhCaretRight weight="bold" />
            </button>
            <button type="button" class={styles.next} onclick={state.next}>
              <PhCaretRight weight="bold" />
            </button>
          </div>
        )
      }
    </>
  );
}
