import { bind, live } from '@donkeyjs/jsx-runtime';
import {
  store,
  type DataList,
  type DataNode,
  type NodeTypename,
} from '@donkeyjs/proxy';
import { session } from '../session';

export interface UseSlideShowSettings {
  autoPlay?: boolean;
  interval?: number;
  transition?: 'fade' | 'slide';
  transitionSpeed?: number;
}

export interface UseSlideShowProps<T extends NodeTypename<DataSchema>>
  extends UseSlideShowSettings {
  data: DataList<DataSchema, T>;
  autoHeight?: boolean;
  render(item: DataNode<DataSchema, T>): JSX.Children;
}

export const useSlideShow = <T extends NodeTypename<DataSchema>>(
  props: UseSlideShowProps<T>,
) => {
  let timeout: number | undefined;

  const startTimeout = () => {
    if (timeout) clearTimeout(timeout);
    timeout = window.setTimeout(
      state.next,
      state.interval * 1000 + (timeout ? state.transitionSpeed * 1000 : 0),
    );
  };

  const slideChanged = () => {
    if (timeout) startTimeout();
  };

  const state = store({
    el: null as HTMLElement | null,
    current: 0,

    get interval() {
      return props.interval ?? 5;
    },
    get transition() {
      return props.transition ?? 'fade';
    },
    get transitionSpeed() {
      return props.transitionSpeed ?? 0.5;
    },
    get slideCount() {
      return props.data.length;
    },

    get height(): number {
      const element = state.el?.children[state.current];
      return element?.clientHeight || 0;
    },

    get style(): string {
      return !props.autoHeight
        ? ''
        : `height: ${state.height}px;${
            state.transition === 'slide'
              ? ` transform: translateX(-${
                  state.current * 100
                }%); transition: transform ${state.transitionSpeed * 1000}ms;`
              : ''
          }`;
    },

    onmount(el: HTMLElement) {
      state.el = el;
      return () => {
        state.el = null;
      };
    },

    next() {
      const next = state.current + 1;
      state.current = next === state.slideCount ? 0 : next;
      slideChanged();
    },

    previous() {
      const prev = state.current - 1;
      state.current = prev < 0 ? state.slideCount - 1 : prev;
      slideChanged();
    },

    $item(node: DataNode<DataSchema, T>, index: () => number) {
      return (
        <div
          class={bind(() => [
            'slide',
            {
              fade: state.transition === 'fade',
              active: index() === state.current,
            },
          ])}
          style={bind(
            () =>
              `${
                state.transition === 'fade'
                  ? index() === state.current
                    ? 'opacity: 1;'
                    : 'opacity: 0;'
                  : undefined
              }; transition: opacity ${state.transitionSpeed * 1000}ms;`,
          )}
        >
          {() => props.render(node)}
        </div>
      );
    },
  });

  live(() => {
    if (session.dom.ssr) return;
    if (props.autoPlay) {
      startTimeout();
    } else if (timeout) {
      clearTimeout(timeout);
    }
  });

  return state;
};
