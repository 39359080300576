import {
  PhFacebookLogo,
  PhInstagramLogo,
  PhLinkedinLogo,
  PhMediumLogo,
  PhPinterestLogo,
  PhRedditLogo,
  PhSnapchatLogo,
  PhSpotifyLogo,
  PhTiktokLogo,
  PhTwitchLogo,
  PhTwitterLogo,
  PhYoutubeLogo,
  type Icon,
  type IconProps,
} from '@donkeyjs/phosphor-icons';
import type { EnumFromSchema } from '@donkeyjs/proxy';

export function XLogo(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <title>X</title>
      {props.children}
      <path d="M 27.161 33 L 105.41 137.796 L 26.667 223 L 44.388 223 L 113.328 148.403 L 169.026 223 L 229.334 223 L 146.684 112.31 L 219.976 33 L 202.255 33 L 138.766 101.703 L 87.468 33 L 27.161 33 Z M 53.221 46.074 L 80.928 46.074 L 203.27 209.924 L 175.565 209.924 L 53.221 46.074 Z" />
    </svg>
  );
}

export const icons: Record<
  EnumFromSchema<DataSchema, 'SocialMediaPlatform'>,
  Icon
> = {
  FACEBOOK: PhFacebookLogo,
  INSTAGRAM: PhInstagramLogo,
  LINKEDIN: PhLinkedinLogo,
  MEDIUM: PhMediumLogo,
  PINTEREST: PhPinterestLogo,
  REDDIT: PhRedditLogo,
  SNAPCHAT: PhSnapchatLogo,
  SPOTIFY: PhSpotifyLogo,
  TIKTOK: PhTiktokLogo,
  TWITCH: PhTwitchLogo,
  TWITTER: PhTwitterLogo,
  X: XLogo,
  YOUTUBE: PhYoutubeLogo,
};

export const iconColors: Record<
  EnumFromSchema<DataSchema, 'SocialMediaPlatform'>,
  string
> = {
  FACEBOOK: '#3b5998',
  INSTAGRAM: '#e1306c',
  LINKEDIN: '#0077b5',
  MEDIUM: '#00ab6c',
  PINTEREST: '#bd081c',
  REDDIT: '#ff4500',
  SNAPCHAT: '#fffc00',
  SPOTIFY: '#1db954',
  TIKTOK: '#000000',
  TWITCH: '#6441a5',
  TWITTER: '#1da1f2',
  X: '#1a8cd8',
  YOUTUBE: '#ff0000',
};

export const renderedIcons = (weight: IconProps['weight'] = 'duotone') =>
  Object.fromEntries(
    Object.entries(icons).map(([key, value]) => [key, value({ weight })]),
  );
