import { bind } from '@donkeyjs/jsx-runtime';
import {
  map,
  type DataNode,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import { attachChangelog } from '../donkey';
import { text } from '../i18n/i18n';
import { getTheme } from '../styles';
import type { ViewMode } from '../views';
import styles from './ListDrafts.module.css';

export interface ListDraftsProps<
  S extends Schema,
  Typename extends NodeTypename<S>,
> {
  drafts: DataNode<S, Typename>[];
  open?: boolean;
  loading: boolean;
  class?: JSX.ClassNames;
  modes?: ViewMode[];
  mode?: ViewMode;
  render(item: DataNode<S, Typename>, index: () => number): JSX.Children;
  renderContainer(children: JSX.Children | undefined): JSX.Children;
}

export function ListDrafts<S extends Schema, Typename extends NodeTypename<S>>(
  props: ListDraftsProps<S, Typename>,
) {
  const theme = getTheme();

  const drafts = map(
    () => props.drafts,
    (item, i) => (
      <div class={styles.draft}>
        <div
          class={[styles.header, 'list-draft-header']}
          onmount={attachChangelog('list-draft-header')}
        >
          <div class={styles.counter}>
            {() =>
              text('Common.DraftXofY', undefined, i() + 1, props.drafts.length)
            }
          </div>
          <button
            class={[theme.class.button, 'default']}
            type="button"
            onclick={() => {
              item.draft = false;
            }}
          >
            {text('Common.Publish')}
          </button>
        </div>
        {props.render(item, i)}
      </div>
    ),
  );

  return () => {
    if (!props.drafts.length || !props.open) return null;

    return (
      <div class={bind(() => [styles.items, props.class])}>
        {() => (props.renderContainer ? props.renderContainer(drafts) : drafts)}
      </div>
    );
  };
}
