import type { RouteDefinition } from '../createRouter';

export function isSameRoutesTree(
  a: RouteDefinition[],
  b: RouteDefinition[],
  lastSeenUrls: WeakMap<RouteDefinition, string>,
): boolean {
  // Check if both arrays have the same length
  if (a.length !== b.length) {
    return false;
  }

  // Compare each pair of RouteDefinitions
  for (let i = 0; i < a.length; i++) {
    if (!isDeepEqual(a[i], b[i], lastSeenUrls)) {
      return false;
    }
  }

  return true;
}

function isDeepEqual(
  a: RouteDefinition,
  b: RouteDefinition,
  lastSeenUrls: WeakMap<RouteDefinition, string>,
): boolean {
  // Check if the ids are the same
  if (a.id !== b.id) {
    return false;
  }

  // Check if the pathname changed
  if (lastSeenUrls.get(a) !== (a.fullPathname || a.pathname)) {
    return false;
  }

  // Check if both have children or neither have
  const aHasChildren = a.children && a.children.length > 0;
  const bHasChildren = b.children && b.children.length > 0;
  if (aHasChildren !== bHasChildren) {
    return false;
  }

  // If they have children, compare them using areArraysDeepEqual
  if (aHasChildren && bHasChildren) {
    return isSameRoutesTree(a.children!, b.children!, lastSeenUrls);
  }

  // If all checks pass, the RouteDefinitions are considered equal
  return true;
}
