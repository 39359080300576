import { bind } from '@donkeyjs/jsx-runtime';
import { getTheme } from '../../../styles';
import styles from './DateFilter.module.css';
import { MonthSelector } from './MonthSelector';
import { YearSelector } from './YearSelector';
import { useDateFilter } from './useDateFilter';

export interface DateFilterProps {
  key: string;
  value?: string;
  values?: string[];
}

export function DateFilter(props: DateFilterProps) {
  const theme = getTheme();
  const state = useDateFilter(props);

  return (
    <div class={styles.dateFilter}>
      <a
        class={bind(() => [
          theme.class.button,
          styles.future,
          'selectable',
          { selected: state.value === 'future' },
        ])}
        href={bind(() => state.getPath(undefined))}
      >
        Upcoming
      </a>
      <a
        class={bind(() => [
          theme.class.button,
          styles.past,
          'selectable',
          { selected: state.value === 'past' },
        ])}
        href={bind(() => state.getPath('past'))}
      >
        Past
      </a>
      <YearSelector state={state} />
      <MonthSelector state={state} />
    </div>
  );
}
