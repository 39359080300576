import { meta } from '../helpers/meta';
import type { Node } from './DataNode';
import { autoSortableField } from './autoSortableField';

export function getSortIndex<Fields extends { readonly __typename: string }>(
  sort: any, // QuerySortOrder
  items: Node<Fields>[],
  after?: Node<Fields> | null,
  before?: Node<Fields> | null,
): [fieldName: string | undefined, index: number | undefined] {
  const autoSort = autoSortableField(sort);

  if (!autoSort) return [undefined, undefined];

  let prevNode = after;
  let prev = (prevNode as any)?.[autoSort];
  while (meta(prevNode)?.isLocal || (prevNode != null && prev == null)) {
    prevNode = prevNode && items[items.indexOf(prevNode) - 1];
    prev = (prevNode as any)?.[autoSort];
  }

  let nextNode = before;
  let next = (nextNode as any)?.[autoSort];
  while (meta(nextNode)?.isLocal || (nextNode != null && next == null)) {
    nextNode = nextNode && items[items.indexOf(nextNode) + 1];
    next = (nextNode as any)?.[autoSort];
  }

  if (prev != null && next != null) return [autoSort, prev + (next - prev) / 2];
  if (prev != null) return [autoSort, prev + 1];
  if (next != null) return [autoSort, next - 1];
  return [autoSort, 0];
}
