import { bind } from '@donkeyjs/jsx-runtime';
import { getTheme } from '../../../styles';
import styles from './MonthSelector.module.css';
import type { DateFilterState } from './useDateFilter';

interface MonthSelectorProps {
  state: DateFilterState;
}

export function MonthSelector(props: MonthSelectorProps) {
  const theme = getTheme();
  const state = props.state;

  return (
    <div class={styles.months}>
      {[...Array(12)].map((_, index) => {
        const key = () => `${state.selectedYear}-${index + 1}`;
        return (
          <a
            $element={bind(() =>
              state.hasMonth(state.selectedYear, index) ? undefined : 'span',
            )}
            class={bind(() => [
              theme.class.button,
              styles.month,
              'selectable',
              {
                selected:
                  typeof state.value === 'object' &&
                  state.value.year === state.selectedYear &&
                  state.value.month === index + 1,
              },
            ])}
            href={bind(() =>
              state.hasMonth(state.selectedYear, index)
                ? state.getPath(key())
                : undefined,
            )}
          >
            {new Date(new Date().getFullYear(), index).toLocaleString(
              'default',
              { month: 'short' },
            )}
          </a>
        );
      })}
    </div>
  );
}
