import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import { store, type DataNode } from '@donkeyjs/proxy';

const contextKey = Symbol('node-selection-context');

export interface SelectionContext {
  scheduledSelect?: DataNode<DataSchema, 'Block'>;
}

export function createSelectionContext() {
  setContext(contextKey, store({}));
}

export function getSelectionContext() {
  return getContext<SelectionContext | undefined>(contextKey);
}
