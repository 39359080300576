import { store } from '@donkeyjs/proxy';
import {
  addDays,
  addMonths,
  isSameDay,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { getI18n } from '../../../i18n/getI18n';

export interface UseCalendarProps {
  date: Date;
  maxDate?: Date;
  minDate?: Date;
}

export type CalendarState = ReturnType<typeof useCalendar>;

export interface CalendarDay {
  otherMonth?: boolean;
  marks: CalendarMark[];
  date: Date;
}

export type CalendarMark = 'selected' | 'in-range' | 'disabled';

const days = [0, 1, 2, 3, 4, 5, 6];

export const useCalendar = (props: UseCalendarProps) => {
  const i18n = getI18n();

  return store({
    page: startOfMonth(props.date),

    get date() {
      return props.date;
    },

    set date(value) {
      props.date = value;
      if (
        value.getFullYear() !== this.page.getFullYear() ||
        value.getMonth() !== this.page.getMonth()
      ) {
        this.page = startOfMonth(this.date);
      }
    },

    get min() {
      return props.minDate && startOfDay(props.minDate);
    },

    get max() {
      return props.maxDate && startOfDay(props.maxDate);
    },

    get canGoBack() {
      return !this.min || this.page > this.min;
    },

    get canGoForward() {
      return !this.max || this.page < this.max;
    },

    get formattedMonth() {
      return i18n.formatDate(this.page, 'MMMM');
    },

    get formattedYear() {
      return i18n.formatDate(this.page, 'yyyy');
    },

    get visibleWeeks() {
      const result: CalendarDay[][] = [];

      const month = this.page.getMonth();
      const firstWeekDay = this.page.getDay();
      let day =
        firstWeekDay > 1 ? subDays(this.page, firstWeekDay - 1) : this.page;

      let isFirst = true;
      while (isFirst || day.getMonth() === month) {
        isFirst = false;
        result.push(
          days.map(() => {
            const current = day;
            day = addDays(day, 1);

            return {
              otherMonth: current.getMonth() !== month,
              marks: this.getMarks(current),
              date: current,
            };
          }),
        );
      }

      return result;
    },

    select(date: Date, options?: { keepTime?: boolean }) {
      if (this.getMarks(date).includes('disabled')) return;

      props.date = options?.keepTime
        ? setMilliseconds(
            setSeconds(
              setMinutes(
                setHours(date, props.date.getHours()),
                props.date.getMinutes(),
              ),
              props.date.getSeconds(),
            ),
            props.date.getMilliseconds(),
          )
        : date;

      if (date.getMonth() !== this.page.getMonth()) {
        this.page = startOfMonth(date);
      }
    },

    selectTime(hours: number | undefined, minutes: number | undefined) {
      let result = this.date;
      if (hours != null) result = setHours(result, hours);
      if (minutes != null) result = setMinutes(result, minutes);
      this.select(result);
    },

    goBack() {
      if (this.canGoBack) {
        this.page = subMonths(this.page, 1);
      }
    },

    goForward() {
      if (this.canGoForward) {
        this.page = addMonths(this.page, 1);
      }
    },

    getMarks(current: Date) {
      return [
        this.date && isSameDay(this.date, current) ? 'selected' : undefined,
        (this.min && current < this.min) || (this.max && current > this.max)
          ? 'disabled'
          : undefined,
      ].filter(Boolean) as CalendarMark[];
    },
  });
};
