import { bind } from '@donkeyjs/jsx-runtime';
import styles from './Time.module.css';
import type { CalendarState } from './useCalendar';

export function Time(props: Pick<CalendarState, 'date' | 'selectTime'>) {
  return [0, 1, 2, 3, 4, 5].map((row) => (
    <div class={styles.row}>
      {[0, 1, 2, 3, 4].map((col) => {
        const value = matrix[row][col];
        const isMinutes = col > 3;
        return (
          value !== null && (
            <button
              type="button"
              class={bind(() => [
                styles.cell,
                {
                  [styles.minutes]: isMinutes,
                  [styles.selected]: isMinutes
                    ? props.date?.getMinutes() === value
                    : props.date?.getHours() === value,
                },
              ])}
              onclick={() => {
                if (isMinutes) props.selectTime(undefined, value);
                else props.selectTime(value, undefined);
              }}
            >
              {value?.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
            </button>
          )
        );
      })}
    </div>
  ));
}

const matrix = [
  [0, 1, 2, 3, 0],
  [4, 5, 6, 7, 10],
  [8, 9, 10, 11, 15],
  [12, 13, 14, 15, 30],
  [16, 17, 18, 19, 45],
  [20, 21, 22, 23, 50],
];
