import { bind, getSyncState } from '@donkeyjs/jsx-runtime';
import { LoadingSingleLine, type BlockProps } from '..';
import styles from './QRCodeBlock.module.css';
import { meta } from '@donkeyjs/proxy';

export interface QRCodeBlockSettings {
  text?: string;
  maxWidth?: number | null;
}

export function QRCodeBlock(props: BlockProps<QRCodeBlockSettings>) {
  const state = getSyncState(props.block || props, () => ({
    rev: 0,
  }));

  let text = props.text;
  return (
    <div
      class={[styles.qrCode, props.class]}
      onmount={props.onmount}
      style={bind(() =>
        props.maxWidth ? { width: `${props.maxWidth}px` } : {},
      )}
    >
      {() => {
        if (text !== props.text) {
          text = props.text;
          state.rev++;
        }
        return props.text ? (
          meta(props.block)?.getField('settings').stored ? (
            <img
              class={styles.image}
              src={`/assets/qr-code/${props.block?.id}${
                state.rev > 0 ? `?rev=${state.rev}` : ''
              }`}
              alt={props.text}
            />
          ) : (
            <LoadingSingleLine size="small" />
          )
        ) : (
          <div>QR Code</div>
        );
      }}
    </div>
  );
}
