import { generateNodeId } from '../helpers/generateNodeId';
import { meta } from '../helpers/meta';
import type {
  AggregationFromSchema,
  FieldSchema,
  NodeSchema,
  NodeTypename,
  Schema,
  StatusFragment,
} from '../schema';
import { store } from '../store';
import { isDataNode } from './DataNode';
import type { NodeFactory } from './nodeFactory';
import { statusCollector } from './statusCollector';

export interface DataListAggregation<
  S extends Schema,
  Typename extends NodeTypename<S>,
> {
  result: AggregationFromSchema<S, Typename>;
  status: StatusFragment;
  hasRun: boolean;

  serialize(): any;

  process(values: AggregationFromSchema<S, Typename>): void;
}

export function createDataListAggregation<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  schema: S,
  schemaMeta: any,
  factory: NodeFactory<Schema>,
  typename: Typename,
  status: StatusFragment,
  serialized?: any,
): DataListAggregation<S, Typename> {
  const nodeSchema = schema.nodes[typename];

  const result = store<AggregationFromSchema<S, Typename>>(
    {},
    statusCollector({
      status,
      initializeField(fieldName) {
        const field = getFieldSchema(schema, schemaMeta, fieldName, nodeSchema);
        if (!field?.index && !field?.array) return;

        const fieldStatus = (status[fieldName] as StatusFragment) || {};
        return {
          status: fieldStatus,
          value: store<any>(
            {},
            statusCollector({
              status: fieldStatus,
              initializeField(key) {
                if (key !== 'values') {
                  return {
                    status: 'requested',
                  };
                }

                if (field.scalar || field.enum) {
                  return {
                    status: {
                      count: 'requested',
                      value: 'requested',
                    },
                    value: [],
                  };
                }

                const valueStatus: StatusFragment = { id: 'requested' };
                return {
                  status: {
                    count: 'requested',
                    value: valueStatus,
                  },
                  value: [
                    {
                      count: 1,
                      value: factory.ensureNode(
                        {
                          __typename: field.type,
                          id: `loading-${generateNodeId()}`,
                        },
                        { fieldStatus: valueStatus },
                      ),
                    },
                  ],
                };
              },
            }),
          ),
        };
      },
    }),
  );

  function serialize() {
    const serialized: any = {};
    for (const [key, value] of Object.entries(
      result.$.source as Record<string, any>,
    )) {
      serialized[key] = { ...value };
      for (const [subKey, values] of Object.entries(value as any)) {
        if (subKey === 'values') {
          serialized[key].values =
            values &&
            (values as any[]).map((value) => ({
              ...value,
              value: value.value.__typename
                ? { __typename: value.value.__typename, id: value.value.id }
                : value.value,
            }));
        }
      }
    }
    return { t: typename, s: serialized, f: status };
  }

  if (serialized) {
    for (const [key, value] of Object.entries(
      serialized as Record<string, any>,
    )) {
      const deserialized = { ...value };
      for (const [subKey, values] of Object.entries(value as any)) {
        if (subKey === 'values') {
          deserialized.values =
            values &&
            (values as any[]).map((value: any) => {
              const result: any = {};
              for (const [subKey, subValue] of Object.entries(
                value as Record<string, any>,
              )) {
                if (
                  subValue &&
                  typeof subValue === 'object' &&
                  subValue.__typename
                ) {
                  result[subKey] = factory.processNodeData(subValue);
                } else {
                  result[subKey] = subValue;
                }
              }
              return result;
            });
        }
      }
      (result.$.source as any)[key] = deserialized;
    }
  }

  return {
    status,
    result,
    hasRun: !!serialized,

    serialize,

    process(values) {
      this.hasRun = true;
      for (const [fieldName, fieldValue] of Object.entries(values)) {
        const fieldStatus = (status[fieldName] ??= {}) as StatusFragment;
        const fieldCache = ((result as any)[fieldName] ??= {});

        for (const [key, value] of Object.entries(fieldValue || {})) {
          if (key !== 'values') {
            fieldStatus[key] = 'ready';
            fieldCache[key] = value;
            continue;
          }

          const field = getFieldSchema(
            schema,
            schemaMeta,
            fieldName,
            nodeSchema,
          );
          const status = (fieldStatus[key] ??= {}) as StatusFragment;
          status.count = 'ready';

          if (field.enum || field.scalar) {
            status.value = 'ready';
            fieldCache[key] = value;
            continue;
          }

          for (const value of fieldCache.values) {
            // Delete placeholders
            if (isDataNode(value.value) && meta(value.value).isLoading) {
              value.value.$?.delete();
            }
          }
          for (const value of fieldCache.values) {
            if (meta(value.value).isLoading) {
              meta(value.value).delete();
            }
          }
          fieldCache.values = (value as { count: number; value: any }[]).map(
            ({ count, value }) => ({
              count,
              value: factory.processNodeData(value),
            }),
          );
        }
      }
    },
  };
}

function getFieldSchema(
  schema: Schema,
  schemaMeta: any,
  key: any,
  node: NodeSchema,
): FieldSchema {
  const [, groupId] = typeof key === 'string' ? key.split('_group_') : [];
  const fieldName = groupId ? 'tags' : key;
  const field =
    node.fields[fieldName as keyof (typeof node)['fields']] ||
    node.reverseFields[fieldName];

  const joinTypename = field?.type;
  const joinFieldName =
    joinTypename &&
    (schemaMeta?.[joinTypename]?.join?.[field.reverse!] as string | undefined);

  if (joinFieldName)
    return getFieldSchema(
      schema,
      schemaMeta,
      joinFieldName,
      schema.nodes[joinTypename],
    );

  return field;
}
