import type { Views } from '.';
import {
  AddFileRefPropertiesList,
  ViewFileRefPropertiesList,
} from '../layout/views/ViewFileRefPropertiesList';

export const defaultViews: Views<DataSchema> = {
  FileRef: {
    'properties-list': {
      component: ViewFileRefPropertiesList,
      renderAdd: AddFileRefPropertiesList,
      name: () => 'Properties list',
      hidden: true,
    },
  },
};
