import type { Culture } from '@donkeyjs/proxy';
import type { Locale as DateLocale } from 'date-fns';

export interface LocaleInput {
  formatNumber: (number: number, specifier: string) => string;
  formatDate: (date: Date, specifier: string) => string;
  dateLocale: DateLocale;
}

export type LocaleSet = {
  [culture in Culture]?: LocaleInput | (() => Promise<LocaleInput>);
};

const registeredLocales: {
  [culture in Culture]?: Locale;
} = {};

export function registerLocales(locales: LocaleSet) {
  for (const [culture, locale] of Object.entries(locales)) {
    registeredLocales[culture as Culture] ??= new Locale(
      culture as Culture,
      locale,
    );
  }
  return registeredLocales;
}

class Locale {
  private state: { loading: boolean; value?: LocaleInput };

  constructor(
    public readonly culture: Culture,
    private input: LocaleInput | (() => Promise<LocaleInput>) | undefined,
  ) {
    this.state = {
      loading: typeof input === 'function',
      value: typeof input === 'function' ? undefined : input,
    };
  }

  private ready() {
    if (typeof this.input === 'function') {
      this.input().then((value) => {
        this.state.loading = false;
        this.state.value = value;
      });
      this.input = undefined;
    }
    return !this.state.loading;
  }

  public get loading() {
    return this.state.loading;
  }

  public formatNumber(number: number, specifier: string) {
    if (!this.ready()) return '';
    return this.state.value?.formatNumber(number, specifier) ?? '';
  }

  public formatDate(date: Date, specifier: string) {
    if (!this.ready()) return '';
    return this.state.value?.formatDate(date, specifier) ?? '';
  }

  public get dateLocale() {
    if (!this.ready()) return undefined;
    return this.state.value?.dateLocale;
  }
}

export const getLocale = (culture: Culture) => {
  const locale = registeredLocales[culture];
  if (!locale) throw new Error(`No locale registered for culture ${culture}.`);

  return locale;
};
