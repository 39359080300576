import { createDatabaseRouter, type UseRouterOptions } from '@donkeyjs/core';
import { live } from '@donkeyjs/jsx-runtime';
import { extendedObject } from '@donkeyjs/proxy';

export const useBrowserRouter = (options: UseRouterOptions) => {
  const router = createDatabaseRouter(
    extendedObject(options, {
      plugins: options.plugins || [],
      pathname: document.location.pathname,
      queryString: document.location.search?.replace('?', ''),
    }),
  );

  window.addEventListener('popstate', () => {
    router.navigate(document.location.pathname, {
      queryString: document.location.search?.replace('?', ''),
    });
  });

  live(() => {
    if (
      !router.isLoading &&
      router.pathname !== '/404' &&
      (router.pathname !== document.location.pathname ||
        router.queryString !== document.location.search?.replace('?', ''))
    ) {
      const last = router.lastMutation;

      if (last.replace) history.replaceState({}, '', router.path);
      else history.pushState({}, '', router.path);

      if (!last.anchor && last.changedRoute) {
        document.scrollingElement?.scrollTo(0, 0);
      }
    }
  });

  return router;
};
