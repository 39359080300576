import {
  componentContext,
  getGlobal,
  live,
  withContext,
} from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';

const key = Symbol('inspect');

export const useInspect = () => {
  const state = getGlobal(key, () =>
    store({
      active: false,
      target: null as HTMLElement | null,
    }),
  );

  const context = componentContext.current!;

  let activated = false;

  live(() => {
    if (state.active && !activated) {
      activated = true;
      import('./useInspectWindow')
        .then(({ useInspectWindow }) => {
          withContext(context, () => {
            useInspectWindow(state);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  });

  const result = {
    get active() {
      return state.active;
    },
    set active(value: boolean) {
      state.active = value;
    },
    toggle() {
      state.active = !state.active;
    },
  };

  return result;
};
