import { getGlobal, onMount } from '@donkeyjs/jsx-runtime';

export const preferWorkspace = (workspace: string) => {
  const context = getGlobal<any>('workspace');

  onMount(() => {
    if (!context?.workspaces[workspace]) return;

    return context.prefer(workspace);
  });
};
