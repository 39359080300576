import type { BlockPreset, BlocksType } from '@donkeyjs/client';
import { PhShare } from '@donkeyjs/phosphor-icons';
import { I18nSocialButtons } from '../../../i18n';
import {
  SocialButtonsBlock,
  type SocialButtonsSettings,
} from './SocialButtonsBlock';

export const blocks: BlocksType = {
  'social-buttons': { component: SocialButtonsBlock },
};

export const blockPresets: BlockPreset[] = [
  {
    name: (i18n) => i18n.get(I18nSocialButtons, 'SocialMedia'),
    group: () => 'Widgets',
    icon: () => <PhShare weight="duotone" />,
    hue: 180,
    values: {
      type: 'social-buttons',
      settings: { colorHover: true } as SocialButtonsSettings,
    },
  },
];
