import { bind } from '@donkeyjs/jsx-runtime';
import { meta, store, validateEmailAddress } from '@donkeyjs/proxy';
import type { BlockProps } from '.';
import { useForm } from '../data/useForm';
import { text } from '../i18n/i18n';
import { setLayout } from '../layout/layoutContext';
import { session } from '../session';
import { getTheme } from '../styles';

export interface ContactFormSettings {
  sendToUserId?: string;
  enableSubject?: boolean;
  optinMailingListId?: string;
  sentMessage?: string;
}

const key = Symbol('contact-form');

// TODO: generic form component (use in subscribeForm.ts)

export function ContactFormBlock(props: BlockProps<ContactFormSettings>) {
  if (!props.block) throw new Error('Block is required for contact form.');

  const theme = getTheme();

  // Todo: fix this in ssr (is not a function/is undefined)
  setLayout?.('html');

  const form = useForm(
    key,
    {
      name: 'string',
      email: ['string', { validate: validateEmailAddress }],
      subject: 'string?',
      message: 'string',
      optinMailingList: 'boolean?',
    },
    { name: '', email: '', message: '' },
  );

  const state = store({
    status: 'idle' as 'idle' | 'submitting' | 'submitted',
  });

  const submit = async (ev: Event) => {
    ev.preventDefault();

    if (form.isValid()) {
      state.status = 'submitting';

      const result = await session.data.mutation.submitContactForm({
        id: props.block!.id,
        name: form.values.name,
        email: form.values.email,
        message: form.values.message,
        subject: form.values.subject || undefined,
      });

      if (result.errors) {
        meta(form.values).errors = result.errors;
        state.status = 'idle';
      } else {
        form.reset();
        state.status = 'submitted';
      }
    }
  };

  return (
    <form
      onmount={props.onmount}
      class={bind(() => [props.class, 'contact-form'])}
      onsubmit={submit}
    >
      {() => {
        if (state.status === 'submitted')
          return props.sentMessage || text('ContactForm.SentMessage');

        return (
          <>
            {/* <Errors node={form.values} /> */}
            <form.values.$.name label={text('ContactForm.Name')} />
            <form.values.$.email label={text('ContactForm.Email')} />
            {() =>
              props.enableSubject && (
                <form.values.$.subject label={text('ContactForm.Subject')} />
              )
            }
            <form.values.$.message
              multiline
              label={text('ContactForm.Message')}
            />
            {() =>
              props.optinMailingListId && (
                <form.values.$.optinMailingList
                  label={text('ContactForm.OptinMailingList')}
                />
              )
            }
            <div class={theme.class.buttons}>
              <button
                type="submit"
                class={[theme.class.button, 'default']}
                disabled={bind(() => state.status === 'submitting')}
                action="submit"
              >
                {text('Common.Submit')}
              </button>
            </div>
          </>
        );
      }}
    </form>
  );
}
