import { bind, setState } from '@donkeyjs/jsx-runtime';
import type { BlockProps } from '.';
import { getTheme } from '../styles';
import styles from './LayoutBlock.module.css';
import { ChildBlocks } from './helpers/ChildBlocks';

export interface LayoutSettings {
  kind?: 'flex' | 'grid';
  width?: 'sm' | 'lg' | 'xl';
  direction?: string;
  align?: string;
  justify?: string;
}
export function LayoutBlock(props: BlockProps<LayoutSettings>) {
  const theme = getTheme();

  const state = setState({
    get kind() {
      return props.kind ?? 'flex';
    },
    get width() {
      return props.width ? (`width-${props.width}` as const) : undefined;
    },
  });

  return (
    <ChildBlocks
      class={bind(() => [
        props.class,
        theme.class.block,
        styles[state.kind],
        state.width && styles[state.width],
      ])}
      style={bind(() => ({
        alignItems: props.align,
      }))}
      onmount={props.onmount}
      parentProps={props}
    />
  );
}
