import { getContext, setContext } from '@donkeyjs/jsx-runtime';

const contextKey = Symbol('view');

export interface ViewContext {
  preferredView?: string;
  preferredListView?: string;
}

export function getViewContext() {
  return getContext<ViewContext | undefined>(contextKey);
}

export function setViewContext(context: ViewContext) {
  setContext(contextKey, context);
}
