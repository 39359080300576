import type { DataNode } from '@donkeyjs/proxy';
import type { MailContext } from '../mail/mailContext';
import { session } from '../session';

export interface AssetUrlOptions {
  convertTo?: 'jpg' | 'png' | 'webp' | 'gif' | 'jp2' | 'tiff' | 'avif' | 'heif';
  width?: number;
  height?: number;
  crop?: 'entropy' | 'attention';
  download?: boolean;
  mailContext?: MailContext | null;
}

export const getAssetUrl = (
  file: DataNode<DataSchema, 'File'>,
  {
    convertTo,
    crop,
    download,
    height,
    mailContext,
    width,
  }: AssetUrlOptions = {},
) => {
  const query: string[] = [];
  if (width) query.push(`w=${width}`);
  if (height) query.push(`h=${height}`);
  if (crop) query.push(`c=${crop === 'entropy' ? 'e' : 'a'}`);
  if (download != null) query.push(`dl=${download ? 1 : 0}`);

  return [
    `${mailContext?.target === 'mail' ? session.router.hostname : ''}/assets/${
      file.id
    }.${convertTo || file.fileExtension}`,
    query.join('&'),
  ]
    .filter(Boolean)
    .join('?');
};
