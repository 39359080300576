import type { RouterDataBlocks } from '@donkeyjs/core';
import type { SupportedCulture } from '@donkeyjs/proxy';
import type { DataSettings } from '../blocks';
import { formulaContext, whereFromFilters } from '../data/filters';
import { session } from '../session';

export const getRouterDataBlocks = () => {
  const list = session.data.createList({
    typename: 'Block',
    match: { where: { type: { eq: 'data' } } },
  });

  return (culture: SupportedCulture) => {
    const result: RouterDataBlocks = { routeIds: {}, typenames: {} };

    for (const block of list) {
      if (block.culture !== culture) continue;

      const settings = block.settings as DataSettings;
      if (settings?.selection !== 'url') continue;

      const resolver = settings.resolver;
      const typename = resolver && session.data.schema.resolvers[resolver];
      if (!typename) continue;

      const route = block.onRouteBlocks;
      if (!route) continue;

      const dataBlock = (result.typenames[typename] ??= {
        filtered: [],
      });
      if (settings.where)
        dataBlock.filtered.push({
          block,
          args: {
            where: whereFromFilters(settings.where, formulaContext(block)),
          },
        });
      else dataBlock.unfiltered = block;

      result.routeIds[route.id] = typename;
    }

    return result;
  };
};
