import { onHybridMount, onUnmount } from '@donkeyjs/jsx-runtime';
import {
  batch,
  createDataList,
  createDataListAggregation,
  dontWatch,
  fragmentFromFieldStatus,
  listMatchFromResolverArgs,
  meta,
  watch,
  type AppSchema,
  type DataListAggregation,
  type NodeFactory,
  type NodeTypename,
  type Schema,
  type StatusFragment,
} from '@donkeyjs/proxy';
import type { ClientSchemaMeta } from '../schema/clientSchemaMetaUtils';
import { session } from '../session';
import type { QueryVariables } from './createDataClient';
import type { FetchDataFunction } from './fetch';

export function createDataCache<S extends Schema, A extends AppSchema>(
  schema: A,
  schemaMeta: ClientSchemaMeta<S> | undefined,
  factory: NodeFactory<Schema>,
  fetch: FetchDataFunction,
) {
  function getAggregate(
    key: string,
    typename: string,
    status: StatusFragment,
  ): DataListAggregation<any, any> | undefined {
    if (!cache.aggregations.has(key)) {
      cache.aggregations.set(
        key,
        createDataListAggregation<any, any>(
          schema,
          schemaMeta,
          factory,
          typename,
          status,
        ),
      );
    }
    return cache.aggregations.get(key);
  }

  function prepareFetch(
    resolverName: string,
    typename: string,
    returnsMany: boolean,
    args: any,
    status: StatusFragment,
    aggregateStatus: StatusFragment | undefined,
  ) {
    const { skipExecution, ...options } = args;
    const optionsKey = JSON.stringify(options);
    const key = `${typename}:${resolverName}:${optionsKey}`;
    const aggregateKey = aggregateStatus
      ? `${typename}:${optionsKey}`
      : undefined;
    const aggregate = aggregateKey
      ? getAggregate(aggregateKey, typename, aggregateStatus!)
      : undefined;
    const resolver = schema.nodes[typename].resolvers?.[resolverName];

    let matchOptions: any;
    if (returnsMany) {
      matchOptions = options;
    } else {
      const { source, ...where } = options;
      matchOptions = { source, where };
    }

    const fromCache = cache.requests.get(key);
    if (fromCache) {
      Object.assign(status, fromCache.n);
      if (aggregateStatus) Object.assign(aggregateStatus, fromCache.a);
    }

    return {
      skipExecution,
      hasRun: !!fromCache && (!aggregate || aggregate.hasRun),
      markRun() {
        cache.requests.set(key, { n: status, a: aggregateStatus });
      },
      aggregate,
      options,
      status,
      resolver,
      match: listMatchFromResolverArgs(
        schema,
        typename,
        resolverName,
        matchOptions,
        false,
      ),
    };
  }

  const cache = {
    requests: new Map<string, StatusFragment>(),
    aggregations: new Map<string, DataListAggregation<S, NodeTypename<S>>>(),

    useData({
      typename,
      resolverName,
      returnsMany,
      returnsAggregation = false,
      forUnion,
      args,
      addArgs,
      placeholderCount,
    }: {
      typename: string;
      resolverName: string;
      returnsMany: boolean;
      returnsAggregation?: boolean;
      forUnion?: string;
      args: QueryVariables;
      addArgs?: QueryVariables;
      placeholderCount?: number;
    }) {
      const fieldStatus: StatusFragment = { id: 'requested' };
      let aggregateStatus: StatusFragment | undefined = returnsAggregation
        ? {}
        : undefined;

      const request = dontWatch(() =>
        prepareFetch(
          resolverName,
          typename,
          returnsMany,
          args,
          fieldStatus,
          aggregateStatus,
        ),
      );

      if (request.hasRun) fieldStatus.id = 'ready';

      const result = createDataList<any, any>({
        factory,
        match: request.match,
        sort: request.match.sort,
        aggregate: request.aggregate?.result,
        schema,
        typename,
        fieldStatus,
        loading: !request.hasRun,
        placeholderCount,
      });

      if (request.aggregate) {
        aggregateStatus = request.aggregate.status;
      }

      onUnmount(() => {
        meta(result).dispose();
      });

      onHybridMount(() => {
        const dispose = watch(() => {
          const request = prepareFetch(
            resolverName,
            typename,
            returnsMany,
            args,
            fieldStatus,
            aggregateStatus,
          );
          if (request.skipExecution) {
            batch(() => {
              meta(result).isLoading = false;
              meta(result).match = request.match;
              meta(result).sort = request.match.sort;
            });
            return;
          }
          if (request.hasRun) {
            batch(() => {
              meta(result).isLoading = false;
              meta(result).match = request.match;
              meta(result).sort = request.match.sort;
              result.aggregate = request.aggregate?.result;
            });
            return;
          }
          const fragment = fragmentFromFieldStatus(fieldStatus, true);
          fetch({
            fragment: aggregateStatus
              ? {
                  nodes: fragment!,
                  aggregate: fragmentFromFieldStatus(aggregateStatus, true),
                }
              : fragment!,
            options: { ...request.options, ...addArgs },
            resolverName,
            resolver: request.resolver,
            schema,
            nodeSchema: schema.nodes[typename],
            returnsAggregation,
            forUnion,
            process(data) {
              request.markRun();
              meta(result).isLoading = false;
              meta(result).match = request.match;
              meta(result).sort = request.match.sort;
              if (request.aggregate) {
                if (data.data?.aggregate) {
                  request.aggregate.process(data.data.aggregate);
                } else {
                  request.aggregate.hasRun = true;
                }
              }
              result.aggregate = request.aggregate?.result;
            },
          }).catch((error) => {
            console.error(error);
          });
        }).dispose;

        return () => {
          if (session.dom.ssr) {
            dispose();
          }
        };
      });

      return result;
    },
  };

  return cache;
}
