import { live } from '@donkeyjs/jsx-runtime';
import { getUserContext, type UserContext } from '../authentication';
import { setTheme } from '../styles';
import { donkeyThemes } from './theme';
import { store } from '@donkeyjs/proxy';

export const getDonkeyTheme = (
  user: UserContext | null | undefined,
  override?: 'LIGHT' | 'DARK',
) => donkeyThemes[override || user?.theme || 'DARK'];

export const applyDonkeyTheme = (
  user: UserContext | null | undefined,
  override?: 'LIGHT' | 'DARK',
) => {
  const state = store({
    theme: undefined as ReturnType<typeof getDonkeyTheme> | undefined,
  });
  live(() => {
    state.theme = setTheme(getDonkeyTheme(user, override));
  });
  return () => state.theme!;
};

export const useDonkeyTheme = (override?: 'LIGHT' | 'DARK') => {
  const user = getUserContext();
  return applyDonkeyTheme(user, override);
};
