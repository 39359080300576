import { bind } from '@donkeyjs/proxy';
import { Animation } from './Animation';

export function LoadingText() {
  return (
    <svg
      class="loading"
      width="100%"
      height="72px"
      viewBox="0 0 500 72"
      preserveAspectRatio="none"
    >
      <title>Loading...</title>
      <rect
        x="0"
        y="8"
        rx="3"
        ry="3"
        width="85%"
        height="8"
        style="fill:url(#loading)"
      />
      <rect
        x="0"
        y="30"
        rx="3"
        ry="3"
        width="100%"
        height="8"
        style="fill:url(#loading)"
      />
      <rect
        x="0"
        y="52"
        rx="3"
        ry="3"
        width="55%"
        height="8"
        style="fill:url(#loading)"
      />
      <Animation />
    </svg>
  );
}

export type LoadingSingleLineSize =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'full';

interface LoadingSingleLineProps {
  size?: LoadingSingleLineSize;
}

export function LoadingSingleLine(props: LoadingSingleLineProps) {
  const size = () =>
    props.size === 'xsmall'
      ? 30
      : props.size === 'small'
        ? 60
        : props.size === 'medium'
          ? 180
          : props.size === 'large'
            ? 240
            : undefined;

  return (
    <svg
      class="loading"
      width={bind(() => size() || '100%')}
      height="14px"
      viewBox={bind(() => `0 0 ${size() || 500} 14`)}
      preserveAspectRatio="none"
      fill="currentColor"
    >
      <title>Loading...</title>
      <rect
        x="0"
        y="3"
        rx="3"
        ry="3"
        width="100%"
        height="8"
        style="fill:url(#loading)"
      />
      <Animation />
    </svg>
  );
}
