import type { NodeTypename, QuerySortOrder, Schema } from '../schema';

export function autoSortableField<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(sort: QuerySortOrder<S, S['nodes'][Typename]> | undefined) {
  return sort
    ?.map((key) => key.split(':'))
    .find(([, direction]) => direction === 'auto')?.[0];
}
