import { bind } from '@donkeyjs/jsx-runtime';
import type { FieldRenderProps } from '../..';
import { Label } from '../../components/Label';
import { File } from './File';
import styles from './HtmlFile.module.css';

export function HtmlFile(props: FieldRenderProps<'File'>) {
  return (
    <Label
      class="input align-start"
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      onmount={props.onmount}
    >
      <div>
        <div class={styles.file}>
          <File
            value={bind(
              () => props.field.value,
              (value) => {
                props.field.value = value;
              },
            )}
            ratio={bind(() => props.ratio)}
            readonly={bind(() => props.readonly)}
            changeOnClick={bind(() => !props.readonly)}
            onmount={bind(() => props.onmount)}
          />
        </div>
      </div>
    </Label>
  );
}
