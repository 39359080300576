import { meta, type Culture } from '@donkeyjs/proxy';
import type { RoutingMap } from '../RoutingMap';
import type { RouteDefinition, Router } from '../createRouter';
import type { RouterPlugin, RoutingStatus, UrlQuery } from '../types';
import { decodeQueryString } from './encodeAndDecodeQuery';

export const route404 = {
  id: '404',
  name: '404',
  pathname: '/404',
};

export function getRouterStateFromPath(
  router: Router,
  pathname: string,
  queryString: string | null | undefined,
  anchor: any,
  plugins: RouterPlugin[],
): {
  culture: Culture;
  route: RouteDefinition;
  query: UrlQuery;
  anchor?: any;
  loginRequested?: boolean;
} {
  const segments = pathname.replace(/\/$/, '').slice(1).split('/');

  const culture = extractCulture(router, segments);
  const { route, unusedSegments } = findClosestRoute(
    router.map,
    culture,
    segments,
  );

  if (!route) {
    return {
      culture,
      route: route404,
      query: {},
    };
  }

  let status: RoutingStatus = {
    route,
    segments: unusedSegments,
    query: decodeQueryString(queryString),
    anchor,
  };

  for (const plugin of plugins) {
    if (plugin.decode) {
      status = plugin.decode(status, router);
    }
  }

  if (status.segments.length) {
    return pathname === '/login'
      ? {
          culture,
          route,
          query: {},
          loginRequested: true,
        }
      : {
          culture,
          route: route404,
          query: {},
        };
  }

  return {
    culture,
    route: status.route,
    query: status.query,
    anchor: status.anchor,
  };
}

function extractCulture(router: Router, segments: string[]) {
  if (router.cultures.length <= 1) return router.defaultCulture;

  const culture = router.cultures.find(
    (culture) => culture.toLowerCase() === segments[0]?.toLowerCase(),
  );
  if (culture) segments.shift();

  return culture || router.defaultCulture;
}

function findClosestRoute(
  map: RoutingMap,
  culture: Culture,
  segments: string[],
): { route: RouteDefinition | undefined; unusedSegments: string[] } {
  const unusedSegments: string[] = [];
  let route: RouteDefinition | undefined = map.routeByPath(culture, '/');

  if (meta(route?.node)?.isLoading) {
    route = {
      ...route!,
      pathname: `/${segments.join('/')}`,
    };
  } else {
    route = map.routeByPath(culture, `/${segments.join('/')}`);
    while (!route && segments.length) {
      unusedSegments.unshift(segments.pop()!);
      route = map.routeByPath(culture, `/${segments.join('/')}`);
    }
  }
  return { route, unusedSegments };
}
