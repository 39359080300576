import { bind, live, setState } from '@donkeyjs/jsx-runtime';
import type { DataError } from '@donkeyjs/proxy';
import type { EmbedType } from '../../../components/Embed';
import { session } from '../../../session';
import { getTheme } from '../../../styles';
import { Errors } from '../../components/Errors';
import styles from './EmbedEditor.module.css';

export interface EmbedEditorProps {
  embed?: EmbedType | null;
  helper?: JSX.Children;
}

export function EmbedEditor(props: EmbedEditorProps) {
  const theme = getTheme();

  const state = setState({
    loading: false,
    failedUrls: {} as Record<string, DataError[]>,
    url: props.embed?.originalUrl || '',
  });

  const setErrors = (errors: DataError[]) => {
    props.helper = errors.length ? (
      <Errors class="helper" additionalErrors={errors} />
    ) : undefined;
  };

  live(() => {
    if (!state.loading && state.url) {
      const isCurrent = state.url === props.embed?.originalUrl;
      const knownErrors = state.failedUrls[state.url];

      setErrors(knownErrors || []);

      if (!knownErrors && !isCurrent) {
        setErrors([]);
        state.loading = true;
        const url = state.url;
        session.data.query.fetchEmbed({ url }).then(({ data, errors }) => {
          state.loading = false;
          if (errors?.length) {
            setErrors(errors);
            state.failedUrls[url] = errors;
          } else {
            props.embed = { originalUrl: url, ...(data as any) };
            if (props.embed!.provider_url) state.url = url;
          }
        });
      }
    }
  });

  return (
    <input
      type="text"
      class={[styles.input, theme.class.input]}
      placeholder="Type or paste a URL"
      value={bind(state, 'url')}
    />
  );
}
