import {
  batch,
  isDataList,
  meta,
  store,
  type DataList,
  type DataNode,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import { getUserContext } from '../authentication';
import { getI18n, type I18nContext } from '../i18n/getI18n';

export interface UseListProps<
  S extends Schema,
  Typename extends NodeTypename<S>,
> {
  readonly data:
    | DataList<S, Typename>
    | DataNode<S, Typename>[]
    | null
    | undefined;
  filter?: (item: DataNode<S, Typename>) => boolean;

  readonly noDrafts?: boolean;
  readonly ungroupDrafts?: boolean;
  readonly isInIsolation?: boolean;

  readonly group?: ListGroupFunction<S, Typename>;
}

export interface ListNodes<S extends Schema, Typename extends NodeTypename<S>> {
  drafts: DataNode<S, Typename>[];
  published: DataNode<S, Typename>[];
}

export interface ListGroups<
  S extends Schema,
  Typename extends NodeTypename<S>,
> {
  name: string;
  items: DataNode<S, Typename>[];
}

export type ListGroupFunction<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = (
  item: DataNode<S, Typename>,
  i18n: I18nContext,
) => string | { name: string; sortIndex: number };

export type ListState<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = ReturnType<typeof useList<S, Typename>>;

export const useList = <S extends Schema, Typename extends NodeTypename<S>>(
  props: UseListProps<S, Typename>,
) => {
  const i18n = getI18n();
  const user = getUserContext();

  type Group = { name: string; items: DataNode<S, Typename>[] };
  const groupsIndex: Map<string, Group> = new Map();

  return store({
    get isLoading() {
      return !!(
        props.data &&
        isDataList(props.data) &&
        meta(props.data).isLoading
      );
    },

    get canInsert() {
      return (
        !!props.data &&
        isDataList(props.data) &&
        user.can('insert', meta(props.data).typename as any)
      );
    },

    get nodes(): ListNodes<S, Typename> {
      if (props.ungroupDrafts || props.noDrafts || props.isInIsolation) {
        return {
          drafts: [],
          published:
            (props.noDrafts
              ? props.data?.filter(
                  (item) =>
                    !item.draft && (!props.filter || props.filter(item)),
                )
              : props.data) || [],
        };
      }

      if (!props.data) return { drafts: [], published: [] };

      return props.data.reduce<ListNodes<S, Typename>>(
        (acc, item) => {
          if (props.filter && !props.filter(item)) return acc;
          if (item.draft) acc.drafts.push(item);
          else acc.published.push(item);
          return acc;
        },
        {
          drafts: [],
          published: [],
        },
      );
    },

    get groups() {
      function getGroup(name: string) {
        if (!groupsIndex.has(name)) {
          const group = store({ name, items: [] });
          groupsIndex.set(name, group);
        }
        return groupsIndex.get(name)!;
      }

      const groups: Group[] = [];

      batch(() => {
        if (!props.group) {
          const group = getGroup('');
          group.items = this.nodes.published;
          groups.push(group);
          return;
        }

        const seen = new Set<string>();
        for (const item of this.nodes.published) {
          const currentGroup = props.group?.(item, i18n) || '';
          const { name, sortIndex } =
            typeof currentGroup === 'string'
              ? { name: currentGroup, sortIndex: groups.length }
              : currentGroup;
          const group = getGroup(name);
          if (!seen.has(name)) {
            seen.add(name);
            groups[sortIndex] = group;
            group.items = [];
          }
          group.items.push(item);
        }
      });

      return groups;
    },

    draftsOpen: false,
  });
};
