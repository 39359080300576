import type { Component } from '@donkeyjs/jsx-runtime';
import { LoadingSingleLine, LoadingText } from './LoadingText';

export type LoaderType =
  | 'inline-xsmall'
  | 'inline-small'
  | 'inline-medium'
  | 'inline-large'
  | 'inline-full'
  | 'multi-line'
  | 'none';

interface LoaderProps {
  loading: boolean;
  type?: LoaderType | Component;
  children?: JSX.Children;
}

export function Loader(props: LoaderProps) {
  return () => {
    if (!props.loading) return props.children;

    // Return loader
    return (
      <>
        <div style="display: none">{props.children}</div>
        {props.type === 'multi-line' ? (
          <LoadingText />
        ) : props.type === 'inline-full' ? (
          <LoadingSingleLine size="full" />
        ) : props.type === 'inline-large' ? (
          <LoadingSingleLine size="large" />
        ) : props.type === 'inline-medium' ? (
          <LoadingSingleLine size="medium" />
        ) : !props.type || props.type === 'inline-small' ? (
          <LoadingSingleLine size="small" />
        ) : props.type === 'inline-xsmall' ? (
          <LoadingSingleLine size="xsmall" />
        ) : props.type === 'none' ? null : (
          props.type({})
        )}
      </>
    );
  };
}

interface LoadingProps {
  type?: LoaderType | Component;
}

export function Loading(props: LoadingProps) {
  return (
    <Loader loading type={props.type}>
      {null}
    </Loader>
  );
}
