import { bind, live } from '@donkeyjs/jsx-runtime';
import { store, type DataNode } from '@donkeyjs/proxy';
import type { BlockProps } from '.';
import { setLayout } from '../layout';
import { getMailContext } from '../mail';
import { session } from '../session';
import { getTheme } from '../styles';
import styles from './ButtonBlock.module.css';

export interface ButtonProps {
  linkTo?: string;
  highlight?: boolean;
  fileName?: string;
  files?: DataNode<DataSchema, 'FileRef'>[];
  label?: string;
  size?: 'small' | 'medium' | 'large';
}

export function ButtonBlock(props: BlockProps<ButtonProps>) {
  const theme = getTheme();
  const mail = getMailContext();

  setLayout('inline');

  const state = store({
    link: undefined as string | undefined,

    get files() {
      return props.files || props.block?.files;
    },
  });

  live(() => {
    if (props.linkTo) state.link = props.linkTo;
    else if (mail?.target !== 'mail' && state.files?.length === 1) {
      state.link = '';
      session.data.query
        .signedFileUrl({ fileId: state.files[0].file.id })
        .then((result) => {
          state.link = result.data as string;
        })
        .catch((err) => {
          console.error(err);
          state.link = '';
        });
    } else if (state.files && state.files.length > 1) {
      state.link = `/assets/archive.zip?${[
        ...state.files.map(
          (ref) =>
            `file=${ref.file.id}&name=${encodeURIComponent(
              ref.name || ref.file.name,
            )}`,
        ),
        props.fileName
          ? `fn=${props.fileName.replace(/\.zip$/i, '')}.zip`
          : undefined,
      ]
        .filter(Boolean)
        .join('&')}`;
    } else {
      state.link = undefined;
    }
  });

  return (
    <a
      $element={bind(() => (props.readonly ? undefined : 'span'))}
      class={bind(() => [
        props.class,
        theme.class.block,
        theme.class.button,
        styles.button,
        {
          default: props.highlight,
          [styles.small]: props.size === 'small',
          [styles.large]: props.size === 'large',
        },
      ])}
      href={bind(() =>
        props.readonly ? state.link : (undefined as unknown as string),
      )}
      onmount={props.onmount}
    >
      {props.label ?? (props.block && <props.block.$.text label="Label" />)}
    </a>
  );
}
