import type { FieldSchema, FieldStatus, StatusFragment } from '../schema';

export function updateFieldStatus(
  status: StatusFragment,
  key: string,
  field: FieldSchema | undefined,
  value: FieldStatus,
) {
  if (!field || field.scalar || field.enum) {
    status[key] = value;
    if ((field as FieldSchema<'string'>)?.markup) {
      status[`${key}Markup`] = value;
    }
  } else {
    const nested = (status[key] ??= {}) as StatusFragment;
    nested.id = value;
    if (field.reverse) {
      nested[field.reverse] = value;
    }
  }
}
