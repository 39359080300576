import { getMailContextFromUrl } from '@donkeyjs/core';
import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import { session } from '../session';
import type { Theme } from '../styles/Theme';

const key = Symbol('mailContext');

export interface MailContext {
  target: 'browser' | 'mail';
  theme: Theme;
  setTheme: (theme: Theme) => MailContext;
}

export const getMailContext = () => getContext<MailContext | undefined>(key);

export function setMailContext(context: Omit<MailContext, 'setTheme'>) {
  const newContext: MailContext = {
    ...context,
    setTheme: (theme) => setMailContext({ ...context, theme }),
  };
  setContext(key, newContext);
  return newContext;
}

export function ensureMailContext() {
  const context = getMailContext();
  if (!context)
    return setMailContext({ target: 'browser', theme: session.app.theme });
  return context;
}

export function setMailContextFromUrl(theme: Theme) {
  const context = getMailContextFromUrl(session.router);
  if (context) setMailContext({ target: context, theme });
}
