import type { MarkupFormatting, MarkupStringProcessor } from '@donkeyjs/proxy';
import type { BlockEditorInfo } from '../../fields/blocks/BlocksEditor';
import type { TextBlockType } from '../../fields/blocks/helpers/getTextBlockElement';

export interface MarkupInterface {
  multiline: boolean;
  passThroughKeys?: string[];
  splice(
    processor: MarkupStringProcessor,
    index: number,
    count: number,
    add?: string,
  ): void;
  insertText(
    processor: MarkupStringProcessor,
    fromIndex: number,
    toIndex: number,
    add: string,
  ): void;
  insertParagraphText(
    processor: MarkupStringProcessor,
    fromIndex: number,
    toIndex: number,
    add: string[],
  ): [BlockEditorInfo, number] | null;
  removeText(
    processor: MarkupStringProcessor,
    index: number,
    count: number,
  ): void;
  removeTextForward(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): [mergedWith: BlockEditorInfo, index: number] | null | undefined;
  removeTextBackward(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): [mergedWith: BlockEditorInfo, index: number] | null | undefined;
  toggleFormatting(
    processor: MarkupStringProcessor,
    index: number,
    toIndex: number,
    formatting: MarkupFormatting['f'],
  ): void;
  split(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): BlockEditorInfo | null;
  merge(
    processor: MarkupStringProcessor,
    direction: 'forward' | 'backward',
  ): [mergedWith: BlockEditorInfo, index: number] | null;
  getType(): TextBlockType | null | undefined;
  setType(
    type: TextBlockType | null | undefined,
  ): HTMLElement | null | undefined;
  getAlign(): 'left' | 'center' | 'right';
  setAlign(align: 'left' | 'center' | 'right'): void;

  copy(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): Promise<void>;
  cut(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): Promise<void>;
  paste(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): Promise<[BlockEditorInfo, number] | null>;
}

export const singleBlockMarkupInterface: MarkupInterface = {
  multiline: false,

  passThroughKeys: ['Enter', 'Escape'],

  splice(processor, index, count, add) {
    processor.splice(index, count, add);
  },

  insertText(processor, fromIndex, toIndex, add) {
    processor.replaceText(fromIndex, toIndex, add);
  },

  insertParagraphText(processor, fromIndex, toIndex, paragraphs) {
    this.insertText(processor, fromIndex, toIndex, paragraphs.join(' '));
    return null;
  },

  removeText(processor, index, count) {
    processor.removeText(index, count);
  },

  removeTextForward(processor, from, to) {
    processor.removeTextForward(from, to);
    return null;
  },

  removeTextBackward(processor, from, to) {
    processor.removeTextBackward(from, to);
    return null;
  },

  toggleFormatting(processor, index, toIndex, formatting) {
    processor.toggleFormatting(index, toIndex, formatting);
  },

  split() {
    return null;
  },

  merge() {
    return null;
  },

  getType() {
    return undefined;
  },

  setType() {
    return undefined;
  },

  getAlign() {
    return 'left';
  },

  setAlign() {},

  async copy(processor, from, to) {
    const text = processor.extract(from, to);
    await navigator.clipboard.writeText(text);
  },

  async cut(processor, from, to) {
    await this.copy(processor, from, to);
    processor.removeText(from, to - from);
  },

  async paste(processor, from, to) {
    const paragraphs = (await navigator.clipboard.readText())
      .replace(/\r/g, '')
      .split(/\n/g);
    this.insertParagraphText(processor, from, to, paragraphs);
    return null;
  },
};
