import { type ClientSchemaMeta, session } from '@donkeyjs/client';
import { getSchemaI18n } from '@donkeyjs/core';

import { PhShare } from '@donkeyjs/phosphor-icons';
import { meta } from '@donkeyjs/proxy';

export const clientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  SocialButton: {
    asString: (node) =>
      getSchemaI18n(session.app.schema, meta(node).culture).getEnumValue(
        'SocialMediaPlatform',
        node.platform,
      ),
    icon: () => PhShare,
  },
};
