import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import type { LayoutRenderer } from '.';
import type { Layout, Layouts } from './layouts';

const key = Symbol('layout');

export interface LayoutContext extends LayoutRenderer<string> {
  wrap?: 'flow';
  layout: Layout;
  all: Layouts;
}

export const setLayout = (
  layout: Layout | `${Layout}:flow`,
  layouts: Layouts = getLayoutContext().all,
) => {
  const [renderer, wrap] = layout.split(':');
  const context: LayoutContext = {
    ...(layouts[renderer as Layout] as LayoutRenderer<string>),
    wrap: wrap as 'flow',
    layout: renderer as Layout,
    all: layouts,
  };
  setContext(key, context);
  return context;
};

export const getLayoutContext = (): LayoutContext => {
  return getContext<LayoutContext>(key);
};
