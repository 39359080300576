import { computed, signal, type Signal } from '@preact/signals-core';
import { resolveBinding } from '../bind';
import type { DataList } from '../cache/DataList';
import type { DataNode } from '../cache/DataNode';
import type { NodeTypename, Schema } from '../schema';

export type MappedList<T, U> = U[] & {
  get(key: T): U | undefined;
};

interface CacheEntry<U> {
  value: U;
  index: Signal<number>;
}

export function map<S extends Schema, T extends NodeTypename<S>, U>(
  array: () => DataList<S, T> | null | undefined,
  callback: (
    value: DataNode<S, T>,
    index: () => number,
    array: DataNode<S, T>[],
  ) => U,
): () => MappedList<DataNode<S, T>, U>;
export function map<T, U>(
  array: () => T[] | null | undefined,
  callback: (value: T, index: () => number, array: T[]) => U,
): () => MappedList<T, U>;
export function map<T, U>(
  array: () => T[] | null | undefined,
  callback: (value: T, index: () => number, array: T[]) => U,
): () => MappedList<T, U> {
  const objectCache = new WeakMap<object, CacheEntry<U>>();
  const primitiveCache = new Map<T, CacheEntry<U>>();

  const getCache = (key: T): CacheEntry<U> | undefined => {
    if (key && typeof key === 'object') return objectCache.get(key as object);
    return primitiveCache.get(key);
  };

  const setCache = (key: T, value: CacheEntry<U>) => {
    if (key && typeof key === 'object') {
      objectCache.set(key as object, value);
    } else {
      primitiveCache.set(key, value);
    }
  };

  const result = computed<MappedList<T, U>>(() => {
    const seen = new Set<T>();
    const result = resolveBinding(array() || []).map((value, i, array) => {
      if (seen.has(value)) {
        console.warn('Duplicate key in map', value);
        return null;
      }

      seen.add(value);

      const fromCache = getCache(value);
      if (fromCache) {
        fromCache.index.value = i;
        return fromCache.value;
      }

      const index = signal(i);
      const result = callback(value, () => index.value, array);
      setCache(value, { value: result, index });

      return result;
    }) as MappedList<T, U>;
    result.get = (key: T) => getCache(key)?.value;
    return result;
  });

  return () => result.value;
}

interface GroupedCache<T> {
  items: T[];
  signal?: Signal<T[]>;
}

export function grouped<
  S extends Schema,
  T extends NodeTypename<S>,
  U = any,
  G = any,
>(
  array: () => DataList<S, T> | null | undefined,
  group: (value: DataNode<S, T>) => G,
  callback: (
    items: () => DataNode<S, T>[],
    group: G,
    index: () => number,
    array: G[],
  ) => U,
): () => MappedList<G, U>;
export function grouped<T extends {} = {}, U = any, G = any>(
  array: () => T[] | null | undefined,
  group: (value: T) => G,
  callback: (items: () => T[], group: G, index: () => number, array: G[]) => U,
): () => MappedList<G, U>;
export function grouped<T extends {} = {}, U = any, G = any>(
  array: () => T[] | null | undefined,
  group: (value: T) => G,
  callback: (items: () => T[], group: G, index: () => number, array: G[]) => U,
): () => MappedList<G, U> {
  const groupMap = new Map<G, GroupedCache<T>>();

  const groups = computed(() => {
    const seen = new Set<G>();
    const groupOrder: G[] = [];

    // Group the items
    for (const item of resolveBinding(array() || [])) {
      const key = group(item);
      const keySeen = seen.has(key);

      let mapped = groupMap.get(key);
      if (mapped) {
        if (!keySeen) mapped.items = [item];
        else mapped.items.push(item);
      } else {
        mapped = { items: [item] };
        groupMap.set(key, mapped);
      }

      if (!keySeen) {
        seen.add(key);
        groupOrder.push(key);
      }
    }

    for (const key of groupOrder) {
      const item = groupMap.get(key)!;
      if (!item.signal) item.signal = signal(item.items);
      else item.signal.value = item.items;
    }

    return groupOrder;
  });

  return map(
    () => groups.value,
    (group, index, array) => {
      const items = groupMap.get(group)!;
      return callback(() => items.signal!.value, group, index, array);
    },
  );
}
