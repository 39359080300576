import type { Icon } from '@donkeyjs/phosphor-icons';
import type {
  DataNode,
  NodeFieldsFromSchema,
  NodeTypename,
  Schema,
  SchemaMeta,
} from '@donkeyjs/proxy';
import type { OutlineEntryInput, UseOutlineOptions } from '..';
import { session } from '../session';

export interface ClientSchemaMetaSEO {
  readonly title?: string;
  readonly description?: string;
  readonly image?: DataNode<DataSchema, 'File'>;
}

export type ClientSchemaMeta<S extends Schema> = {
  [Typename in NodeTypename<S>]?: SchemaMeta<S>[Typename] & {
    icon?: (node?: DataNode<S, Typename>) => Icon;
    addIcon?: Icon;
    asString?: (node: DataNode<S, Typename>, schema: S) => string;
    seo?: (node: DataNode<S, Typename>) => ClientSchemaMetaSEO;
    outline?: (
      node: DataNode<S, Typename>,
      options: UseOutlineOptions<S, Typename>,
    ) => OutlineEntryInput<S, Typename>;
    // propertiesPanel?: (() => Promise<Component<ViewProps<S, Typename>>>) | null;
    grouping?: SchemaGrouping<S, Typename>;
  };
};

export type SchemaGrouping<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  [GroupName: string]: {
    label?: JSX.Children;
    fields: (keyof NodeFieldsFromSchema<S, Typename>)[];
    expanded?: boolean;
  };
};

export const createClientSchemaMeta = <S extends Schema>(
  schemaMeta: SchemaMeta<S>,
  clientSchemaMeta?: ClientSchemaMeta<S>,
) => {
  return Object.keys({ ...schemaMeta, ...clientSchemaMeta }).reduce<
    ClientSchemaMeta<S>
  >((meta, key) => {
    const typename = key as NodeTypename<S>;
    return Object.assign(meta, {
      [typename]: {
        ...schemaMeta[typename],
        ...clientSchemaMeta?.[typename],
      },
    });
  }, {});
};

export const extendClientSchemaMeta = (
  extension: ClientSchemaMeta<DataSchema>,
) => {
  const meta = (session.app.clientSchemaMeta ??= {});
  for (const k in extension) {
    const key = k as keyof ClientSchemaMeta<DataSchema>;
    Object.assign((meta[key] ??= {}), extension[key]);
  }
};

export const mergeClientSchemaMeta = <S extends Schema>(
  meta: ClientSchemaMeta<S> | undefined,
  extension: ClientSchemaMeta<S> | undefined,
) => {
  if (!meta) return extension || {};
  if (!extension) return meta || {};

  const merged: ClientSchemaMeta<S> = { ...meta };
  for (const [key, value] of Object.entries(extension) as [
    keyof ClientSchemaMeta<S>,
    Record<string, any>,
  ][]) {
    if (merged[key]) {
      merged[key] = {
        ...merged[key],
        ...value,
      };
    } else {
      merged[key] = value;
    }
  }
  return merged;
};
