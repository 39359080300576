import { jsxx, type Component } from '@donkeyjs/jsx-runtime';
import {
  isDataList,
  meta,
  store,
  type DataNode,
  type FieldsFromNodeSchema,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import type { FieldPropsForFieldType, FieldRenderProps } from '..';
import { admin } from '../../admin';
import { getUserContext } from '../../authentication';
import { useUserZone } from '../../i18n/UserZone';
import { getI18n } from '../../i18n/getI18n';
import { isNodeType } from '../../session';
import { getLayoutContext } from '../layoutContext';

type FieldName<S extends Schema, Typename extends NodeTypename<S>> =
  | Exclude<keyof FieldsFromNodeSchema<S, Typename>, '__typename' | 'id'>
  | '__errors';

type FieldType<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = FieldsFromNodeSchema<S, Typename>[FieldName<S, Typename>]['type'];

export interface ValueProps<
  S extends Schema,
  Typename extends NodeTypename<S>,
> {
  node: DataNode<S, Typename>;
  fieldName: FieldName<S, Typename>;
  type?: FieldType<S, Typename>;
  props: FieldsFromNodeSchema<S, Typename>[FieldName<S, Typename>] extends {
    array: infer Array extends boolean;
  }
    ? FieldPropsForFieldType<FieldType<S, Typename>, Array>
    : never;
}

export function Value<S extends Schema, Typename extends NodeTypename<S>>(
  props: ValueProps<S, Typename>,
) {
  const layoutContext = getLayoutContext();
  const layout = props.props?.layout
    ? layoutContext.all[props.props.layout]
    : layoutContext;

  const p = useFieldRenderProps(props);
  const user = getUserContext();

  const createRenderer = () => {
    const nodeType = () =>
      layout.fields[p.field.schema?.array ? 'nodeList' : 'node'] as unknown as
        | Component<FieldRenderProps<string, S, Typename>>
        | undefined;

    const renderType =
      props.type ||
      (!p.field.schema || p.field.schema.enum ? 'string' : p.field.schema.type);

    if ((props.props as any)?.mode && isNodeType(renderType)) return nodeType();

    const result = (layout.fields as any)[
      p.field.schema?.array ? `${renderType}List` : renderType
    ] as Component<FieldRenderProps<string, S, Typename>> | undefined;
    if (result) return result;

    if (isNodeType(renderType)) return nodeType();

    return undefined;
  };

  // let wrap: 'flow' | undefined;

  // const handleChange = (ev: CustomEvent<any>) => {
  //   try {
  //     props.field.setValue(ev.detail);
  //     state.invalidValue = undefined;
  //   } catch (err) {
  //     state.invalidValue = ev.detail;
  //     state.errors = [err as ValidationError];
  //   }
  // };

  return () => {
    const render = createRenderer();
    if (!render) {
      if (user.isSysAdmin)
        console.warn(`No field renderer exists for type '${
          p.field.schema?.type
        }${p.field.schema?.array ? 'List' : ''}' in
                  layout '${p.layout || layoutContext.layout}' (rendering '${
                    p.field.parent?.typename
                  }.${p.field.parent?.key}')`);
      return null;
    }
    return jsxx(render, p);
  };
}

export function useFieldRenderProps<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(value: ValueProps<S, Typename>) {
  const user = getUserContext();
  const i18n = getI18n();
  const interfaceI18n = getI18n(useUserZone());
  const outline = admin.useOutline?.(
    value.node as unknown as DataNode<DataSchema>,
  );

  const p = store.clone(
    store({
      get field() {
        const result = meta(meta(value.node).getCulture(i18n.culture)).getField(
          value.fieldName as any,
        );
        if (!result?.schema)
          throw new Error(`Field '${value.fieldName as string}' not found`);
        return result;
      },

      get label() {
        return () =>
          p.field.parent &&
          interfaceI18n.getFieldName(
            p.field.parent.typename as any,
            p.field.parent.key as any,
          );
      },

      get helper() {
        return p.field.touched && p.field.errors.length
          ? () =>
              interfaceI18n.getError(
                p.field.errors[0].code === 'custom'
                  ? p.field.errors[0].customCode
                  : p.field.errors[0].code,
              ) || p.field.errors[0].message
          : undefined;
      },

      get readonly(): boolean {
        return !state.canUpdate || state.needsFetch;
      },

      get parentOutline() {
        return outline;
      },
    }),
    value.props as any,
  );

  const state = store({
    invalidValue: undefined as unknown,

    get needsFetch() {
      let trigger = p.field.value;
      if (isDataList(trigger)) trigger = meta(trigger).isLoading;
      return (trigger || !trigger) && p.field.status !== 'ready';
    },

    get canUpdate(): boolean {
      return !p.field.parent
        ? true
        : user.can(
            p.field.parent.isNew ? 'insert' : 'update',
            p.field.parent.typename as any,
            p.field.parent.key,
          );
    },

    get input() {
      return this.invalidValue ?? !this.needsFetch ? p.field.value : undefined;
    },
  });

  return p;
}
