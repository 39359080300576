import { getGlobal } from '@donkeyjs/jsx-runtime';
import {
  createDataNode,
  meta,
  nodeSchemaFromInput,
  store,
  type DataNode,
  type Node,
  type NodeSchemaInput,
  type SchemaFromInput,
  type Store,
} from '@donkeyjs/proxy';

export const useForm = <N extends NodeSchemaInput<never, never>>(
  key: string | symbol,
  nodeSchema: N,
  defaultValues?: Partial<
    Omit<
      Node<DataNode<SchemaFromInput<{ nodes: { Form: N } }>, 'Form'>>,
      'createdAt' | 'updatedAt' | 'id' | 'draft'
    >
  >,
): Store<{
  values: Node<DataNode<SchemaFromInput<{ nodes: { Form: N } }>, 'Form'>>;
  isValid(): boolean;
  reset(): void;
}> => {
  return getGlobal(key, () => {
    const create = () =>
      createDataNode<DataNode<SchemaFromInput<{ nodes: { Form: N } }>, 'Form'>>(
        {
          values: { __typename: 'Form', ...defaultValues } as any,
          nodeSchema: nodeSchemaFromInput('Form', nodeSchema),
        },
      );

    const result = store({
      values: create(),
      reset() {
        result.values = create();
      },
      isValid() {
        return !meta(result.values).validate().length;
      },
    });

    return result;
  });
};
