import { createTheme } from '../styles/createTheme';
import styles from './theme.module.css';

const createDonkeyTheme = (theme: 'LIGHT' | 'DARK') =>
  createTheme({
    colors: {
      background: theme === 'DARK' ? '#383838' : '#f9f9f9',
      color: theme === 'DARK' ? '#ffffff' : '#222222',
      error: '#b00020',
      accent: theme === 'DARK' ? '#80deea' : '#007985',
      secondary: theme === 'DARK' ? '#3f6b71' : '#4c8187',
      'text-on-error': theme === 'DARK' ? '#ffffff' : '#ffffff',
      'text-on-accent': theme === 'DARK' ? '#004140' : '#ffffff',
      'text-on-secondary': '#ffffff',
    },

    styles,

    theme: theme,

    email: null,

    font: '400 10pt/1.4 "Noto Sans", sans-serif',

    isAdmin: false,

    spacing: '16px',

    webFonts: {
      google: { families: ['Noto+Sans:ital,wght@0,400;0,700;1,400;1,700'] },
    },
  });

export const donkeyThemes = {
  DARK: createDonkeyTheme('DARK'),
  LIGHT: createDonkeyTheme('LIGHT'),
};
