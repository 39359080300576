import { isValid as isValidDate } from 'date-fns';
import type { NodeFactory } from '../cache/nodeFactory';
import { createMarkupString } from '../markup/Markup';
import {
  isNode,
  scalars,
  type FieldSchema,
  type Scalar,
  type Schema,
} from '../schema';

// export type ValidationError =
//   | Error &
//       (
export class ValueRequiredError {
  public name = 'ValidationError';
  public code = 'required';
  public message = 'Value is required';
  constructor(public field: string) {}
}

export class ExpectedArrayError {
  public name = 'ValidationError';
  public code = 'expected-array';
  public message = 'Expected an array';
  constructor(public field: string) {}
}

export class UnknownValidationError {
  public name = 'ValidationError';
  public code = 'unknown';
  public message = 'Unexpected validation error';
  constructor(public field: string) {}
}

export class InvalidOperationError {
  public name = 'ValidationError';
  public code = 'invalid-operation';
  constructor(public message: string) {}
}

export class CustomError {
  public name = 'ValidationError';
  public code = 'custom';
  constructor(
    public customCode: string,
    public message?: string,
  ) {}
}

export class ExpectedTypeError {
  public name = 'ValidationError';
  public code = 'expected-type';
  public message: string;

  constructor(
    public field: string,
    public received: string,
    public expected: string,
  ) {
    this.message = `Expected value of type ${expected}, received ${received}`;
  }
}

export class ExpectedOneOfError {
  public name = 'ValidationError';
  public code = 'expected-one-of';
  public message: string;

  constructor(
    public field: string,
    public allowedValues: readonly unknown[],
  ) {
    this.message = `Expected one of ${allowedValues.join(', ')}`;
  }
}

export class OutOfRangeError {
  public name = 'ValidationError';
  public code = 'out-of-range';

  constructor(
    public field: string,
    public message: string,
  ) {}
}

export type ValidationError =
  | ValueRequiredError
  | ExpectedArrayError
  | UnknownValidationError
  | ExpectedTypeError
  | ExpectedOneOfError
  | InvalidOperationError
  | CustomError;

export function validateValue(
  field: string,
  value: unknown,
  schema: Schema,
  fieldSchema: FieldSchema,
  factory?: NodeFactory,
): [corrected: any, error?: ValidationError] {
  if (fieldSchema.skipValidation) return [value];

  if (
    !fieldSchema.optional &&
    !fieldSchema.array &&
    (value == null || value === '')
  ) {
    let empty = scalars[fieldSchema.type as keyof typeof scalars]?.emptyValue();
    if (empty === '' && (fieldSchema as FieldSchema<'string'>).markup) {
      empty = createMarkupString('', []);
    }
    return empty != null
      ? [empty, new ValueRequiredError(field)]
      : [undefined, new ValueRequiredError(field)];
  }

  // From here on, safe to say null/undefined is valid
  if (value == null) return [value];

  const valueValidation = validateValueType(
    field,
    value,
    schema,
    fieldSchema,
    factory,
  );
  if (valueValidation[1]) return valueValidation;

  if (fieldSchema.validate && valueValidation[0] != null) {
    const code = fieldSchema.validate(valueValidation[0]);
    if (code) return [valueValidation[0], new CustomError(code, code)];
  }

  return valueValidation;
}

const validateValueType = (
  field: string,
  value: unknown,
  schema: Schema,
  fieldSchema: FieldSchema,
  factory?: NodeFactory,
): [corrected: any, error?: ValidationError] => {
  if (fieldSchema.array) return [value];

  if (
    fieldSchema.type === 'string' &&
    (fieldSchema as FieldSchema<'string'>).markup &&
    value instanceof String
  )
    return [value];

  if (Object.keys(validScalar).includes(fieldSchema.type as any))
    return validScalar[fieldSchema.type as keyof typeof validScalar](
      field,
      value,
    );

  if (Object.keys(schema.nodes).includes(fieldSchema.type))
    return validNode(field, value, fieldSchema.type, factory);

  if (Object.keys(schema.enums).includes(fieldSchema.type))
    return typeof value === 'string' &&
      schema.enums[fieldSchema.type].values.includes(value)
      ? [value]
      : [schema.enums[fieldSchema.type].values[0]];

  return [value];
};

const validateJson = (
  field: string,
  value: unknown,
): [corrected: any, error?: ValidationError] => {
  try {
    JSON.stringify(value);
    return [value];
  } catch (e) {
    return [undefined, new OutOfRangeError(field, (e as Error).message)];
  }
};

const validScalar: Record<
  Scalar,
  (field: string, value: unknown) => [corrected: any, error?: ValidationError]
> = {
  boolean: (_, value) => [!!value],
  date: (field, value) =>
    isValidDate(value)
      ? [value]
      : [undefined, new ExpectedTypeError(field, typeof value, 'date')],
  int: (field, value) =>
    Number.isInteger(value)
      ? [value]
      : typeof value === 'number'
        ? [Math.round(value)]
        : typeof value === 'string'
          ? [Number.parseInt(value)]
          : [undefined, new ExpectedTypeError(field, typeof value, 'int')],
  json: (field, value) => validateJson(field, value),
  float: (field, value) =>
    typeof value === 'number'
      ? [value]
      : typeof value === 'string'
        ? [Number.parseFloat(value)]
        : [undefined, new ExpectedTypeError(field, typeof value, 'float')],
  string: (field, value) =>
    typeof value === 'string'
      ? [value]
      : typeof value === 'number'
        ? [value.toString()]
        : [undefined, new ExpectedTypeError(field, typeof value, 'string')],
};

const validNode = (
  field: string,
  value: unknown,
  typename: string,
  factory?: NodeFactory,
): [corrected: any, error?: ValidationError] => {
  if (isNode(value) && value.__typename === typename) return [value];
  if (factory) {
    if (typeof value === 'string') {
      const node = factory.getNodeIfExists(typename, value);
      if (node) return [node];
    }
  }
  return [undefined, new ExpectedTypeError(field, typeof value, typename)];
};

export const EMAIL_REGEX =
  /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;

export const URL_REGEX_FORGIVING =
  /^(?:https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export const validateEmailAddress = (value: unknown) =>
  typeof value === 'string' && EMAIL_REGEX.test(value) ? null : 'invalid-email';

// export const PHONE_NUMBER_REGEX =
//   /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-. \\/]?)?((?:\(?\d{1,}\)?[-. \\/]?){0,})(?:[-. \\/]?(?:#|ext\.?|extension|x)[-. \\/]?(\d+))?$/;

// export const testPhoneNumber: Donkey.FieldTest<string> = (input, { I18n }) =>
//   PHONE_NUMBER_REGEX.test(input)
//     ? true
//     : [I18n.System.Error.InvalidPhoneNumber()];
