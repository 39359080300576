import type { MarkupProps } from './Markup';
import { contentEditable } from './contentEditable';

export const attachMarkup =
  (markupProps: MarkupProps<any>) => (element: HTMLElement) => {
    markupProps.element = element;
    return contentEditable({
      onkeydown(ev) {
        markupProps.onkeydown?.(ev);
      },
      onfocus(element) {
        markupProps.hasFocus = true;
        markupProps.onfocus?.(element);
      },
      onblur(element) {
        markupProps.hasFocus = false;
        markupProps.onblur?.(element);
      },
    })(element);
  };
