import { store } from '@donkeyjs/proxy';
import { session } from '../../../session';
import { parseMonthFilter } from '../parseMonthFilter';
import type { DateFilterProps } from './DateFilter';

export type DateFilterState = ReturnType<typeof useDateFilter>;

export const useDateFilter = (props: DateFilterProps) => {
  const state = store({
    _selectedYear: undefined as number | undefined,

    get selectedYear(): number {
      if (state._selectedYear != null) return state._selectedYear;
      if (typeof state.value === 'object') return state.value.year;
      // Find the first future year, or the last available year
      const years = state.values?.years;
      const compareYear = new Date().getFullYear();
      if (!years) return compareYear;
      const futureYear = years.find((year) => Number(year) >= compareYear);
      if (futureYear) return Number(futureYear);
      return years.filter((year) => year < compareYear).pop()!;
    },

    get nextYear(): number | undefined {
      const years = state.values?.years;
      return years?.find((year) => year > state.selectedYear);
    },

    get previousYear(): number | undefined {
      const years = state.values?.years;
      return years?.filter((year) => year < state.selectedYear).pop();
    },

    get value(): 'future' | 'past' | { year: number; month: number } {
      if (props.value === 'past') return 'past';
      return parseMonthFilter(props.value) || 'future';
    },

    set value(value: 'future' | 'past' | { year: number; month: number }) {
      if (value === 'future') {
        props.value = undefined;
      } else if (value === 'past') {
        props.value = 'past';
      } else {
        props.value = `${value.year}-${value.month}`;
      }
    },

    get values(): { years: number[]; months: number[][] } | undefined {
      if (!props.values?.length) return undefined;
      const values = props.values.reduce<{
        years: number[];
        months: number[][];
      }>(
        (acc, value) => {
          const date = new Date(Number(value));
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          if (!acc.months[year]) {
            acc.years.push(year);
            acc.months[year] = [];
          }
          acc.months[year].push(month);
          return acc;
        },
        { years: [], months: [] },
      );
      return values;
    },

    hasMonth(year: number, month: number) {
      return state.values?.months[year]?.includes(month + 1);
    },

    getPath(value: string | undefined) {
      return session.router.getPath(
        { route: 'current' },
        {
          query: {
            ...session.router.query,
            'node-id': undefined,
            'node-typename': undefined,
            [props.key]: value ? [value] : undefined,
          },
        },
      );
    },
  });
  return state;
};
