import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import {
  store,
  type Culture,
  type DataList,
  type DataNode,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { Flag } from '../../../i18n/Flag';
import { getI18n, setI18n } from '../../../i18n/getI18n';
import { session } from '../../../session';
import { getTheme } from '../../../styles';
import { Label } from '../../components/Label';
import { setLayout } from '../../layoutContext';
import { Block } from './Block';
import styles from './HtmlBlock.module.css';
import { LayoutBlocks } from './LayoutBlocks';
import { createSelectionContext } from './helpers/getSelectionContext';

export function HtmlBlock(props: FieldRenderProps<'Block'>) {
  return jsxx(
    Block,
    store.clone(props, {
      get value() {
        return props.field.value!;
      },
      set value(value) {
        props.field.value = value;
      },
    }),
  );
}

export function HtmlBlocks(props: FieldRenderProps<'BlockList'>) {
  const theme = getTheme();
  createSelectionContext();

  setLayout('inline');

  const render = (value: DataList<DataSchema, 'Block'>) =>
    jsxx(
      LayoutBlocks,
      store.clone(props, {
        get value() {
          return value;
        },
        get active() {
          return props.parentOutline?.active;
        },
        get parentNode() {
          return props.field.parent?.node as DataNode<DataSchema>;
        },
        class: theme.class.input,
      }),
    );

  return (
    <Label
      class="input align-start"
      element="div"
      onmount={props.onmount}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
    >
      {() =>
        session.app.routerFollowsCultures && props.field.cultures ? (
          <div class={[styles.cultures, 'cultures']}>
            {Object.entries(props.field.cultures).map(([culture, field]) => (
              <CultureBlocks field={field} render={render} culture={culture} />
            ))}
          </div>
        ) : (
          render(props.field.value!)
        )
      }
    </Label>
  );
}

interface CultureBlocksProps {
  field: FieldRenderProps<'BlockList'>['field'];
  render: (value: DataList<DataSchema, 'Block'>) => JSX.Children;
  culture: string;
}

export function CultureBlocks(props: CultureBlocksProps) {
  const i18n = getI18n(true);
  setI18n({
    get culture() {
      return props.culture as Culture;
    },
    get userCulture() {
      return i18n.culture;
    },
  });
  return (
    <div class={[styles.culture, 'culture']}>
      <span class="flag">
        <Flag culture={bind(() => props.culture)} />
      </span>
      {props.render(props.field.value!)}
    </div>
  );
}
