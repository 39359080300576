import type { AppClientModule } from '@donkeyjs/client';
import { permissions } from '../permissions';
import { clientSchemaMeta, schemaMeta } from '../schemaMeta';

export * from './backoffice';
export * from './calendar/Calendar';
export * from './useEvent';

export const clientModuleEvents: AppClientModule = {
  permissions,
  clientSchemaMeta,
  schemaMeta,
};
