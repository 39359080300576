import { getError } from '@donkeyjs/jsx-runtime';
import { PhSmileyXEyes } from '@donkeyjs/phosphor-icons';
import { DataError, store } from '@donkeyjs/proxy';
import { getUserContext } from '../authentication';
import { getI18n } from '../i18n/getI18n';
import styles from './ErrorBoundary.module.css';

export function ErrorBoundary(props: {
  readonly children?: JSX.Children;
}): JSX.Children {
  const state = getError();

  return () =>
    state.error ? <RenderError error={state.error} /> : props.children;
}

export function RenderError(props: {
  readonly error: Error;
}): JSX.Children {
  const user = getUserContext();
  const i18n = getI18n();

  const state = store({
    showCode: false,
  });

  return (
    <div class={styles.error}>
      <div
        class={styles.head}
        onclick={(ev: MouseEvent) => {
          if (ev.ctrlKey && ev.altKey) {
            state.showCode = !state.showCode;
          }
        }}
      >
        <div class={styles.icon}>
          <PhSmileyXEyes weight="duotone" />
        </div>
        <div class={styles.message}>
          {() => {
            if (!user.isSysAdmin && !state.showCode)
              return i18n.getError('boundary') || 'An error occurred.';

            if (props.error instanceof DataError)
              return `${props.error.message}: ${props.error.code}`;
            return props.error.message;
          }}
        </div>
      </div>
      {() => state.showCode && RenderErrorDiagnostics(props)}
    </div>
  );
}

function RenderErrorDiagnostics(props: {
  error: Error;
}) {
  return <div class={styles.diagnostics}>{() => props.error.stack}</div>;
}
