import { jsxx } from '@donkeyjs/jsx-runtime';
import {
  bind,
  meta,
  store,
  type Culture,
  type DataList,
  type DataNode,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { session } from '../../../session';
import { Cultures } from '../../components/Cultures';
import { Block } from './Block';
import { LayoutBlocks } from './LayoutBlocks';
import { createSelectionContext } from './helpers/getSelectionContext';

export function InlineBlock(props: FieldRenderProps<'Block'>) {
  return jsxx(
    Block,
    store.clone(props, {
      get value() {
        return props.field.value!;
      },
      set value(value) {
        props.field.value = value;
      },
    }),
  );
}

export function InlineBlocks(props: FieldRenderProps<'BlockList'>) {
  createSelectionContext();

  const state = store({
    get culture() {
      return session.router.query.culture?.[0] as Culture | undefined;
    },
    get value(): DataList<DataSchema, 'Block'> {
      return state.culture
        ? meta(props.field.value!).getCulture(state.culture)
        : props.field.value!;
    },
  });

  return (
    <>
      <Cultures
        blocks={bind(() => state.value)}
        segment={bind(() => props.segment)}
      />
      {jsxx(
        LayoutBlocks,
        store.clone(props, {
          get value() {
            return state.value;
          },
          get active() {
            return props.parentOutline?.active;
          },
          get parentNode() {
            return props.field.parent?.node as DataNode<DataSchema>;
          },
        }),
      )}
    </>
  );
}
