import type {
  AppSchema,
  NodeTypename,
  QuerySortOrder,
  ResolverManyArgsFromSchema,
  ResolverSchema,
  Schema,
} from '../schema';

export const listMatchFromResolverArgs = <
  S extends Schema,
  A extends AppSchema,
  Typename extends NodeTypename<S>,
>(
  schema: A,
  typename: Typename,
  resolverName: string,
  options: ResolverManyArgsFromSchema<S, Typename>,
  editable: boolean | undefined,
) => {
  const resolver = schema.nodes[typename].resolvers?.[resolverName];
  if ((options as any).ids) {
    return {
      where: { id: { in: (options as any).ids } },
      sort: undefined,
    };
  }
  const result = {
    ...options,
    sort: resolver
      ? sortFromResolver<S, Typename>(options, resolver, editable)
      : (['createdAt:desc'] as unknown as QuerySortOrder<
          S,
          S['nodes'][Typename]
        >),
    where: resolver && options.where ? {} : options.where || {},
  };
  if (resolver && options.where) {
    const isMany = resolver.return === 'many';
    for (const arg in options.where) {
      const fromResolver = resolver.where?.[arg];
      if (!fromResolver)
        throw new Error(
          `Invalid argument "${arg}" for resolver "${resolverName}" on "${typename}"`,
        );
      (result as any).where[
        isMany ? fromResolver[0] : (fromResolver as string)
      ] = isMany ? options.where[arg] : { eq: options.where[arg] };
    }
  }
  return result;
};

const sortFromResolver = <S extends Schema, Typename extends NodeTypename<S>>(
  options: any,
  resolver: ResolverSchema,
  editable: boolean | undefined,
): QuerySortOrder<S, S['nodes'][Typename]> | undefined => {
  const result = options.search
    ? (['searchRank:desc'] as any)
    : options.sort && resolver.return === 'many'
      ? resolver.sort?.find((order) => order.name === options.sort)?.order
      : resolver.return === 'many'
        ? resolver.sort?.[0]?.order
        : undefined;
  return editable ? ['draft:desc', ...(result || [])] : result;
};
