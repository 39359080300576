import { bind } from '@donkeyjs/jsx-runtime';
import { session } from '../../../session';
import styles from './GoogleMap.module.css';

interface GoogleMapProps {
  readonly googlePlacesId: string | null | undefined;
  readonly width?: string;
  readonly height?: string;
  readonly frameTitle?: string;
  readonly className?: string;
}

export function GoogleMap(props: GoogleMapProps) {
  return () => {
    const googleMapsEmbedKey = session.app.data[0]?.googleMapsEmbedKey;

    return googleMapsEmbedKey && props.googlePlacesId ? (
      <iframe
        class={bind(() => [styles.frame, props.className])}
        title={bind(() => props.frameTitle || 'Map')}
        width={bind(() => props.width || '100%')}
        height={bind(() => props.height)}
        frameborder="0"
        src={bind(
          () =>
            `https://www.google.com/maps/embed/v1/place?key=${googleMapsEmbedKey}&q=place_id:${props.googlePlacesId}`,
        )}
        allowFullScreen
      />
    ) : null;
  };
}
