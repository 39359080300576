import type { BaseSchemaInput } from '@donkeyjs/core';
import {
  createSchemaExtension,
  type SchemaFromInput,
  type SchemaInputWithExtensions,
} from '@donkeyjs/proxy';
import { timeZones } from './timeZones';

export const eventsSchemaExtension = createSchemaExtension<BaseSchemaInput>()({
  nodes: {
    Event: {
      fullDay: 'boolean?',
      starts: [
        'date',
        {
          timeZoneField: 'timeZone',
          rangeToField: 'ends',
          fullDayField: 'fullDay',
        },
      ],
      ends: [
        'date',
        {
          timeZoneField: 'timeZone',
          rangeFromField: 'starts',
          fullDayField: 'fullDay',
        },
      ],
      timeZone: ['string', { options: timeZones, hideFromForms: true }],
      venue: ['Place?', { reverse: 'events' }],
      name: ['string?', { i18n: true }],
      sequence: ['int?', { hideFromForms: true }],
      sequenceHash: ['string?', { hideFromForms: true }],
    },
  },
} as const);

export type EventsSchemaInput = SchemaInputWithExtensions<
  BaseSchemaInput,
  typeof eventsSchemaExtension
>;

export type EventsSchema = SchemaFromInput<EventsSchemaInput>;
