interface MaskOptions {
  mask: string;
  onchange: (value: string) => void;
  onkeydown?: (e: KeyboardEvent) => void;
}

export const useMask = (options: MaskOptions) => {
  const mask = parseMask(options.mask);
  const maskFormat = formatMask(mask);

  let cursorPosition: number | null = null;

  const onchange: JSX.GenericEventHandler<HTMLInputElement> = (ev) => {
    ev.preventDefault();
    const element = ev.target as HTMLInputElement;
    const [newValue, cursor] = mask
      ? fixMask(element.value, mask, element.selectionStart ?? 0)
      : [element.value, null];
    if (cursor != null) cursorPosition = cursor;
    options.onchange(newValue);
  };

  const onkeydown = (ev: KeyboardEvent) => {
    const element = ev.target as HTMLInputElement;
    if (
      element.selectionStart === element.selectionEnd &&
      !ev.ctrlKey &&
      !ev.altKey &&
      !ev.metaKey
    ) {
      if (ev.code === 'Backspace' || ev.code === 'ArrowLeft') {
        let cursor = element.selectionStart ?? 0;
        while (cursor > 0 && mask[cursor - 1]?.type === 'fixed') cursor -= 1;
        element.setSelectionRange(cursor, cursor);
      } else if (ev.code === 'ArrowRight' && !ev.shiftKey) {
        let cursor = element.selectionEnd ?? 0;
        while (mask[cursor + 1]?.type === 'fixed') cursor += 1;
        element.setSelectionRange(cursor, cursor);
      } else if (ev.code === 'Delete') {
        let cursor = element.selectionEnd ?? 0;
        while (mask[cursor]?.type === 'fixed') cursor += 1;
        element.setSelectionRange(cursor, cursor);
      } else {
        const cursor = element.selectionStart ?? 0;
        const segment = mask[cursor];
        if (segment?.type === 'input' && ev.key.length === 1) {
          if (
            !segment.chars.includes(ev.key) &&
            !segment.chars.includes(ev.key.toLowerCase()) &&
            !segment.chars.includes(ev.key.toUpperCase())
          )
            ev.preventDefault();
        }
      }
    }
    options.onkeydown?.(ev);
  };

  const onvalueupdate = (_value: string, element: HTMLInputElement) => {
    if (cursorPosition != null) {
      element.setSelectionRange(cursorPosition, cursorPosition);
      cursorPosition = null;
    }
  };

  return {
    onchange,
    onkeydown,
    onvalueupdate,
    placeholder: maskFormat,
  };
};

type MaskInput = {
  type: 'input';
  chars: string;
};

type MaskFixed = {
  type: 'fixed';
  value: string;
};

type MaskSegment = MaskInput | MaskFixed;

type Mask = MaskSegment[];

const parseRegex = /\[.*?\]|[^[]*/gm;
const parseMask = (mask: string): Mask => {
  const result: Mask = [];

  let m: RegExpExecArray | null;
  while ((m = parseRegex.exec(mask))) {
    if (m.index === parseRegex.lastIndex) parseRegex.lastIndex++;
    const segment = m[0];
    const [, chars] = /^\[(.*?)\]$/.exec(segment) || [];
    if (chars) result.push({ type: 'input', chars });
    else if (segment)
      segment.split('').map((value) => result.push({ type: 'fixed', value }));
  }

  return result;
};

const formatMask = (mask: Mask) =>
  mask
    .map((segment) => (segment.type === 'input' ? '_' : segment.value))
    .join('');

const fixMask = (
  value: string,
  mask: Mask,
  cursor: number,
): [string, number] => {
  if (!value) return ['', 0];

  let index = 0;
  let charIndex = 0;
  let segment: MaskSegment | undefined;
  let result = '';
  let hasInput = false;
  while ((segment = mask[index])) {
    index += 1;
    if (segment.type === 'fixed') {
      result += segment.value;
      if (value[charIndex] === segment.value) charIndex += 1;
    } else {
      let char: string | undefined;
      while ((char = value[charIndex])) {
        charIndex += 1;
        if (segment.chars.includes(char)) {
          result += char;
          hasInput = true;
          break;
        }
        if (char === '_') {
          result += '_';
          break;
        }
      }
      if (char === undefined) result += '_';
    }
  }

  if (!hasInput) return ['', 0];

  let newCursor = cursor;
  while (mask[newCursor]?.type === 'fixed') newCursor += 1;

  return [result, newCursor];
};
