export function Animation() {
  return (
    <defs>
      <linearGradient id="loading">
        <stop offset="0%" stop-color="currentColor" stop-opacity="0.05">
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="50%" stop-color="currentColor" stop-opacity="0.2">
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="100%" stop-color="currentColor" stop-opacity="0.05">
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  );
}
