import { type BaseSchemaInput, baseSchema } from '@donkeyjs/core';
import {
  type SchemaFromInput,
  createSchemaExtension,
  extendSchemaInput,
} from '@donkeyjs/proxy';

export const gallerySchemaExtension = createSchemaExtension<BaseSchemaInput>()({
  extensions: {
    FileRef: {
      add: {
        downloadButton: 'boolean?',
      },
    },
  },

  resolvers: {
    FileRef: {
      gallery: {
        return: 'many',
        where: { id: ['block', ['eq']] },
        sort: [{ name: 'INDEX', order: ['sortIndex:auto'] }],
      },
    },
  },
} as const);

const gallerySchema = extendSchemaInput(baseSchema, gallerySchemaExtension);

export type GallerySchema = SchemaFromInput<typeof gallerySchema>;
