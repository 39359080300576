import {
  componentContext,
  getGlobal,
  type RenderContext,
} from '@donkeyjs/jsx-runtime';
import { batch, store } from '@donkeyjs/proxy';

interface ResolvedPromise<T, Source = T> {
  loading: boolean;
  error: unknown | null;
  data: T | null;
  readonly update: (promise: Promise<Source> | null | undefined) => void;
}

const key = 'async-provider';

export interface AsyncProvider {
  running: Set<Promise<unknown>>;
  register<T>(promise: Promise<T>): Promise<T>;
}

export function useAsyncProvider(context?: RenderContext) {
  return getGlobal<AsyncProvider>(
    key,
    () => ({
      running: new Set(),
      register(promise) {
        this.running.add(promise);
        return promise;
      },
    }),
    context,
  );
}

export function getAsyncResource<T>(promise: Promise<T>) {
  const state = store({
    loading: true,
    error: null as unknown | null,
    data: null as T | null,
  });
  promise.then(
    (data) => {
      batch(() => {
        state.loading = false;
        state.data = data;
      });
    },
    (error) => {
      batch(() => {
        state.loading = false;
        state.error = error;
      });
    },
  );
  if (!componentContext.current) return state;
  const provider = useAsyncProvider();
  return provider.register(promise);
}

export const usePromise = <T, Result = T>(
  promise: Promise<T> | null | undefined,
): ResolvedPromise<Result, T> => {
  const provider = useAsyncProvider();

  let current = promise;

  const result = store<ResolvedPromise<Result, T>>({
    loading: true,
    error: null,
    data: null,
    update(newPromise) {
      if (newPromise === current) return;
      current = newPromise;
      resolve(newPromise);
    },
  });

  const resolve = (promise: Promise<T> | null | undefined) => {
    if (!promise) {
      batch(() => {
        result.loading = false;
        result.error = null;
        result.data = promise || null;
      });
      return;
    }

    provider.running.add(promise);

    (async () => {
      try {
        const data = await promise;
        batch(() => {
          provider.running.delete(promise);
          result.loading = false;
          result.data = data as any;
          result.error = null;
        });
      } catch (error) {
        batch(() => {
          provider.running.delete(promise);
          result.loading = false;
          result.error = error as any;
          result.data = null;
        });
      }
    })();
  };

  resolve(promise);

  return result;
};
