import { setExceptionHandler, traceException } from '@donkeyjs/core';
import { registerGlobalErrorHandler } from '@donkeyjs/jsx-runtime';
import type { DataNode } from '@donkeyjs/proxy';
import type { DataClientFromSchema } from '../data/createDataClient';
import { session } from '../session';

let trace: DataNode<DataSchema, 'Trace'> | undefined;
let isHandling = false;

export const startTrace = (
  client: DataClientFromSchema<DataSchema, ApplicationSchema>,
): void => {
  setExceptionHandler(async (values, events) => {
    if (isHandling) {
      console.error(values);
      return;
    }
    try {
      isHandling = true;
      const { stack, ...exceptionValues } = values;
      const exception = await client.mutation.registerException(
        exceptionValues,
        {
          col: true,
          filename: true,
          line: true,
          message: true,
        },
      );
      if (!exception.data) return;

      if (!trace)
        trace = client.createNode({
          __typename: 'Trace',
        });
      trace.events.push(...events, {
        type: 'EXCEPTION',
        at: new Date(),
        exception: exception.data,
        data: { stack },
        source: session.dom.ssr ? 'SSR' : 'CLIENT',
      });
      events.length = 0;
    } catch (e) {
      console.error(e);
    } finally {
      isHandling = false;
    }
  });
};

registerGlobalErrorHandler((error) => {
  traceException({
    col: 0,
    filename: '',
    line: 0,
    message: error.message,
    stack: error.stack,
  });
});
