import { bind, preventDefault } from '@donkeyjs/jsx-runtime';
import { getTheme } from '../../styles';
import { setLayout } from '../layoutContext';
import type { Layout } from '../layouts';
import styles from './Form.module.css';

// Note: in use as BlockProps in DataForm
export interface FormProps {
  readonly layout?: Layout | `${Layout}:flow`;
  readonly children?: JSX.Children;
  readonly onmount?: JSX.OnMount<HTMLFormElement>;
}

export function Form(props: FormProps) {
  const theme = getTheme();
  setLayout(props.layout || 'html');

  return (
    <form
      onsubmit={preventDefault}
      class={bind(() => [
        styles.form,
        props.layout?.endsWith(':flow')
          ? [styles.flow, theme.class.flow]
          : undefined,
      ])}
      onmount={props.onmount}
    >
      {props.children}
    </form>
  );
}
