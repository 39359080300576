import { uploadFiles } from '@donkeyjs/client';
import type { DataList, DataNode } from '@donkeyjs/proxy';

export const addToGallery = (
  gallery: DataList<DataSchema, 'FileRef'>,
  items: DataNode<DataSchema, 'File'>[] | FileList,
  test?: boolean,
) => {
  if (test) {
    if (items instanceof FileList) return;
    return gallery.testInsertBefore(
      null,
      ...items.map((item) => ({ file: item })),
    )[1];
  }
  if (items instanceof FileList) {
    uploadFiles({
      files: items,
      onCreated(node) {
        gallery.insertBefore(null, {
          file: node,
        });
      },
    });
  } else {
    gallery.insertBefore(null, ...items.map((item) => ({ file: item })));
  }
};
