import { updateComponent, type Component } from '@donkeyjs/jsx-runtime';
import type { NodeTypename, Schema } from '@donkeyjs/proxy';
import type { ViewProps, ViewType, Views } from '.';
import type { ListProps } from '../components/List';
import { session } from '../session';

/*@__NO_SIDE_EFFECTS__*/
export const createView = <S extends Schema, Typename extends NodeTypename<S>>(
  options: Omit<ViewType<S, Typename>, 'component'>,
  fn: Component<ViewProps<S, Typename>>,
  DataContainer?: Component<{
    listProps: ListProps<S, Typename>;
    children?: JSX.Children;
  }>,
  Container?: Component<{
    listProps: ListProps<S, Typename>;
    children?: JSX.Children;
  }>,
): ViewType<S, Typename> => {
  Object.defineProperty(fn, 'name', {
    get() {
      return options.name(session.i18n());
    },
  });

  const result = {
    ...options,
    component: fn,
  };

  if (DataContainer) {
    result.renderDataContainer = (listProps, children) => (
      <DataContainer listProps={listProps}>{children}</DataContainer>
    );
  }

  if (Container) {
    result.renderContainer = (listProps, children) => (
      <Container listProps={listProps}>{children}</Container>
    );
  }

  return result;
};

export function updateViews(views: Views, hot: any | undefined) {
  for (const type in hot) {
    for (const key in hot[type]) {
      if (!(views as any)[type]?.[key]) {
        import.meta.hot?.invalidate();
        continue;
      }
      if (typeof (views as any)[type][key].component !== 'function') {
        continue;
      }
      updateComponent(
        (views as any)[type][key].component,
        hot[type][key].component,
      );
    }
  }
}
