import { htmlLayout } from './htmlLayout';
import { inlineLayout } from './inlineLayout';

export const layouts = {
  html: htmlLayout,
  inline: inlineLayout,
};

export type Layouts = typeof layouts;
export type Layout = keyof typeof layouts;
