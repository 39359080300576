import { isJSXNode, type JSXVirtualNode } from '../dom';
import { Comment, Fragment } from '../jsx/jsx';
import { processBeforeMount } from '../mount/renderPlugin';
import { createComment } from './createComment';
import { createDomArray } from './createDomArray';
import { createDomComponent } from './createDomComponent';
import { createDomSignal } from './createDomSignal';
import { createElement } from './createElement';
import { createFragment } from './createFragment';
import { createNullNode } from './createNullNode';
import { createTextNode } from './createTextNode';

export function createJSXNode(
  input: JSX.Element,
  nextSsrCandidate?: Node | null,
): JSXVirtualNode {
  const from = processBeforeMount(input);

  if (from == null || typeof from === 'boolean') {
    return createNullNode();
  }

  if (typeof from === 'function') {
    return createDomSignal(from as any, nextSsrCandidate);
  }

  if (
    typeof from === 'string' ||
    typeof from === 'number' ||
    from instanceof String
  ) {
    return createTextNode(from, nextSsrCandidate);
  }

  if (Array.isArray(from)) {
    return createDomArray(from, nextSsrCandidate);
  }

  if (isJSXNode(from)) {
    if (from.tag === Fragment) {
      return createFragment(from.children, undefined, nextSsrCandidate);
    }

    if (from.tag === Comment) {
      return createComment(from, nextSsrCandidate);
    }

    if (typeof from.tag === 'string') {
      return createElement(from, nextSsrCandidate);
    }

    if (typeof from.tag === 'function') {
      return createDomComponent(from, nextSsrCandidate);
    }
  }

  console.warn('Unknown JSX element', from);
  return createTextNode(from?.toString(), nextSsrCandidate);
}
