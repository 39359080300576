import type { DomNode } from '../dom';

export function walkSsrTree(
  nextSsrCandidate: Node | null | undefined,
  nodes: DomNode[],
) {
  let next = nextSsrCandidate;
  if (!next) return;
  for (const node of nodes) {
    if (node === next) {
      next = next.nextSibling;
    }
  }
  return next;
}
