import { live } from '@donkeyjs/jsx-runtime';
import { store, type Mutable, type ResolverArgsSearch } from '@donkeyjs/proxy';
import debounce from 'debounce';
import { type I18nContext, getI18n } from '../i18n/getI18n';
import { session } from '../session';

interface UseSearchInputProps {
  initialValue?: string;
  delay?: number;
}

interface State {
  value: string;
  input: string;
  query: (mode?: SearchInputMode) => ResolverArgsSearch | undefined;
}

export type SearchInputMode = 'tokens';

export const useSearchInput = (props?: UseSearchInputProps) => {
  const i18n = getI18n();

  const state = store<State>({
    value: props?.initialValue ?? '',
    input: props?.initialValue ?? '',
    get query() {
      return (mode?: SearchInputMode): ResolverArgsSearch | undefined => {
        if (!this.value) return undefined;
        return getSearchResolverArgs({ text: this.value, i18n, mode });
      };
    },
  });

  const setSearchFromInput = debounce((input: string) => {
    state.value = input;
  }, props?.delay ?? 300);

  live(() => {
    setSearchFromInput(state.input);
  });

  return state;
};

export function getSearchResolverArgs(args: {
  text: string;
  i18n: I18nContext;
  mode?: SearchInputMode;
}) {
  const result = (
    session.app.schema.cultures.length > 1 ? { culture: args.i18n.culture } : {}
  ) as Mutable<ResolverArgsSearch>;
  if (args.mode === 'tokens') {
    result.text = `${args.text.trim().split(/\s+/g).join(' & ')}:*`;
    result.mode = { __literal: 'TOKENS' };
  } else result.text = args.text.trim();
  return result;
}
