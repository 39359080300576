import {
  isJSXNode,
  type DomComment,
  type JSXNode,
  type JSXVirtualNode,
} from '../dom';
import { Comment } from '../jsx/jsx';
import { componentContext } from '../mount/mount';

export function createComment(value: JSXNode, nextSsrCandidate?: Node | null) {
  const context = componentContext.current;
  if (!context) throw new Error('Cannot render a JSX element without context');

  const element = fromSsr(value.children[0] as string, nextSsrCandidate);
  (element as any).$context = context;

  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: value,
    nodes: [element],
    dispose() {},
    testUpdate(value) {
      if (isJSXNode(value) && value.tag === Comment) return true;
      return `invalid value '${value?.toString()}' passed to comment with value '${
        element.data
      }'`;
    },
    update(value) {
      const test = result.testUpdate(value);
      if (test !== true) return test;
      result.currentValue = value;
      element.data = value.children[0] as string;
      return true;
    },
  };

  return result;
}

function fromSsr(text: string, nextSsrCandidate?: Node | null) {
  if (nextSsrCandidate?.nodeType === 8) {
    (nextSsrCandidate as Comment).data = text;
    return nextSsrCandidate as unknown as DomComment;
  }
  const element = componentContext.current!.dom.createComment(text);
  return element;
}
