import {
  getContext,
  getGlobal,
  mount,
  setContext,
} from '@donkeyjs/jsx-runtime';
import { getMailContext } from '../mail/mailContext';
import { session } from '../session';
import type { Theme } from './Theme';
import { loadFonts } from './fonts/loadFonts';

const contextKey = Symbol('theme');
const counterKey = Symbol('themeCounter');

export const setTheme = (theme: Theme) => {
  const mail = getMailContext();

  setContext(contextKey, theme);

  const ensureKey = (key: 'themeClass' | 'mailThemeClass') => {
    if (!theme[key]) {
      const counter = getGlobal(counterKey, () => ({ count: -1 }));
      counter.count++;
      theme[key] = `theme${counter.count}${mail ? '-mail' : ''}`;
      if (theme.webFonts) loadFonts(theme.webFonts);

      const styles = themeStyles(!!mail, theme);
      mount(
        session.dom,
        function Theme() {
          return (
            <style type="text/css">
              {`.${theme.themeClass} { ${styles}; font: var(--font); color: var(--color); }`}
            </style>
          );
        },
        {},
        [],
        session.dom.head,
      );
    }
  };

  ensureKey('themeClass');
  if (mail) ensureKey('mailThemeClass');

  return theme;
};

export const withTheme = (theme: Theme, children: JSX.Children) => (
  <ThemeComponent theme={theme}>{children}</ThemeComponent>
);

function ThemeComponent(props: { children?: JSX.Children; theme: Theme }) {
  setTheme(props.theme);
  return props.children;
}

const themeStyles = (mail: boolean, theme?: Theme): string => {
  if (!theme) return '';

  const styles: Record<string, string> = {};

  const base = mail
    ? { ...theme.colors, ...theme.email?.colors }
    : theme.colors;

  const colors: Record<string, string> = {
    secondary: base.accent || base.color,
    'text-on-accent': base.background,
    'text-on-secondary': base['text-on-accent'] || base.background,
    ...base,
  };
  for (const color in colors) {
    styles[`--${color}`] = colors[color]!;
    styles[`--${color}-rgb`] = hex2rgb(colors[color]!);
  }
  styles['--font'] = mail ? theme.email?.font || theme.font : theme.font;
  styles['--spacing'] = theme.spacing;

  return Object.entries(styles)
    .map((entry) => entry.join(': '))
    .join('; ');
};

export const getTheme = () => getContext<Theme>(contextKey);

export const getThemedStyles = <Styles extends Record<string, string>>(
  key: string,
  styles: Styles,
) => {
  const theme = getTheme();
  const classes = theme.components[key];
  if (!classes) return styles;

  const themed: Record<string, string> = { ...styles };
  for (const key in classes) {
    themed[key] = [styles[key], classes[key]].filter(Boolean).join(' ');
  }

  return themed as Styles;
};

export const hex2rgb = (hex: string) => {
  let value = hex.replace('#', '');
  if (value.length === 3) value = `${value}${value}`;
  return value
    .slice(0, 7)
    .match(/\w\w/g)!
    .map((x) => Number.parseInt(x, 16))
    .join(', ');
};

export const rgb2hex = (red: number, green: number, blue: number) => {
  const hex = [red, green, blue]
    .map((x) => Math.round(x).toString(16).padStart(2, '0'))
    .join('');
  return `#${hex}`;
};

export const hsl2rgb = (
  h: number,
  s: number,
  l: number,
): [number, number, number] => {
  function getHue(input: number) {
    let h = input;
    h = h < 0 ? h + 1 : h > 1 ? h - 1 : h;
    if (h * 6 < 1) return m1 + (m2 - m1) * h * 6;
    if (h * 2 < 1) return m2;
    if (h * 3 < 2) return m1 + (m2 - m1) * (2 / 3 - h) * 6;
    return m1;
  }

  const hue = (h % 360) / 360;
  const sat = clamp(s);
  const ln = clamp(l);

  const m2 = ln <= 0.5 ? ln * (sat + 1) : ln + sat - ln * sat;
  const m1 = ln * 2 - m2;

  return [
    getHue(hue + 1 / 3) * 255,
    getHue(hue) * 255,
    getHue(hue - 1 / 3) * 255,
  ];
};

export const rgbFromHue = (
  background: 'LIGHT' | 'DARK',
  hue: number,
  sat = 1,
) => (background === 'LIGHT' ? hsl2rgb(hue, sat, 0.1) : hsl2rgb(hue, sat, 0.9));

const clamp = (val: number) => Math.min(1, Math.max(0, val));
