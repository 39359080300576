import { bind } from '@donkeyjs/jsx-runtime';
import type {
  LinkTypeData,
  PhotoTypeData,
  RichTypeData,
  VideoTypeData,
} from 'oembed-parser';
import { session } from '../session';
import styles from './Embed.module.css';

export type EmbedType = { originalUrl: string } & (
  | LinkTypeData
  | PhotoTypeData
  | VideoTypeData
  | RichTypeData
);

interface RenderEmbedProps {
  embed: EmbedType;
}

export function Embed(props: RenderEmbedProps) {
  // const { medium } = useRenderContext();
  // const noEmbeds = medium === 'email' || medium === 'email-in-browser';
  const noEmbeds = false;

  // const ref = useRef<HTMLDivElement | null>(null);
  // useEffect(() => {
  //   const scripts = ref.current?.getElementsByTagName('script');
  //   const inserts: HTMLScriptElement[] = [];

  //   if (scripts?.length) {
  //     for (const script of scripts as unknown as HTMLScriptElement[]) {
  //       const insert = document.createElement('script');
  //       insert.src = script.src;
  //       insert.async = script.async;
  //       insert.innerHTML = script.innerHTML;
  //       inserts.push(insert);
  //     }
  //     inserts.forEach((insert) => {
  //       document.body.appendChild(insert);
  //     });
  //     return () => {
  //       inserts.forEach((insert) => {
  //         document.body.removeChild(insert);
  //       });
  //     };
  //   }
  // }, [(embed as any).html]);

  return () => {
    const embed = props.embed;

    if (noEmbeds) {
      return (
        <a
          href={bind(() => embed.originalUrl)}
          target="_blank"
          rel="noreferrer"
        >
          <img
            class={styles.noEmbed}
            alt={bind(() => embed.title)}
            src={bind(() => embed.thumbnail_url)}
          />
        </a>
      );
    }

    if (embed.type === 'link') {
      return 'Not supported';
    }

    const style = () =>
      embed.width != null && embed.height != null
        ? `aspect-ratio: ${
            Math.floor((embed.width * 1000) / embed.height) / 1000
          };`
        : undefined;

    if (embed.type === 'photo')
      return (
        <img
          alt={bind(() => embed.title)}
          href={bind(() => embed.url)}
          style={bind(style)}
        />
      );

    const html = () => {
      if (session.dom.ssr) return '';
      return embed.html;
    };

    if (embed.type === 'rich')
      return (
        <div
          class={styles.rich}
          style={bind(style)}
          $html={bind(() => html())}
        />
      );

    return (
      <div
        class={styles.embed}
        style={bind(style)}
        $html={bind(() => html())}
      />
    );
  };
}
