import { getMailContext } from '../mail';
import type { Theme, ThemeClasses, ThemeInput } from './Theme';
import mailStyles from './defaultMailTheme.module.css';
import styles from './defaultTheme.module.css';

export const createTheme = (theme: ThemeInput | undefined): Theme => {
  const classes = {
    ...styles,
    ...theme?.styles,
  };

  const mailClasses = {
    ...mailStyles,
    ...theme?.email?.styles,
  };

  function className(key: keyof ThemeClasses): string | undefined {
    return mailClasses[key] && getMailContext()
      ? mailClasses[key as keyof ThemeClasses]!
      : classes[key as keyof ThemeClasses];
  }

  return {
    theme: 'DARK',
    email: null,
    font: '300 12pt Arial, sans-serif',
    spacing: '16px',
    webFonts: null,
    isAdmin: false,
    ...theme,

    colors: {
      background: '#ffffff',
      color: '#000000',
      error: '#b00020',
      accent: '#0000ff',
      ...theme?.colors,
    },

    components: theme?.components || {},

    class: new Proxy({} as Required<ThemeClasses>, {
      get: (_, key) => {
        return className(key as keyof ThemeClasses);
      },
      set: () => {
        return false;
      },
    }),
  };
};
