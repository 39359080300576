import { bind } from '@donkeyjs/jsx-runtime';
import { PhCaretDown } from '@donkeyjs/phosphor-icons';
import { store } from '@donkeyjs/proxy';
import type { BlockProps } from '.';
import { text } from '../i18n/i18n';
import { SlideOut, setLayout } from '../layout';
import { getTheme } from '../styles';
import styles from './ReadMoreBlock.module.css';
import { ChildBlocks } from './helpers/ChildBlocks';

export interface ReadMoreSettings {
  hideIcon?: boolean;
  label?: string;
  labelWhenOpen?: string;
}

export function ReadMoreBlock(props: BlockProps<ReadMoreSettings>) {
  const theme = getTheme();

  setLayout('inline');

  const state = store({
    open: false,
  });

  return (
    <div
      class={bind(() => [props.class, theme.class.block])}
      onmount={props.onmount}
    >
      <SlideOut
        class={bind(() => (state.open ? theme.class.block : undefined))}
        open={bind(() => state.open)}
      >
        <div class={theme.class.block}>
          <ChildBlocks
            label="Read More Content"
            nestedTextBlocks
            parentProps={props}
          />
        </div>
      </SlideOut>
      <button
        type="button"
        class={bind(() => [
          styles.button,
          theme.class.linkButton,
          { [styles.open]: state.open },
        ])}
        onclick={() => {
          state.open = !state.open;
        }}
      >
        {() => !props.hideIcon && <PhCaretDown weight="bold" />}
        <span>
          {() =>
            state.open
              ? props.labelWhenOpen || text('Common.Close')
              : props.label || text('Common.ReadMore')
          }
        </span>
      </button>
    </div>
  );
}
