import type { BaseSchemaInput } from '@donkeyjs/core';
import {
  type SchemaFromInput,
  type SchemaInputWithExtensions,
  createSchemaExtension,
} from '@donkeyjs/proxy';

export const socialButtonsSchemaExtension =
  createSchemaExtension<BaseSchemaInput>()({
    enums: {
      SocialMediaPlatform: [
        'FACEBOOK',
        'INSTAGRAM',
        'LINKEDIN',
        'MEDIUM',
        'PINTEREST',
        'REDDIT',
        'SNAPCHAT',
        'SPOTIFY',
        'TIKTOK',
        'TWITCH',
        'TWITTER',
        'X',
        'YOUTUBE',
      ] as const,
    },

    nodes: {
      SocialButton: {
        platform: 'SocialMediaPlatform',
        url: 'string',
        sortIndex: 'float',
      },
    },

    resolvers: {
      SocialButton: {
        socialButtons: {
          return: 'many',
          sort: [{ name: 'ASC', order: ['sortIndex:auto'] }],
        },
      },
    },

    i18n: {
      'en-GB': () => import('./i18n/enGB').then((m) => m.SchemaEnGB),
      nl: () => import('./i18n/nlNL').then((m) => m.SchemaNl),
    },
  });

export type SocialButtonsSchema = SchemaFromInput<
  SchemaInputWithExtensions<
    BaseSchemaInput,
    typeof socialButtonsSchemaExtension
  >
>;
