import type { DataList, List, ListMeta } from '../cache/DataList';
import type { DataNode, Node, NodeMeta } from '../cache/DataNode';

export function meta<
  Fields extends { readonly __typename: string },
  Value extends Node<Fields>,
>(value: Value): NodeMeta<Value>;
export function meta<
  Fields extends { readonly __typename: string },
  Value extends Node<Fields>,
>(value: Value | null | undefined): NodeMeta<Value> | undefined;
export function meta<Value extends DataList<any, any>>(
  value: Value,
): Value extends DataList<infer S, infer T>
  ? ListMeta<DataNode<S, T>, Value>
  : never;
export function meta<Value extends DataList<any, any>>(
  value: Value | null | undefined,
): Value extends DataList<infer S, infer T>
  ? ListMeta<DataNode<S, T>, Value> | undefined
  : never;
export function meta<
  Fields extends { readonly __typename: string },
  Value extends List<Fields>,
>(value: Value): ListMeta<Fields, Value>;
export function meta<
  Fields extends { readonly __typename: string },
  Value extends List<Fields>,
>(value: Value | null | undefined): ListMeta<Fields, Value> | undefined;

export function meta(value: any) {
  return (value as any)?.__meta;
}
