import { PhCaretLeft, PhCaretRight } from '@donkeyjs/phosphor-icons';
import { getTheme } from '../../../styles';
import styles from './YearSelector.module.css';
import type { DateFilterState } from './useDateFilter';

interface YearSelectorProps {
  state: DateFilterState;
}

export function YearSelector(props: YearSelectorProps) {
  const theme = getTheme();
  const state = props.state;

  return (
    <div class={styles.years}>
      {() =>
        state.previousYear == null ? (
          <span />
        ) : (
          <button
            type="button"
            class={[theme.class.linkButton, 'previous']}
            onclick={(event) => {
              event.preventDefault();
              state._selectedYear = state.previousYear;
            }}
          >
            <PhCaretLeft weight="bold" />
          </button>
        )
      }
      <div class={styles.year}>{() => state.selectedYear}</div>
      {() =>
        state.nextYear == null ? (
          <span />
        ) : (
          <button
            type="button"
            class={[theme.class.linkButton, 'next']}
            onclick={(event) => {
              event.preventDefault();
              state._selectedYear = state.nextYear;
            }}
          >
            <PhCaretRight weight="bold" />
          </button>
        )
      }
    </div>
  );
}
