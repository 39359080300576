import { bind } from '@donkeyjs/jsx-runtime';
import { PhPlus } from '@donkeyjs/phosphor-icons';
import { meta, type DataNode } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { session } from '../../../session';
import { Label } from '../../components/Label';
import { Select } from '../../components/Select';
import { usePlaceSearch } from './usePlaceSearch';

export function HtmlPlace(props: FieldRenderProps<'Place'>) {
  const search = usePlaceSearch();

  const recent = session.data.useNodes({ typename: 'Place' });
  meta(recent).request({
    createdAt: true,
    name: true,
    city: true,
    country: true,
    timeZone: true,
  });

  return (
    <Label
      class={'input'}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      onmount={props.onmount}
    >
      <Select
        search={bind(search, 'searchInput')}
        value={bind(
          () => props.field.value,
          (v) => {
            if (v) meta(v as DataNode<DataSchema, 'Place'>).isLocal = false;
            props.field.value = v as DataNode<DataSchema, 'Place'> | null;
          },
        )}
        allowEmpty={true}
        icon={(place) => {
          return meta(place).isLocal ? <PhPlus weight={'bold'} /> : null;
        }}
        options={() => ({
          get value() {
            return search.searchInput
              ? [...search.existing, ...search.fromMaps]
              : recent;
          },
        })}
      />
    </Label>
  );
}
