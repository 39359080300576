import { bind } from '@donkeyjs/proxy';
import styles from './SpinnerBackground.module.css';

interface SpinnerBackgroundProps {
  readonly enabled: boolean;
  readonly delay: number;
  readonly children?: JSX.Element[];
}

export function SpinnerBackground(props: SpinnerBackgroundProps) {
  return bind(() =>
    props.enabled ? (
      <div class={styles.spinnerBackground}>{props.children}</div>
    ) : (
      props.children
    ),
  );
}
