import {
  type ExternalNodeFromSchema,
  type FieldSchema,
  type NodeSchemaFromInput,
  type NodeSchemaInput,
  type NodeTypename,
  emptyFieldValue,
  nodeSchemaFromInput,
  store,
  validateValue,
} from '@donkeyjs/proxy';

export type SchemaStoreInput = {
  -readonly [P in keyof NodeSchemaInput<
    NodeTypename<DataSchema>,
    Extract<keyof DataSchema['enums'], string>
  >]: NodeSchemaInput<
    NodeTypename<DataSchema>,
    Extract<keyof DataSchema['enums'], string>
  >[P];
};

export type SchemaStoreValue<Input extends SchemaStoreInput> =
  ExternalNodeFromSchema<
    DataSchema,
    NodeSchemaFromInput<Input, never, never, never>
  >;

export type SchemaStore<Input extends SchemaStoreInput> =
  SchemaStoreValue<Input>;

interface SchemaStoreOptions {
  autoFix?: boolean;
}

export const schemaStore = <Input extends SchemaStoreInput>(
  schema: Readonly<Input>,
  values: SchemaStoreValue<Input>,
  appSchema: DataSchema,
  { autoFix }: SchemaStoreOptions = {},
): SchemaStore<Input> => {
  const nodeSchema = nodeSchemaFromInput(
    'External',
    schema,
    {},
    Object.keys(appSchema.enums),
  );

  const validate = (
    _: SchemaStore<Input>,
    key: keyof SchemaStore<Input>,
    value: unknown,
  ) => {
    if (!autoFix)
      validateValue(key as string, value, appSchema, nodeSchema.fields[key]);
    else {
      const field =
        nodeSchema.fields[key as keyof (typeof nodeSchema)['fields']];
      if (field)
        try {
          let result = value;
          result = fixFieldValue(field, result, appSchema);
          validateValue(
            key as string,
            result,
            appSchema,
            nodeSchema.fields[key],
          );
          return result;
        } catch (err) {
          return emptyFieldValue(nodeSchema.fields[key], appSchema);
        }
    }
  };

  return store(values, {
    afterGet: validate,
    beforeSet: validate,
  });
};

const fixFieldValue = (
  field: FieldSchema,
  valueInput: any,
  appSchema: DataSchema,
): unknown => {
  if (field.array)
    return (
      Array.isArray(valueInput) ? valueInput : valueInput ? [valueInput] : []
    ).map((value) =>
      fixFieldValue({ ...field, array: false }, value, appSchema),
    );

  const value = Array.isArray(valueInput) ? valueInput[0] : valueInput;

  if (value == null)
    return field.optional ? value : emptyFieldValue(field, appSchema);

  switch (field.type) {
    case 'int':
      return typeof value === 'number' ? value : Math.round(Number(value));
    case 'float':
      return typeof value === 'number' ? value : Number(value);
    case 'string':
      return typeof value === 'string'
        ? value
        : 'toString' in value
          ? value.toString()
          : new String(value).toString();
    case 'boolean':
      return !!value;
  }

  const enumSchema = (appSchema.enums as any)[field.type];
  if (enumSchema) {
    if (enumSchema.values.includes(value)) return value;
    for (const enumValue of enumSchema.values)
      if (enumValue.toLowerCase() === value.toLowerCase()) return enumValue;
  }

  return value;
};
