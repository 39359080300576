import {
  isDataNode,
  isNode,
  meta,
  type Culture,
  type Node,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { RoutingMap } from '../RoutingMap';
import type { RouteDefinition } from '../createRouter';
import type { NodeRouteMappings, RouterDataBlocks, UrlQuery } from '../types';

export function getNodeRouting(
  node: Node,
  map: RoutingMap,
  dataBlocks: () => RouterDataBlocks | undefined,
  culture: Culture,
  nodeRouting?: NodeRouteMappings,
): { route: RouteDefinition; query?: UrlQuery } | undefined {
  const set = nodeRouting?.[node.__typename as NodeTypename<DataSchema>];
  const found =
    set &&
    (typeof set === 'string'
      ? set
      : set.find((r) => isNode(node) && !!r.test?.(node as any)) ||
        set.find((r) => !r.test));

  let route: RouteDefinition | undefined;

  if (!found) {
    route = getAutoNodeRouting(node, map, dataBlocks, culture);
  } else {
    if (!found.routeKey) return undefined;
    route = map.routeByKey(culture, found.routeKey);
  }

  return !route
    ? undefined
    : {
        route: route,
        query: (isNode(node) && found?.query?.(node as any)) || undefined,
      };
}

function getAutoNodeRouting(
  node: Node,
  map: RoutingMap,
  dataBlocks: () => RouterDataBlocks | undefined,
  culture: Culture,
): RouteDefinition | undefined {
  const blocks = dataBlocks();
  if (blocks?.typenames[node.__typename]?.filtered.length) {
    for (const item of blocks.typenames[node.__typename]!.filtered) {
      if (meta(node).isMatch(item.args) === true)
        return map.routeById(culture, item.block.onRouteBlocks!.id);
    }
  }

  const result = blocks?.typenames[node.__typename]?.unfiltered?.onRouteBlocks;
  return result ? map.routeById(culture, result.id) : undefined;
}

export function getRouteTypename(
  routeInput: RouteDefinition | Node | null | undefined,
  dataBlocks: () => RouterDataBlocks | undefined,
  nodeRouting?: NodeRouteMappings,
) {
  const route = isDataNode<DataSchema, 'Route'>(routeInput)
    ? routeInput
    : (routeInput as RouteDefinition)?.node;
  if (route == null) return undefined;
  let found: string | undefined;
  if (nodeRouting) {
    for (const key in nodeRouting) {
      if (found) return;
      const value = nodeRouting[key as NodeTypename<DataSchema>]!;
      if (typeof value === 'string') {
        if (value === route?.key) found = key;
      } else {
        for (const k of value) {
          if (found) return;
          if (
            (k.routeKey == null || route.key === k.routeKey) &&
            (!k.testRoute || k.testRoute(route))
          )
            found = key;
        }
      }
    }
  }
  if (!found) found = dataBlocks()?.routeIds[route.id];
  return found;
}
