import type { DatabaseRouter } from '@donkeyjs/core';
import type { Dom } from '@donkeyjs/jsx-runtime';
import type { Culture, NodeRef, NodeTypename } from '@donkeyjs/proxy';
import type { ClientApp } from './apps/bootClientApp';
import type { DataClientFromSchema } from './data/createDataClient';
import { I18nContext } from './i18n/getI18n';
import type { LazyResource } from './loaders/lazy';

export interface ClientSession {
  app: ClientApp;
  resources: Map<string, LazyResource<any>>;
  data: DataClientFromSchema<DataSchema, ApplicationSchema>;
  dom: Dom;
  router: DatabaseRouter;
  i18n(culture?: Culture): I18nContext;
}

let _session: { current: ClientSession } | undefined;
export const session = new Proxy({} as unknown as ClientSession, {
  get: (_, key: keyof ClientSession) => {
    if (!_session)
      throw new Error('Session not initalized by calling bootClientApp');
    return _session.current[key];
  },

  set: (_, key: keyof ClientSession, value) => {
    if (!_session)
      throw new Error('Session not initalized by calling bootClientApp');
    _session.current[key] = value;
    return true;
  },
});

export const isNodeType = (
  value: string | null | undefined,
): value is NodeTypename<DataSchema> =>
  !!value && value in session.app.schema.nodes;

export const isNodeRef = <
  Typename extends NodeTypename<DataSchema> = NodeTypename<DataSchema>,
>(
  value: string | null | undefined,
  typename?: Typename,
): value is NodeRef<DataSchema, Typename> => {
  if (!value) return false;

  const [refTypename, refId] = value.split(':');
  if (!refTypename || !refId) return false;

  if (typename && refTypename === typename) return true;
  return isNodeType(refTypename);
};

export const initalizeSession = ({
  app,
  data,
  defaultCulture,
  dom,
  session,
}: {
  app: ClientApp;
  data: DataClientFromSchema<DataSchema, ApplicationSchema>;
  defaultCulture: Culture;
  dom: Dom;
  session: { current: ClientSession };
}) => {
  const i18n = new Map<Culture, I18nContext>();
  session.current.app = app;
  session.current.data = data;
  session.current.dom = dom;
  session.current.resources = new Map();
  session.current.i18n = (culture = defaultCulture) => {
    if (!i18n.has(culture)) {
      i18n.set(culture, new I18nContext({ culture }));
    }
    return i18n.get(culture)!;
  };
  _session = session;
};
