import { store } from '@donkeyjs/proxy';

type RequestFonts = (family: string, link: string, isGoogle?: boolean) => void;

export const fonts = store<{
  links: Record<string, string>;
  css?: Record<string, string>;
  hasGoogleFonts?: boolean;
}>({ links: {} });

export const requestFonts: RequestFonts = (
  family: string,
  link: string,
  isGoogle?: boolean,
) => {
  if (fonts.links[family] !== link)
    fonts.links = { ...fonts.links, [family]: link };
  if (isGoogle) fonts.hasGoogleFonts = true;
};
