import {
  batch,
  dontWatch,
  isBinding,
  isDataList,
  isStore,
  resolveBinding,
  signal,
  store,
  watch,
} from '@donkeyjs/proxy';
import debounce from 'debounce';
import { bindContext, onMount, withContext } from '../component';
import type { DomElement, JSXNode, JSXVirtualNode } from '../dom';
import { componentContext, type RenderContext } from '../mount/mount';
import { createFragment } from './createFragment';
import { getAttributeValue } from './getAttributeValue';

export function createElement(
  values: JSXNode,
  nextSsrCandidate?: Node | null,
): JSXVirtualNode {
  let context = componentContext.current!;
  if (!context) throw new Error('Cannot render a JSX element without context');

  let ssrCandidate = nextSsrCandidate;

  const current = {
    tag: values.tag,
    attrValues: values.props ?? {},
    attr: values.props,
    children: values.children,
  };
  current.attr = store(current.attrValues);

  const cleanup: (() => void)[] = [];
  const disposeElement = watch((initial) => {
    withContext(context, () => {
      const tag = current.attr.$element || current.tag;
      if (tag === current.tag) return;
      current.tag = tag;
      if (initial) return;
      dispose();
      element.value = [fromSsr(context, current.tag, ssrCandidate)];
      activateChildren();
    });
  }).dispose;

  if (current.tag === 'svg') {
    context = Object.create(context);
    context.namespace = 'http://www.w3.org/2000/svg';
  }

  const element = signal([fromSsr(context, current.tag, ssrCandidate)]);
  ssrCandidate = undefined;

  let isActivated = false;
  let deactivate: () => void;
  let children: JSXVirtualNode | undefined;

  const activateChildren = () => {
    cleanup.push(
      watch(() => {
        withContext(context, () => {
          const nextChildren = current.children;
          if (children) {
            children.update(nextChildren);
          } else {
            children = createFragment(nextChildren, element.value[0]);
            cleanup.push(children.dispose);
          }
        });
      }).dispose,
    );
    cleanup.push(
      watch(() => {
        withContext(context, () => children!.nodes);
      }).dispose,
    );
  };
  activateChildren();

  function dispose() {
    deactivate?.();
    for (const disposeFn of cleanup) {
      disposeFn();
    }
    cleanup.length = 0;
    children = undefined;
    isActivated = false;
  }

  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: values,
    testUpdate(values: JSXNode) {
      if (
        values == null ||
        typeof values !== 'object' ||
        !values.tag ||
        !values.props
      ) {
        return 'invalid JSX node';
      }
      const newTag = resolveBinding(values.props.$element) || values.tag;
      if (current.tag !== newTag) {
        return `tag changed unexpectedly from ${current.tag.toString()} to ${newTag.toString()}`;
      }
      if (current.children === values.children) return true;
      return children?.testUpdate(values.children) ?? true;
    },
    update(values: JSXNode) {
      const test = result.testUpdate(values);
      if (test !== true) return test;
      result.currentValue = values;
      batch(() => {
        current.attrValues = values.props;
        current.children = values.children;
        store.assign(current.attr, values.props);
        children?.update(current.children);
      });

      return true;
    },
    dispose() {
      disposeElement();
      dispose();
    },
    get nodes() {
      if (!isActivated) {
        isActivated = true;
        deactivate = activateElement(element.value[0], current.attr);
      }
      return element.value;
    },
  };

  return result;
}

export function canUpdateFromProps(
  aProps: Record<string, any>,
  bProps: Record<string, any>,
): true | string {
  if (aProps === bProps) return true;
  return dontWatch(() => {
    const a = isStore(aProps) ? aProps.$.source : aProps;
    const b = isStore(bProps) ? bProps.$.source : bProps;
    if (a === b) return true;
    for (const key in b) {
      if (key === 'children') continue;
      const valA = a[key];
      const valB = b[key];
      if (valA === valB) continue;
      if (isBinding(valA) && isBinding(valB)) continue;
      if (typeof valA === 'function' && typeof valB === 'function') continue;
      if (canUpdateArray(valA, valB)) continue;
      return `attribute '${key}' changed unexpectedly`;
    }
    return true;
  });
}

export function canUpdateArray(a: any, b: any): boolean {
  if (a === b) return true;
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (isDataList(a) || isDataList(b)) return false;
  if (a.length !== b.length) return false;
  return a.every((item, i) => item === b[i]);
}

export function activateElement(
  element: DomElement,
  attributes: Record<string, any>,
) {
  const dispose = activateAttributes(element, attributes);

  return () => {
    dispose();
  };
}

function activateAttributes(
  element: DomElement,
  attributes: Record<string, any>,
) {
  const dispose: (() => void)[] = [];

  if ('onmount' in attributes || 'autofocus' in attributes) {
    attachOnMount(dispose, element, attributes);
  }

  for (const key in attributes) {
    if (key === 'onmount' || key === 'autofocus') {
      continue;
    }
    if (key === '$html') {
      dispose.push(
        watch(
          bindContext(() => {
            element.innerHTML = resolveBinding(attributes.$html);
          }),
        ).dispose,
      );
      continue;
    }
    if (key === '$element') continue;
    dispose.push(
      watch(
        bindContext((first) => {
          const [name, value, attr] = getAttributeValue(
            false,
            element.tagName,
            key,
            resolveBinding(attributes![key]),
            resolveBinding(attributes!.type),
          );
          if (first || !attr) {
            if (value == null || value === false) {
              element.removeAttribute(name);
            } else if (typeof value === 'function') {
              return attachHandler(element, name, value);
            } else {
              element.setAttribute(name, value);
            }
          } else {
            (element as any)[attr] = value;
          }
        }),
      ).dispose,
    );
  }

  dispose.push(attachChangeHandler(element, attributes.type, attributes));

  return () => {
    dontWatch(() => {
      for (const disposeFn of dispose) {
        disposeFn();
      }
    });
  };
}

function attachHandler(
  element: DomElement,
  key: string,
  value: (...args: any[]) => (() => void) | void,
): (() => void) | undefined {
  if (!componentContext.current || componentContext.current.dom.ssr) return;

  if (key === 'onsize') {
    return attachResizeObserver(value, element);
  }

  if (key.startsWith('on')) {
    const event = key.slice(2);
    const bound = bindContext(value);
    element.addEventListener(event, bound);
    return () => {
      element.removeEventListener(event, bound);
    };
  }
}

function attachResizeObserver(
  value: (...args: any[]) => (() => void) | void,
  element: DomElement,
) {
  const bound = bindContext(value);
  let dispose: (() => void) | void;
  const handler = debounce(([entry]: ResizeObserverEntry[]) => {
    dispose?.();
    dispose = bound(entry);
  }, 10);

  let isDisposed = false;
  let observer: ResizeObserver | undefined;
  onMount(() => {
    if (!isDisposed) {
      observer = new ResizeObserver(handler);
      observer.observe(element as HTMLElement);
    }
  });
  return () => {
    isDisposed = true;
    observer?.disconnect();
    observer = undefined;
    dispose?.();
  };
}

function attachOnMount(
  dispose: (() => void)[],
  element: DomElement,
  attributes: Record<string, any>,
) {
  let isDisposed = false;
  dispose.push(() => {
    isDisposed = true;
  });
  const run = bindContext((fn: (el: DomElement) => (() => void) | void) =>
    fn?.(element),
  );

  onMount(() => {
    if (!isDisposed && attributes.autofocus) {
      (element as HTMLElement).focus?.();
    }
  });

  onMount(() => {
    return watch(() => {
      if (!isDisposed) {
        const onmount = attributes.onmount;
        return dontWatch(() => {
          const disposeWatch: ((() => void) | void)[] = [];
          if (onmount) {
            if (Array.isArray(onmount)) {
              for (const fn of onmount.flat()) {
                disposeWatch.push(run(fn));
              }
            } else {
              disposeWatch.push(run(onmount));
            }
          }
          return () => {
            for (const fn of disposeWatch) {
              fn?.();
            }
          };
        });
      }
    }).dispose;
  });
}

function attachChangeHandler(
  element: DomElement,
  type: string,
  attr: Record<string, any>,
): () => void {
  if (!('value' in attr)) return () => {};

  function onChange(ev: Event) {
    if (type === 'radio') {
      ev.preventDefault();
      attr.value = true;
    } else if (type === 'checkbox') {
      ev.preventDefault();
      attr.value = !(attr as any).value;
    } else (attr as any).value = (ev.target as HTMLInputElement).value;
  }

  const name =
    type === 'checkbox' || type === 'radio' || type === 'select'
      ? 'onchange'
      : 'oninput';

  return attachHandler(element, name, onChange) || (() => {});
}

function fromSsr(
  context: RenderContext,
  tag: string,
  nextSsrCandidate?: Node | null,
) {
  if (nextSsrCandidate) {
    if (
      nextSsrCandidate?.nodeType === 1 &&
      (nextSsrCandidate as HTMLElement).tagName.toLowerCase() ===
        tag.toLowerCase()
    ) {
      context.dom.ssrCursor?.set(nextSsrCandidate, nextSsrCandidate.firstChild);
      return nextSsrCandidate as unknown as DomElement;
    }
    (context.dom.ssrMismatches ??= []).push([
      `Expected ${tag} but got`,
      nextSsrCandidate,
    ]);
  }
  return context.dom.createElement(tag, {}, context.namespace);
}
