import type { RouteDefinition } from '@donkeyjs/core';
import { getGlobal } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';

const key = Symbol('system-routes');

interface SystemRoutes {
  routes: RouteDefinition[];
  register(...routes: RouteDefinition[]): void;
}

export function useSystemRoutes() {
  return getGlobal(key, () => {
    const value = store<SystemRoutes>({
      routes: [],
      register(...routes) {
        value.routes = [...value.routes, ...routes];
      },
    });
    return value;
  });
}
