import type {
  DataNode,
  InsertableNodeFromSchema,
  NodeTypename,
} from '@donkeyjs/proxy';
import { useDragHandler } from './useDragHandler';
import type { AcceptedDrag, DropPosition } from './useDropZone';

export interface DraggableOptions<Accept extends AcceptedDrag> {
  item: Accept extends NodeTypename<DataSchema>
    ? DataNode<DataSchema, Accept>
    : Accept extends `Create${infer T extends NodeTypename<DataSchema>}`
      ? { typename: T; values: InsertableNodeFromSchema<DataSchema, T> }
      : Accept extends 'native-files'
        ? { type: 'files'; files: FileList | undefined }
        : { type: string; value: any };
  accept?: (key: unknown, position: DropPosition) => boolean;
  listKey?: unknown;
  copy?: boolean;
  handle?: HTMLElement | string;
  onCancelDrag?: () => void;
  onDrop?: () => void;
}

export const draggable =
  <T extends AcceptedDrag>(options: DraggableOptions<T>) =>
  (element: HTMLElement, handle?: HTMLElement | string) => {
    const handler = useDragHandler();

    if (!options.item) return () => {};

    const handleInput = handle || options.handle;
    const handleElement = handleInput
      ? typeof handleInput === 'string'
        ? (element.querySelector(handleInput) as HTMLElement)
        : handleInput
      : element;
    if (!handleElement) throw new Error('Handle not found');

    const dragStart = (ev: DragEvent) => {
      handler.startDrag(ev, options, element);
    };

    handleElement.draggable = true;
    handleElement.addEventListener('dragstart', dragStart);

    return () => {
      handleElement.draggable = false;
      handleElement.removeEventListener('dragstart', dragStart);
    };
  };
