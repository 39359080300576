import { Portal, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhCaretLeft, PhCaretRight, PhX } from '@donkeyjs/phosphor-icons';
import { store } from '@donkeyjs/proxy';
import styles from './Lightbox.module.css';

interface LightboxProps {
  index: number;
  readonly length: number;
  readonly slide: (index: number) => JSX.Children;
  readonly onclose: () => void;
}

export function Lightbox(props: LightboxProps) {
  function previous() {
    props.index -= 1;
    if (props.index < 0) {
      setTimeout(() => {
        state.looping = true;
        setTimeout(() => {
          props.index = props.length - 1;
          setTimeout(() => {
            state.looping = false;
          }, 10);
        }, 0);
      }, 350);
    }
  }

  function next() {
    props.index += 1;
    if (props.index >= props.length) {
      setTimeout(() => {
        state.looping = true;
        setTimeout(() => {
          props.index = 0;
          setTimeout(() => {
            state.looping = false;
          }, 10);
        }, 0);
      }, 350);
    }
  }

  let startX = 0;
  let x = 0;
  const state = store({
    diff: 0,
    looping: false,
  });

  function handleTouchStart(ev: TouchEvent | MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    if ('button' in ev && ev.button !== 0) return;
    startX =
      'touches' in ev ? ev.touches[0]?.clientX : (ev as MouseEvent).clientX;

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('mousemove', handleTouchMove);
    document.addEventListener('mouseup', handleTouchEnd);
  }

  function handleTouchMove(ev: TouchEvent | MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    x = 'touches' in ev ? ev.touches[0]?.clientX : (ev as MouseEvent).clientX;
    state.diff = x - startX;
  }

  function handleTouchEnd(ev: TouchEvent | MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    state.diff = 0;
    const diff = x - startX;
    if (diff > 50) previous();
    else if (diff < -50) next();

    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
    document.removeEventListener('mousemove', handleTouchMove);
    document.removeEventListener('mouseup', handleTouchEnd);
  }

  function handleMouseWheel(ev: WheelEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.deltaY > 0) next();
    else if (ev.deltaY < 0) previous();
  }

  return (
    <Portal parent={session.dom.body}>
      <div
        class={styles.lightbox}
        onkeydown={(ev) => {
          if (ev.key === 'Escape') props.onclose();
          if (ev.key === 'ArrowLeft') previous();
          if (ev.key === 'ArrowRight') next();
        }}
        tabindex={0}
        onmount={(element) => {
          element.focus();
        }}
      >
        <div class={styles.controls}>
          <div class={styles.button} onclick={previous}>
            <PhCaretLeft weight="bold" />
          </div>
          <div class={styles.button} onclick={next}>
            <PhCaretRight weight="bold" />
          </div>
          <div
            class={[styles.button, styles.closeButton]}
            onclick={() => props.onclose()}
          >
            <PhX weight="bold" />
          </div>
        </div>
        <div
          class={styles.slides}
          ontouchstart={handleTouchStart}
          onmousedown={handleTouchStart}
          onwheel={handleMouseWheel}
          style={bind(
            () =>
              `width: ${
                (props.length + 2) * 100
              }vw; transform: translateX(calc(-${(props.index + 1) * 100}vw + ${
                state.diff
              }px)); transition: ${
                state.diff !== 0 || state.looping
                  ? 'none'
                  : 'transform 0.3s ease'
              }`,
          )}
        >
          <div class={styles.slide}>{props.slide(props.length - 1)}</div>
          {Array.from({ length: props.length }, (_, i) => (
            <div
              class={styles.slide}
              onmouseup={(ev) => {
                if (
                  state.diff === 0 &&
                  ev.button === 0 &&
                  ev.target === ev.currentTarget
                )
                  props.onclose();
              }}
            >
              {props.slide(i)}
            </div>
          ))}
          <div class={styles.slide}>{props.slide(0)}</div>
        </div>
      </div>
      ,
    </Portal>
  );
}
