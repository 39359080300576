import { Loader, getTheme, session, type BlockProps } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta, type DataNode } from '@donkeyjs/proxy';
import { iconColors, icons } from '../icons';
import styles from './SocialButtonsBlock.module.css';

export interface SocialButtonsSettings {
  color?: boolean;
  colorHover?: boolean;
  size?: 'small' | 'medium' | 'large';
  spacing?: 'small' | 'medium' | 'large';
}

export function SocialButtonsBlock(props: BlockProps<SocialButtonsSettings>) {
  const theme = getTheme();

  const buttons = session.data.getSocialButtons({});

  return (
    <div
      class={bind(() => [
        styles.social,
        props.class,
        theme.class.block,
        (props.size ?? 'medium') === 'medium'
          ? ''
          : styles[`size-${props.size as 'small' | 'large'}`],
        props.spacing ? styles[`spacing-${props.spacing}`] : '',
        {
          [styles.color]: props.color,
          [styles.colorHover]: props.colorHover,
        },
      ])}
      onmount={props.onmount}
    >
      <Loader loading={bind(() => meta(buttons).isLoading)} type="none">
        {() =>
          buttons.length
            ? buttons.$((button: DataNode<DataSchema, 'SocialButton'>) => {
                return (
                  <a
                    href={button.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={bind(() => {
                      if (!props.color && !props.colorHover) return '';
                      const color = iconColors[button.platform];
                      return color ? `--social-button-color: ${color};` : '';
                    })}
                  >
                    {() => icons[button.platform]?.({ weight: 'fill' })}
                  </a>
                );
              })
            : 'No buttons selected'
        }
      </Loader>
    </div>
  );
}
