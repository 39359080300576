import {
  Image,
  dropZone,
  getUserContext,
  masonry,
  type RenderViewContainerFunction,
  type ViewMode,
} from '@donkeyjs/client';
import { bind, setState } from '@donkeyjs/jsx-runtime';
import {
  generateNodeId,
  isDataNode,
  watch,
  type DataList,
} from '@donkeyjs/proxy';
import styles from './GalleryContainer.module.css';
import { Lightbox } from './Lightbox';
import { addToGallery } from './helpers/addToGallery';
import { setGallery } from './useGallery';

export const renderGalleryContainer: RenderViewContainerFunction<
  DataSchema,
  'FileRef'
> = (props, children) => (
  <GalleryContainer
    data={props.data as DataList<DataSchema, 'FileRef'>}
    mode={bind(() => props.mode)}
  >
    {children}
  </GalleryContainer>
);

interface GalleryContainerProps {
  readonly data: DataList<DataSchema, 'FileRef'> | null | undefined;
  readonly children?: JSX.Children | undefined;
  readonly mode: ViewMode | undefined;
}

export function GalleryContainer(props: GalleryContainerProps) {
  const user = getUserContext();

  const gallery = setGallery({
    active: undefined,
    data: props.data,
  });

  const key = generateNodeId();

  const state = setState({
    adding: false,
  });

  return (
    <>
      <div
        class={bind(() => [
          'gallery',
          styles.gallery,
          props.mode && `gallery-${props.mode.key}`,
          { [styles.adding]: state.adding },
        ])}
        onmount={[
          (element) => {
            return watch(() => {
              if (props.mode?.key === 'masonry') {
                return masonry(element);
              }
            }).dispose;
          },
          dropZone({
            key,
            onDrag(item) {
              if (!props.data || !user.can('insert', 'FileRef')) return;

              if (item.type === 'files') {
                return {
                  positions: ['inside'],
                  hover() {
                    state.adding = true;
                    return () => {
                      state.adding = false;
                    };
                  },
                  drop() {
                    addToGallery(props.data!, item.files || []);
                  },
                };
              }

              if (item.type === 'node') {
                const node = item.node;
                if (isDataNode<DataSchema, 'File'>(node, 'File')) {
                  return {
                    positions: ['inside'],
                    hover() {
                      state.adding = true;
                      const dispose = addToGallery(props.data!, [node], true);
                      return () => {
                        dispose?.();
                        state.adding = false;
                      };
                    },
                    drop() {
                      addToGallery(props.data!, [node]);
                    },
                  };
                }
              }
            },
          }),
          /*
    containerDragDrop: (data) => ({
      accept: (item) =>
        item.type === 'files' ||
        (item.type === 'node' && item.node.__typename === 'File'),
      interceptHover(item) {
        if (!isDataList<DataSchema, 'FileRef'>(data)) {
          return;
        }

        if (item.type === 'files')
          return addToGallery(data, item.files || [], true);

        if (item.type === 'node') {
          const node = item.node;
          if (isDataNode<DataSchema, 'File'>(node, 'File'))
            return addToGallery(data, [node], true);
        }
      },
      interceptDrop(item) {
        if (!isDataList<DataSchema, 'FileRef'>(data)) {
          return true;
        }

        if (item.type === 'files') addToGallery(data, item.files || []);
        else if (item.type === 'node') {
          const node = item.node;
          if (isDataNode<DataSchema, 'File'>(node, 'File'))
            addToGallery(data, [node]);
        }

        return true;
      },
    }),
    */
        ]}
      >
        {() =>
          props.data?.length ? (
            props.children
          ) : user.can('insert', 'FileRef') ? (
            <div class={styles.empty}>Drag files here</div>
          ) : null
        }
      </div>

      {() =>
        gallery.active !== undefined && (
          <Lightbox
            index={bind(
              () => gallery.active ?? 0,
              (index) => {
                gallery.active = index;
              },
            )}
            length={bind(() => gallery.data?.length ?? 0)}
            slide={(index) => (
              <Image class={styles.image} file={gallery.data?.[index]} />
            )}
            onclose={() => {
              gallery.active = undefined;
            }}
          />
        )
      }
    </>
  );
}
