import { bind, preventDefault } from '@donkeyjs/jsx-runtime';
import { tooltip } from '../../../donkey';
import styles from './OptionButtons.module.css';

interface OptionButtonsProps {
  value: string;
  readonly options: { value: string; label: string }[];
  readonly optionButtons: Record<string, JSX.Children>;
}

export function OptionButtons(props: OptionButtonsProps) {
  return (
    <div role="group">
      {props.options.map((option) =>
        !props.optionButtons[option.value] ? null : (
          <button
            type="button"
            class={bind(() => [
              styles.button,
              { [styles.selected]: props.value === option.value },
            ])}
            onclick={preventDefault(() => {
              props.value = option.value;
            })}
            onmount={tooltip(option.label)}
          >
            {props.optionButtons[option.value]}
          </button>
        ),
      )}
    </div>
  );
}
