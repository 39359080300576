import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import { store, type DataList, type DataNode } from '@donkeyjs/proxy';

const key = Symbol();

export interface GalleryContext {
  active: number | undefined;
  data:
    | DataList<DataSchema, 'FileRef'>
    | DataNode<DataSchema, 'FileRef'>[]
    | null
    | undefined;
  goto: (item: DataNode<DataSchema, 'FileRef'>) => void;
}

export function useGallery() {
  return getContext<GalleryContext | undefined>(key);
}

export function setGallery(
  context: Pick<GalleryContext, 'active' | 'data'>,
): GalleryContext {
  const value = store<GalleryContext>({
    ...context,
    goto(item) {
      const index = value.data?.findIndex((i) => i.id === item.id);
      if (index !== undefined) value.active = index;
    },
  });

  setContext(key, value);
  return value;
}
