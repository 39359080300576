import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { BlockProps, ButtonsProps } from '../..';
import { ChildBlocks } from '../../blocks/helpers/ChildBlocks';
import { getTheme } from '../../styles';
import { getLayoutContext } from '../layoutContext';
import { getNodeContext } from '../nodeContext';
import { DataFormButtons } from './DataFormButtons';

export interface DataFormStepSettings extends Partial<ButtonsProps> {
  name?: string;
  showStepper?: boolean;
}

export function DataFormStep(props: BlockProps<DataFormStepSettings>) {
  const context = getNodeContext();
  const layout = getLayoutContext();
  const theme = getTheme();

  return () =>
    context?.step?.id === props.block?.id ? (
      <>
        <ChildBlocks
          parentProps={props}
          label={bind(() => `${props.name} Step`)}
          class={bind(() =>
            layout.wrap === 'flow' ? theme.class.flow : undefined,
          )}
        />
        {jsxx(
          DataFormButtons,
          store.clone(props, {
            onClick: (isDefault: boolean) => {
              if (isDefault) {
                context?.goNextStep();
              } else {
                context?.goPreviousStep();
              }
            },
          }),
        )}
      </>
    ) : null;
}
