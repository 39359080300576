import { Errors } from '../layout';
import type { LoginFormType } from './getLoginForm';

interface LoginFormProps {
  form: LoginFormType;
  isConfirm?: boolean;
}

export function LoginForm(props: LoginFormProps) {
  return (
    <>
      {() => props.isConfirm && <h1>Confirm it's you</h1>}
      <Errors node={props.form} />
      {() => !props.isConfirm && <props.form.$.email autofocus />}
      <props.form.$.password password />
    </>
  );
}
