import { changeMatch } from '../helpers/changeMatch';
import type { ValidationError } from '../helpers/validate';
import { isDataNode, type Node } from './DataNode';
import { getSortIndex } from './getSortIndex';

export function processListUpdates<
  Fields extends { readonly __typename: string },
>({
  target,
  previous,
  match,
  sort,
  nodeFromInput,
}: {
  target: Node<Fields>[];
  previous: Node<Fields>[];
  match: any;
  sort: any; // QuerySortOrder
  nodeFromInput: (input: any) => [Node<Fields>, void | (() => void)];
}) {
  const errors: ValidationError[] = [];

  const removed = previous.filter((instance) => !target.includes(instance));
  for (const item of removed) {
    errors.push(...changeMatch('remove', match, item)[0]);
  }

  const added = target.filter(
    (instance) => !isDataNode(instance) || !previous.includes(instance),
  );

  for (const item of added) {
    const [instance] = nodeFromInput(item);
    if (item !== instance)
      target.splice(target.indexOf(item as any), 1, instance);

    const index = target.indexOf(instance);
    const [sortKey, sortValue] = getSortIndex(
      sort,
      target,
      target[index - 1],
      target[index + 1],
    );

    if (sortKey && sortValue != null && !(instance as any)[sortKey])
      (instance as any)[sortKey] = sortValue;
  }

  return errors;
}
