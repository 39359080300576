import { bind } from '@donkeyjs/jsx-runtime';
import { Heading, type HeadingStyle } from './Heading';
import { IncreaseHeadingLevel } from './IncreaseHeadingLevel';

interface WithHeadingProps {
  styleAs?: HeadingStyle;
  onmount?: JSX.OnMount<HTMLElement>;
  heading: JSX.Children;
  class?: JSX.ClassNames;
  children?: JSX.Children;
}

export function WithHeading(props: WithHeadingProps) {
  return (
    <>
      <Heading
        class={bind(() => props.class)}
        onmount={bind(() => props.onmount)}
        styleAs={bind(() => props.styleAs)}
      >
        {() => props.heading}
      </Heading>
      <IncreaseHeadingLevel>{props.children}</IncreaseHeadingLevel>
    </>
  );
}
