import {
  createDataNode,
  createSchema,
  nodeSchemaFromInput,
  type DataFromSchema,
  type DataNode,
} from '@donkeyjs/proxy';
import { getUserContext } from './getUserContext';

const schema = createSchema({
  nodes: {
    Login: {
      email: 'string',
      password: 'string',
    },
  },
});

export type LoginFormType = DataNode<DataFromSchema<typeof schema>, 'Login'>;

export const getLoginForm = (): LoginFormType => {
  const user = getUserContext();
  return createDataNode({
    values: {
      __typename: 'Login',
      email: user?.user?.email || '',
      password: '',
    },
    nodeSchema: nodeSchemaFromInput('Login', {
      email: 'string',
      password: 'string',
    }),
  });
};
