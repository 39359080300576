import debounce from 'debounce';

export const masonry = (element: HTMLElement, childSelector = '.view') => {
  let childWidth = 0;
  let resizeObserver: ResizeObserver | undefined;
  let mutationObserver: MutationObserver | undefined;
  let children: HTMLElement[] | undefined;

  function apply() {
    resizeObserver?.disconnect();
    mutationObserver?.disconnect();

    children = Array.from(element.children).filter((child) =>
      child.matches(childSelector),
    ) as HTMLElement[];

    if (!children.length) {
      dispose(element, children);
      return;
    }

    if (!childWidth) childWidth = children[0].offsetWidth;
    if (!childWidth) {
      dispose(element, children);
      return;
    }

    setup(element, children, childWidth);

    // Observe the element and its children
    resizeObserver = new ResizeObserver(() => update());
    resizeObserver.observe(element);
    for (const child of children) {
      resizeObserver.observe(child);
    }

    // Observe child mutations
    mutationObserver = new MutationObserver(() => update());
    mutationObserver.observe(element, { childList: true, subtree: true });
  }

  const update = debounce(apply, 0);
  apply();

  return () => {
    update.clear();
    resizeObserver?.disconnect();
    mutationObserver?.disconnect();
    if (children) dispose(element, children);
  };
};

function setup(
  element: HTMLElement,
  children: HTMLElement[],
  childWidth: number,
) {
  const elementWidth = element.offsetWidth;
  if (!elementWidth) return;

  const columnGap = Number.parseInt(
    window.getComputedStyle(element).getPropertyValue('grid-column-gap'),
    10,
  );
  const rowGap = Number.parseInt(
    window.getComputedStyle(element).getPropertyValue('grid-row-gap'),
    10,
  );

  const columns = Math.floor(elementWidth / childWidth);
  const columnWidth = (elementWidth - (columns - 1) * columnGap) / columns;

  const columnHeights = new Array(columns).fill(0);

  for (const child of children) {
    // Find the shortest column
    const shortestColumnIndex = columnHeights.indexOf(
      Math.min(...columnHeights),
    );

    // Position the child element at the top of the shortest column
    child.style.position = 'absolute';
    child.style.top = `${columnHeights[shortestColumnIndex]}px`;
    child.style.left = `${
      shortestColumnIndex * columnWidth + shortestColumnIndex * columnGap
    }px`;
    child.style.width = `${columnWidth}px`;

    // Increase the height of the column by the height of the child element
    columnHeights[shortestColumnIndex] += child.offsetHeight + rowGap;
  }

  // Set the height of the element to the height of the tallest column
  element.style.position = 'relative';
  element.style.height = `${Math.max(...columnHeights)}px`;
}

function dispose(element: HTMLElement, children: HTMLElement[]) {
  for (const child of children) {
    child.style.removeProperty('position');
    child.style.removeProperty('top');
    child.style.removeProperty('left');
    child.style.removeProperty('width');
  }

  // Set the height of the element to the height of the tallest column
  element.style.removeProperty('position');
  element.style.removeProperty('height');
}
