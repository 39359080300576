import { bind, jsxx, preventDefault } from '@donkeyjs/jsx-runtime';
import { PhCaretLeft, PhCaretRight } from '@donkeyjs/phosphor-icons';
import styles from './Calendar.module.css';
import { Month } from './Month';
import { Time } from './Time';
import { useCalendar, type UseCalendarProps } from './useCalendar';

export interface CalendarProps extends UseCalendarProps {
  includeTime?: boolean;
  vertical?: boolean;
  shadow?: boolean;
}

export function Calendar(props: CalendarProps) {
  const state = useCalendar(props);

  return (
    <div
      class={bind(() => [
        styles.calendar,
        { [styles.vertical]: props.vertical, [styles.shadow]: props.shadow },
      ])}
      onmousedown={preventDefault}
    >
      <div class={styles.date}>
        <div class={styles.header}>
          <button
            type="button"
            class={[styles.button, styles.nav]}
            disabled={bind(() => !state.canGoBack)}
            onclick={preventDefault(() => state.goBack())}
          >
            <PhCaretLeft weight="bold" />
          </button>
          <button
            type="button"
            class={[styles.button, styles.nav]}
            disabled={bind(() => !state.canGoForward)}
            onclick={preventDefault(() => state.goForward())}
          >
            <PhCaretRight weight="bold" />
          </button>
          <button type="button" class={styles.button}>
            <span>{() => state.formattedMonth}</span>
          </button>
          <button type="button" class={styles.button}>
            <span>{() => state.formattedYear}</span>
          </button>
        </div>

        {jsxx(Month, state)}
      </div>
      {() =>
        props.includeTime && <div class={styles.time}>{jsxx(Time, state)}</div>
      }
    </div>
  );
}
