import { session } from '../session';

interface BlocksProps {
  on?: 'app';
  segment?: string;
}

export function Blocks(props: BlocksProps) {
  return () => {
    const parent =
      props.on === 'app' ? session.router.app : session.router.route.node;
    if (!parent) return null;

    return <parent.$.blocks segment={props.segment} />;
  };
}
