import { jsxx } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { BlockProps } from '..';
import {
  LayoutBlocks,
  type BlocksProps,
} from '../../layout/fields/blocks/LayoutBlocks';

export function ChildBlocks(
  props: Partial<BlocksProps> & { parentProps: BlockProps<any> },
) {
  return (
    !!props.parentProps.context &&
    jsxx(
      LayoutBlocks,
      store.clone(props, {
        readonly: props.parentProps.readonly,
        segment: props.parentProps.block.segment,
        parent: props.parentProps.block,
        get value() {
          return props.parentProps.context!;
        },
        get scheduledSelection() {
          return props.parentProps.scheduledSelection;
        },
        set scheduledSelection(value) {
          props.parentProps.scheduledSelection = value;
        },
      }),
    )
  );
}
