import { live } from '@donkeyjs/jsx-runtime';
import { dontWatch, store } from '@donkeyjs/proxy';
import throttle from 'throttleit';
import { session } from '../session';
import { getScrollParent } from './getScrollParent';

interface Page {
  firstElement: HTMLElement;
  index: number;
}

export interface InfiniteScrollState {
  page: number;
  pageProgress: number;
  pages: (Page | undefined)[];
  container: HTMLElement | undefined;
  bindContainer: (container: HTMLElement) => void;
  bindPage: (number: number) => (firstElement: HTMLElement) => () => void;
}

const onScroll = (
  state: InfiniteScrollState,
  scrollParent: HTMLElement | Window,
  container: HTMLElement,
) => {
  const { currentPageIndex, scrollProgress } = getCurrentPageAndScrollProgress(
    state.pages,
    container,
    scrollParent,
  );
  if (state.page !== currentPageIndex) state.page = currentPageIndex;
  if (state.pageProgress !== scrollProgress)
    state.pageProgress = scrollProgress;
};

export const useInfiniteScroll = (scrollState?: {
  page?: number;
  paused?: boolean;
}) => {
  const query = session.router.getSchemaQuery({ page: 'int?' });

  const state = store<InfiniteScrollState>({
    get page() {
      const page = scrollState?.page ?? (query.page ?? 1) - 1;
      return page ? Math.max(page, 0) : 0;
    },
    set page(page) {
      if (scrollState?.page != null) scrollState.page = page;
      else
        session.router.updateQuery({
          page: page === 0 ? undefined : [(page + 1).toString()],
        });
    },
    pageProgress: 0,
    pages: [],
    container: undefined,
    bindContainer: (container: HTMLElement) => {
      state.container = container;
    },
    bindPage: (page: number) => (firstElement: HTMLElement) => {
      const newPages = [...state.pages];
      newPages[page] = { firstElement, index: page };
      state.pages = newPages;

      return () => {
        const newPages = [...state.pages];
        delete newPages[page];
        state.pages = newPages;
      };
    },
  });

  live(() => {
    if (scrollState?.paused) return;
    if (state.container) {
      const container = state.container;
      const scrollParent = getScrollParent(container) || window;

      const handleScroll = throttle(
        () => onScroll(state, scrollParent, container),
        100,
      );

      let resizeObserver: ResizeObserver | undefined;
      if (scrollParent !== window) {
        resizeObserver = new ResizeObserver(handleScroll);
        resizeObserver.observe(scrollParent as Element);
      } else {
        window.addEventListener('resize', handleScroll);
      }
      scrollParent.addEventListener('scroll', handleScroll);

      dontWatch(() => {
        handleScroll();
      });

      return () => {
        scrollParent.removeEventListener('scroll', handleScroll);
        if (resizeObserver) {
          resizeObserver.disconnect();
        } else {
          window.removeEventListener('resize', handleScroll);
        }
      };
    }
  });

  return state;
};

function getCurrentPageAndScrollProgress(
  pages: (Page | undefined)[],
  container: HTMLElement,
  scrollContainer: HTMLElement | Window,
): { currentPageIndex: number; scrollProgress: number } {
  let currentPageIndex = 0;
  let maxVisibility = 0;
  let scrollProgress = 0;

  const scrollContainerRect =
    scrollContainer === window
      ? { top: 0, height: window.innerHeight, bottom: window.innerHeight }
      : (scrollContainer as HTMLElement).getBoundingClientRect();

  for (const page of pages) {
    if (!page) continue;

    const pageRect = page.firstElement.getBoundingClientRect();
    const pageTop = pageRect.top - scrollContainerRect.top; // Relative to the container
    const nextPage = pages.find((p) => p && p.index > page.index);

    const pageBottomAbsolute = nextPage
      ? nextPage.firstElement.getBoundingClientRect().top
      : (container.lastElementChild || container).getBoundingClientRect()
          .bottom;
    const pageBottom = pageBottomAbsolute - scrollContainerRect.top; // Relative to the container

    // Calculate visible portion of the page
    const visibleTop = Math.max(pageTop, 0);
    const visibleBottom = Math.min(pageBottom, scrollContainerRect.height);
    const visibleHeight = Math.max(0, visibleBottom - visibleTop);
    const totalHeight = pageBottom - pageTop;
    const visibility = visibleHeight / totalHeight;

    if (visibility > maxVisibility) {
      currentPageIndex = page.index;
      maxVisibility = visibility;

      // Calculate scroll progress based on the bottom of the page
      const visibleAboveBottom = scrollContainerRect.height - pageBottom;
      scrollProgress = (totalHeight + visibleAboveBottom) / totalHeight;
    }
  }

  return {
    currentPageIndex,
    scrollProgress: Math.min(Math.max(scrollProgress, 0), 1),
  };
}

// return (
//   <>
//     {!!pages.current?.length && !pages.current.find((p) => p.page === '0') && (
//       <ScrollToTop>
//         <Link
//           onClick={() => {
//             onPageChange(0);
//             forceUpdate();
//           }}
//         >
//           Scroll to top
//         </Link>
//       </ScrollToTop>
//     )}
//     <a id="pages" ref={ref} />
//     {children}
//   </>
// );
// const ScrollToTop = styled.div`
//   width: 100%;
//   text-align: center;
//   margin-bottom: ${(props) => props.theme.spacing()};
// `;
