import { getGlobal } from '@donkeyjs/jsx-runtime';
import { store, type DataNode } from '@donkeyjs/proxy';
import type { DataSettingsFilter } from '../blocks';
import { getNow } from '../helpers/getNow';

const operators = {
  '!=': 'notEq' as const,
  '=': 'eq' as const,
  '<=': 'lte' as const,
  '<': 'lt' as const,
  '>=': 'gte' as const,
  '>': 'gt' as const,
  '∈': 'in' as const,
};

export interface Filter {
  operator: (typeof operators)[keyof typeof operators];
  value: string;
}

export const parseFilter = (filter: string): Filter => {
  for (const operator in operators) {
    if (filter.startsWith(operator)) {
      return {
        operator: operators[operator as keyof typeof operators],
        value: filter.slice(operator.length),
      };
    }
  }

  return { operator: 'eq', value: filter };
};

export const parseFilters = (filters: Record<string, string>) => {
  const parsedFilters: Record<string, Filter> = {};

  for (const key in filters) {
    parsedFilters[key] = parseFilter(filters[key]);
  }

  return parsedFilters;
};

export const whereFromFilters = (
  filters: Record<string, string> | undefined,
  context: any,
) => {
  if (!filters) return undefined;
  const where: any = {};
  const parsed = parseFilters(filters);
  if (parsed)
    for (const filter in parsed) {
      const { operator, value } = parsed[filter];
      where[filter] = { [operator]: runFormula(value, context) };
    }
  return where;
};

export const formatFilter = (filter: Filter) => {
  const { operator, value } = filter;

  return operator && value !== undefined
    ? `${
        Object.entries(operators).find(([, op]) => op === operator)?.[0]
      }${value}`
    : undefined;
};

export const formatFilters = (filters: Record<string, Filter>) => {
  const formattedFilters: Record<string, string> = {};

  for (const key in filters) {
    const formatted = formatFilter(filters[key]);
    if (formatted) formattedFilters[key] = formatted;
  }

  return formattedFilters;
};

export const runFormula = (formula: string, context: any) => {
  try {
    let fn = new Function(`with ({...this}) { return ${formula} }`);
    fn = fn.bind(context);
    return fn(formula);
  } catch (ex) {
    console.error('Error running formula', formula, ex);
    return '';
  }
};

const getFormulaContext = () => getGlobal('formulaContext', () => store({}));

export const setFormulaContext = (values: Record<string, any>) => {
  const context = getFormulaContext();
  Object.assign(context, values);
};

export const formulaContext = (block?: DataNode<DataSchema, 'Block'>) => {
  return new Proxy(getFormulaContext(), {
    get: (target, prop) => {
      if (prop === 'now') {
        const now = getNow();
        now.setSeconds(0, 0);
        return now;
      }

      if (prop === 'self') return block && { id: block.id };
      if (prop === 'settings') return block?.settings;
      return (target as any)[prop];
    },
    ownKeys: (target) => {
      return [...Object.keys(target), 'now', 'self', 'settings'];
    },
    getOwnPropertyDescriptor(target, p) {
      return {
        enumerable: true,
        configurable: true,
        value: (target as any)[p],
      };
    },
  });
};

export const valueFromFilter = (filter: DataSettingsFilter, context: any) => {
  if (!filter.value) return { operator: 'eq', value: undefined };

  const parsed = parseFilter(filter.value);
  const calculated = runFormula(parsed.value, context);

  if (filter.kind === 'tag-group')
    return {
      operator: 'in',
      value:
        calculated && Array.isArray(calculated) ? calculated : [calculated],
    };

  return {
    operator: parsed.operator,
    value: calculated,
  };
};
