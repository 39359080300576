import { customAlphabet, customRandom } from 'nanoid';
import seedRandom from 'seedrandom';

const hashChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const createHashGenerator = (seed?: string) => {
  if (seed) {
    const generateRandomNumber = seedRandom(seed);
    return customRandom(hashChars, 5, (size) => {
      return new Uint8Array(size).map(() => 256 * generateRandomNumber());
    });
  }
  return customAlphabet(hashChars, 5);
};
