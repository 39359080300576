import type { ClientSchemaMeta } from '@donkeyjs/client';
import { getLocale } from '@donkeyjs/core';
import { meta, type SchemaMeta } from '@donkeyjs/proxy';

export const schemaMeta: SchemaMeta<DataSchema> = {};

export const clientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  Event: {
    asString: (node) => {
      const i18n = getLocale(meta(node).culture);
      return [i18n.formatDate(node.starts, 'PPp'), node.name]
        .filter(Boolean)
        .join(' - ');
    },
  },
};
