import { Image, createView, getAssetUrl, masonry } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhDownloadSimple, PhFrameCorners } from '@donkeyjs/phosphor-icons';
import { renderGalleryContainer } from '../GalleryContainer';
import { useGallery } from '../useGallery';
import styles from './ViewGalleryFileRef.module.css';

export const ViewGalleryFileRef = createView<DataSchema, 'FileRef'>(
  {
    name: () => 'Gallery',
    nodeName: () => 'Gallery Image',
    modes: [
      {
        name: () => 'Masonry',
        icon: () => <PhFrameCorners weight="duotone" />,
        key: 'masonry',
      },
      {
        name: () => 'Square',
        icon: () => <PhFrameCorners weight="duotone" />,
        key: 'square',
      },
      {
        name: () => 'Portrait',
        icon: () => <PhFrameCorners weight="duotone" />,
        key: 'portrait',
      },
      {
        name: () => 'Landscape',
        icon: () => <PhFrameCorners weight="duotone" />,
        key: 'landscape',
      },
    ],
    renderDataContainer: renderGalleryContainer,
    onContainerMount: () => masonry,
    delete: {
      label: 'Remove from gallery',
      skipConfirmation: true,
    },
  },

  (props) => {
    const gallery = useGallery();

    return (
      <button
        type="button"
        class={bind(() => [
          styles.button,
          props.class,
          { [styles.download]: props.node.downloadButton },
        ])}
        onmount={props.onmount}
        onclick={(ev) => {
          setTimeout(() => {
            if ((ev as any).outlinePreventsLinks) return;
            gallery?.goto(props.node);
          }, 0);
        }}
      >
        {() =>
          props.node.downloadButton ? (
            <a
              href={bind(() =>
                getAssetUrl(props.node.file, { download: true }),
              )}
            >
              <PhDownloadSimple weight="fill" />
            </a>
          ) : null
        }
        <Image
          file={props.node}
          ratio={bind(() => {
            switch (props.mode?.key) {
              case 'square':
                return 1;
              case 'portrait':
                return 3 / 4;
              case 'landscape':
                return 4 / 3;
              default:
                return undefined;
            }
          })}
        />
      </button>
    );
  },
);
