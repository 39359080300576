import type { AccessType } from '@donkeyjs/core';
import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import {
  meta,
  store,
  type Culture,
  type DataList,
  type DataNode,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import { differenceInMinutes } from 'date-fns';
import { session } from '../session';

export interface UserLogin {
  email: string;
  password: string;
}

export type UserContext = ReturnType<typeof setUserContext>;

export const emptyUserContext: Omit<
  UserContext,
  '$' | 'login' | 'logout' | 'confirm'
> = {
  can: () => false,
  canEdit: () => false,
  culture: 'en-GB',
  theme: 'DARK',
  hasLoggedInRecently: false,
  isAdmin: false,
  isLoggedIn: false,
  isSysAdmin: false,
  matchRoles: (roles) => roles.includes('visitor'),
  roles: ['visitor'],
  user: null,
  userRequest: null,
};

const key = Symbol('user');

export let browserUser: DataNode<DataSchema, 'User'> | undefined;

export const setUserContext = (options: {
  user: DataList<DataSchema, 'User'> | null;
  confirm: () => Promise<boolean>;
}) => {
  if (typeof window !== 'undefined' && options.user?.[0]) {
    browserUser = options.user[0];
  }

  const changed = store({
    request: undefined as DataList<DataSchema, 'User'> | null | undefined,
  });

  const context = store({
    get userRequest(): DataList<DataSchema, 'User'> | null {
      return changed.request === undefined ? options.user : changed.request;
    },

    set userRequest(user: DataList<DataSchema, 'User'> | null | undefined) {
      changed.request = user;
    },

    get user(): DataNode<DataSchema, 'User'> | null {
      return this.userRequest?.[0] || null;
    },

    get culture() {
      return (
        (this.user?.uiCulture as Culture) || session.app.schema.defaultCulture
      );
    },

    get roles() {
      return this.user?.roles?.split(',') || ['visitor'];
    },

    get theme(): 'DARK' | 'LIGHT' {
      return (this.user?.theme as 'DARK' | 'LIGHT') || 'DARK';
    },

    get hasLoggedInRecently(): boolean {
      if (!context.user?.lastLogin) return false;
      return differenceInMinutes(new Date(), context.user.lastLogin) <= 5;
    },

    get isLoggedIn() {
      return !!this.user && !meta(this.user).isLocal;
    },
    get isAdmin() {
      return this.roles.includes('admin') || this.roles.includes('sysadmin');
    },
    get isSysAdmin() {
      return this.roles.includes('sysadmin');
    },

    matchRoles(roles: string[]): boolean {
      for (const matchRole of roles)
        if (this.roles.includes(matchRole)) return true;
      return false;
    },

    can<S extends Schema, Typename extends NodeTypename<S>>(
      action: AccessType,
      nodeOrTypename: DataNode<S, Typename> | Typename,
      fieldName?: keyof S['nodes'][Typename]['fields'],
    ): boolean {
      return can(this.user, action, nodeOrTypename, fieldName);
    },

    canEdit<S extends Schema, Typename extends NodeTypename<S>>(
      node: DataNode<S, Typename>,
    ) {
      return can(this.user, meta(node).isLocal ? 'insert' : 'update', node);
    },

    async confirm() {
      if (context.hasLoggedInRecently) return true;
      return await options.confirm();
    },

    async login(data: UserLogin, noRedirect?: boolean) {
      const result = await session.data.mutation.login(data, {
        id: true,
        lastLogin: true,
      });
      if (result.data?.id && !noRedirect) {
        window.location.reload();
      }
      return result.errors;
    },

    async logout() {
      const result = await session.data.mutation.logout();
      if (result.data === true) {
        window.location.reload();
      }
      if (result.errors?.[0]) throw result.errors[0];
    },
  });

  setContext(key, context);

  return context;
};

export const getUserContext = () => getContext<UserContext>(key);

const can = <S extends Schema, Typename extends NodeTypename<S>>(
  user: DataNode<DataSchema, 'User'> | null | undefined,
  action: AccessType,
  nodeOrTypename: DataNode<S, Typename> | Typename,
  fieldName?: keyof S['nodes'][Typename]['fields'],
): boolean => {
  const typename =
    typeof nodeOrTypename === 'string'
      ? nodeOrTypename
      : nodeOrTypename.__typename;

  if (!(typename in session.app.schema.nodes)) return true;

  const roles = user?.roles ? user.roles.split(',') : ['visitor'];
  if (user) {
    const can = session.app.permissions.can<any, any>(roles, action, typename);
    return fieldName ? can.field(fieldName) : can.node;
  }

  if (!(typename in session.app.schema.nodes)) return true;

  const can = session.app.permissions.can<any, any>(roles, action, typename);
  return fieldName ? can.field(fieldName) : can.node;
};

export const useReadonly = (value: () => boolean) => {
  const parentContext = getUserContext();
  setUserContext({
    get user() {
      return value() ? null : parentContext.userRequest;
    },
    get confirm() {
      return parentContext.confirm;
    },
  });
};

export function Readonly(props: { active: boolean; children?: JSX.Children }) {
  useReadonly(() => props.active);
  return () => props.children;
}
