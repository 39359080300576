import type {
  I18nLibrary,
  I18nLibraryCulture,
  NestedTranslations,
} from '@donkeyjs/core';
import { capitalize, pluralize } from 'inflection';
import { I18nSystem } from './I18nSystem';
import { getI18n } from './getI18n';

function RenderI18n(props: {
  readonly library: I18nLibrary<any>;
  readonly key: string;
  readonly forUser?: boolean;
  readonly capitalize?: boolean;
  readonly lowercase?: boolean;
  readonly plural?: boolean;
  readonly args: any[];
}) {
  const i18n = getI18n(props.forUser);
  return () => {
    if (i18n.isLoading(props.library)) return '';

    const translation = i18n.get(
      props.library,
      props.key,
      ...(props.args as []),
    );
    let result = translation == null ? `[${props.key}]` : translation;

    if (props.capitalize) result = capitalize(result);
    if (props.lowercase) result = result.toLowerCase();
    if (props.plural) result = pluralize(result);
    return result;
  };
}

interface TextOptions {
  capitalize?: boolean;
  lowercase?: boolean;
  plural?: boolean;
  forUser?: boolean;
}

export function text<
  K extends Extract<
    keyof NestedTranslations<
      typeof I18nSystem extends I18nLibrary<infer Library>
        ? NonNullable<Library['values']>
        : never
    >,
    string
  >,
>(
  key: K,
  options?: TextOptions,
  ...args: NestedTranslations<
    typeof I18nSystem extends I18nLibrary<infer Library>
      ? NonNullable<Library['values']>
      : never
  >[K] extends (...props: infer Props) => string
    ? Props
    : []
): JSX.Children;
export function text<
  Library extends I18nLibraryCulture<any, any>,
  K extends Extract<keyof Nodes, string>,
  Nodes extends NestedTranslations<
    Library extends I18nLibraryCulture<infer Nodes, any> ? Nodes : never
  >,
>(
  library: I18nLibrary<Library>,
  key: K,
  options?: TextOptions,
  ...args: Nodes[K] extends (...props: infer Props) => string ? Props : []
): JSX.Children;
export function text<
  Library extends I18nLibraryCulture<any, any>,
  K extends Extract<
    keyof NestedTranslations<
      Library extends I18nLibraryCulture<infer Library, any> ? Library : never
    >,
    string
  >,
>(
  arg0: I18nLibrary<Library> | K,
  arg1?: K | TextOptions,
  arg2?: TextOptions | any,
  ...args: any[]
): JSX.Children {
  const [library, key, options, ...rest] =
    typeof arg0 === 'string'
      ? [I18nSystem, arg0, arg1, arg2, ...args]
      : [arg0, arg1, arg2, ...args];
  return <RenderI18n library={library} key={key} args={rest} {...options} />;
}

export function userText<
  K extends Extract<
    keyof NestedTranslations<
      typeof I18nSystem extends I18nLibrary<infer Library>
        ? NonNullable<Library['values']>
        : never
    >,
    string
  >,
>(
  key: K,
  options?: TextOptions,
  ...args: NestedTranslations<
    typeof I18nSystem extends I18nLibrary<infer Library>
      ? NonNullable<Library['values']>
      : never
  >[K] extends (...props: infer Props) => string
    ? Props
    : []
): JSX.Children;
export function userText<
  Library extends I18nLibraryCulture<any, any>,
  K extends Extract<keyof Nodes, string>,
  Nodes extends NestedTranslations<
    Library extends I18nLibraryCulture<infer Nodes, any> ? Nodes : never
  >,
>(
  library: I18nLibrary<Library>,
  key: K,
  options?: TextOptions,
  ...args: Nodes[K] extends (...props: infer Props) => string ? Props : []
): JSX.Children;
export function userText(
  arg0: I18nLibrary<any> | string,
  arg1?: string | TextOptions,
  arg2?: TextOptions | any,
  ...args: any[]
): JSX.Children {
  const [library, key, options, ...rest] =
    typeof arg0 === 'string'
      ? [I18nSystem, arg0, arg1, arg2, ...args]
      : [arg0, arg1, arg2, ...args];
  return (
    <RenderI18n library={library} key={key} args={rest} {...options} forUser />
  );
}
