import { bind } from '@donkeyjs/jsx-runtime';
import { text } from '../i18n/i18n';
import { getTheme } from '../styles';
import styles from './Buttons.module.css';

export interface ButtonsPreset {
  preset:
    | 'create'
    | 'apply'
    | 'update'
    | 'add'
    | 'select'
    | 'create-cancel'
    | 'apply-cancel'
    | 'update-cancel'
    | 'add-cancel'
    | 'select-cancel'
    | 'next-previous'
    | '-cancel'
    | '-close';
  onClick?: (isDefault: boolean) => void;
}

export function isButtonsPreset(
  buttons: ButtonsPreset | JSX.Children,
): buttons is ButtonsPreset {
  return !!buttons && typeof buttons === 'object' && 'preset' in buttons;
}

// Note: in use as BlockProps in DataForm
export interface ButtonsProps {
  buttons: ButtonsPreset | JSX.Children;
  alignButtons?: 'left' | 'center' | 'right';
}

function label(value: string) {
  switch (value) {
    case 'create':
      return text('Common.Create');
    case 'apply':
      return text('Common.Apply');
    case 'update':
      return text('Common.Update');
    case 'add':
      return text('Common.Add');
    case 'select':
      return text('Common.Select');
    case 'cancel':
      return text('Common.Cancel');
    case 'close':
      return text('Common.Close');
    case 'previous':
      return text('Common.Previous');
    case 'next':
      return text('Common.Next');
    default:
      return null;
  }
}

export function Buttons(props: ButtonsProps) {
  const theme = getTheme();

  return (
    <div
      class={bind(() => [
        styles.buttons,
        props.alignButtons && styles[props.alignButtons],
      ])}
    >
      {() => {
        if (isButtonsPreset(props.buttons)) {
          const [primary, secondary] = props.buttons.preset.split('-');
          return (
            <>
              {() =>
                !!primary && (
                  <button
                    type="button"
                    class={[theme.class.button, 'default']}
                    onclick={() =>
                      (props.buttons as ButtonsPreset).onClick?.(true)
                    }
                  >
                    {label(primary)}
                  </button>
                )
              }
              {() =>
                !!secondary && (
                  <button
                    type="button"
                    class={theme.class.button}
                    onclick={() =>
                      (props.buttons as ButtonsPreset).onClick?.(false)
                    }
                  >
                    {label(secondary)}
                  </button>
                )
              }
            </>
          );
        }
        return props.buttons;
      }}
    </div>
  );
}
