import type { JSXVirtualNode } from '../dom';

export function createNullNode() {
  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: null,
    nodes: [],
    dispose() {},
    testUpdate(value) {
      if (value == null || typeof value === 'boolean') return true;
      return 'non-nullable value passed to a null node';
    },
    update(value) {
      return result.testUpdate(value);
    },
  };

  return result;
}
