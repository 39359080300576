import { getContext, setContext } from '@donkeyjs/jsx-runtime';

interface RenderPlugin {
  beforeMount?: (node: JSX.Element) => JSX.Element | null;
}

const key = Symbol('render-plugin');

export const setRenderPlugin = (plugin: RenderPlugin | undefined) => {
  setContext(key, plugin);
};

export const getRenderPlugin = (): RenderPlugin | undefined => {
  return getContext(key);
};

export function processBeforeMount(node: JSX.Element): JSX.Element | null {
  const plugin = getRenderPlugin();
  if (!plugin?.beforeMount) return node;

  if (node == null) return node;
  if (typeof node !== 'object' && typeof node !== 'function')
    return plugin.beforeMount(node);

  const cache = ((plugin as any).__cache ??= new WeakMap());
  if (cache.has(node)) return cache.get(node);

  const result = plugin.beforeMount(node);
  cache.set(node, result);
  return result;
}
