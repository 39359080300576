import { meta } from '../helpers/meta';
import type { InsertableNode } from '../schema';
import type { Node } from './DataNode';
import { autoSortableField } from './autoSortableField';

export const dataListHelpers = <Fields extends { readonly __typename: string }>(
  nodeFromInput: (
    input: any,
    isTest?: boolean,
  ) => [Node<Fields>, void | (() => void)],
  state: { sort: any },
) => {
  const helpers = {
    insertBefore(
      this: any[],
      before: Node | null | undefined,
      ...items: InsertableNode[]
    ) {
      const nodes = items.filter(Boolean).map((n) => nodeFromInput(n)[0]);

      const index = (before && this.indexOf(before)) ?? -1;
      if (index < 0) this.unshift(...nodes);
      else this.splice(index, 0, ...nodes);

      return nodes;
    },

    insertAfter(
      this: any[],
      after: Node | null | undefined,
      ...items: InsertableNode[]
    ) {
      const nodes = items.filter(Boolean).map((n) => nodeFromInput(n)[0]);

      const index = (after && this.indexOf(after)) ?? -1;
      if (index < 0) this.push(...nodes);
      else this.splice(index + 1, 0, ...nodes);

      return nodes;
    },

    testPush(this: any[], ...items: InsertableNode[]) {
      const nodes = items.filter(Boolean).map((n) => nodeFromInput(n, true));

      this.push(...nodes.map((n) => n[0]!));

      return () => {
        for (const node of nodes) {
          node[1]?.();
        }
      };
    },

    testInsertAfter(
      this: any[],
      after: Node | null | undefined,
      ...items: InsertableNode[]
    ) {
      const nodes = items
        .filter(Boolean)
        .map((item) => nodeFromInput(item, true));

      const index = (after && this.indexOf(after)) ?? -1;
      const inserted = nodes.map((n) => n[0]!);
      if (index < 0) this.push(...inserted);
      else this.splice(index + 1, 0, ...inserted);

      return [
        inserted,
        () => {
          for (const node of nodes) {
            node[1]?.();
          }
        },
      ];
    },

    testInsertBefore(
      this: any[],
      before: Node | null | undefined,
      ...items: InsertableNode[]
    ) {
      const nodes = items
        .filter(Boolean)
        .map((item) => nodeFromInput(item, true));

      const index = (before && this.indexOf(before)) ?? -1;
      const inserted = nodes.map((n) => n[0]!);
      if (index < 0) this.unshift(...inserted);
      else this.splice(index, 0, ...inserted);

      return [
        inserted,
        () => {
          for (const node of nodes) {
            node[1]?.();
          }
        },
      ];
    },

    getSortIndex(this: Node[], where?: 'before' | 'after', node?: Node) {
      let index = node && this.indexOf(node);
      if (index == null || index < 0)
        index = where === 'before' ? 0 : this.length - 1;
      const after = this[where === 'before' ? index - 1 : index];
      const before = this[where === 'before' ? index : index + 1];
      return getSortIndexes(this, state.sort, after, before)[1]?.[0] ?? 0;
    },

    move(this: Node[], fromIndex: number, toIndex: number, count = 1) {
      if (fromIndex === toIndex) return;
      const after = this[toIndex - 1];
      const before = this[toIndex];
      const [sortKey, sortValues] = getSortIndexes(
        this,
        state.sort,
        after,
        before,
        count,
      );
      if (sortKey && sortValues) {
        for (let i = fromIndex; i < fromIndex + count; i++)
          (this[i] as any)[sortKey] = sortValues[i - fromIndex];
      }
    },

    moveBefore(this: any[], item: Node, before: Node | null | undefined) {
      const fromIndex = this.indexOf(item);
      if (fromIndex < 0) return;
      const toIndex = before ? this.indexOf(before) : 0;
      if (toIndex < 0) return;
      helpers.move.call(this, fromIndex, toIndex);
    },

    moveAfter(this: any[], item: Node, after: Node | null | undefined) {
      const fromIndex = this.indexOf(item);
      if (fromIndex < 0) return;
      const toIndex = after ? this.indexOf(after) + 1 : 0;
      if (toIndex < 0) return;
      helpers.move.call(this, fromIndex, toIndex);
    },

    testMove(
      this: any[],
      fromIndex: number,
      toIndex: number,
      count = 1,
    ): () => void {
      if (fromIndex === toIndex) return () => {};

      const after = this[toIndex - 1];
      const before = this[toIndex];
      const [sortKey, sortValues] = getSortIndexes(
        this,
        state.sort,
        after,
        before,
        count,
      );
      if (sortKey && sortValues) {
        const reset: (() => void)[] = [];
        // const oldIndexes: number[] = [];
        for (let i = fromIndex; i < fromIndex + count; i++) {
          // oldIndexes.push((this[i] as any)[sortKey]);
          reset.push(
            meta(this[i]).testValues({
              [sortKey]: sortValues[i - fromIndex],
            } as any),
          );
          // (this[i] as any)[sortKey] = sortValues[i - fromIndex];
        }

        return () => {
          for (const resetFn of reset) resetFn();
        };
      }

      return () => {};
    },

    testMoveBefore(this: any[], item: Node, before: Node | null | undefined) {
      const fromIndex = this.indexOf(item);
      if (fromIndex < 0) return () => {};
      const toIndex = before ? this.indexOf(before) : 0;
      if (toIndex < 0) return () => {};
      return helpers.testMove.call(this, fromIndex, toIndex);
    },

    testMoveAfter(this: any[], item: Node, after: Node | null | undefined) {
      const fromIndex = this.indexOf(item);
      if (fromIndex < 0) return () => {};
      const toIndex = after ? this.indexOf(after) + 1 : 0;
      if (toIndex < 0) return () => {};
      return helpers.testMove.call(this, fromIndex, toIndex);
    },
  };
  return helpers;
};

function getSortIndexes(
  list: any[],
  activeSort: any,
  after?: Node,
  before?: Node,
  count = 1,
): [string | undefined, number[] | undefined] {
  const autoSort = autoSortableField(activeSort);

  if (!autoSort) return [undefined, undefined];

  let prevNode = after;
  while (meta(prevNode)?.isLocal || meta(prevNode)?.isTest) {
    prevNode = list[list.indexOf(prevNode) - 1];
  }
  const prev = (prevNode as any)?.[autoSort];

  let nextNode = before;
  while (meta(nextNode)?.isLocal || meta(nextNode)?.isTest) {
    nextNode = list[list.indexOf(nextNode) + 1];
  }
  const next = (nextNode as any)?.[autoSort];

  if (prev != null && next != null)
    return [
      autoSort,
      Array.from({ length: count }, (_, i) => i).map(
        (i) => prev + ((next - prev) / (count + 1)) * (i + 1),
      ),
    ];
  if (prev != null)
    return [
      autoSort,
      Array.from({ length: count }, (_, i) => i).map((i) => prev + i + 1),
    ];
  if (next != null)
    return [
      autoSort,
      Array.from({ length: count }, (_, i) => i)
        .map((i) => next - i - 1)
        .reverse(),
    ];
  return [autoSort, Array.from({ length: count }, (_, i) => i)];
}
